import type { Role, AuthenticationMethod, User } from '@models';

import { openModal } from '../open-modal';

// export interface RemoveMFAOptions {
//   authenticationMethod: AuthenticationMethod;
// }

export interface EnrollMFAModalOptions {
  user: User;
}

/**
 * Enroll MFA Modal.
 *
 * @returns Modal promise.
 */
export async function enrollMFA(options: EnrollMFAModalOptions) {
  interface EnrollMfaModalResponse {
    enrolled: boolean;
  }

  let enrollMfaResponse: EnrollMfaModalResponse | null = null;

  try {
    enrollMfaResponse = (await openModal({
      title: 'Secure Your Account',
      component: () => import('./MFA.vue'),
      props: {
        user: options.user,
      },
    })) as EnrollMfaModalResponse;
  } catch {
    /// empty catch
  }
  return { enrolled: enrollMfaResponse?.enrolled };
}

export interface RemoveMFAOptions {
  authenticationMethod: AuthenticationMethod;
}

/**
 * Remove MFA Modal.
 *
 * @returns Modal promise.
 */
export async function removeMFA(options: RemoveMFAOptions) {
  interface RemoveMfaModalResponse {
    mfaRemoved: boolean;
  }

  let removedMfaResponse: RemoveMfaModalResponse | null = null;

  try {
    removedMfaResponse = (await openModal({
      title: 'REMOVE MFA',
      component: () => import('./RemoveMFA.vue'),
      props: {
        authenticationMethod: options.authenticationMethod,
      },
    })) as RemoveMfaModalResponse;
  } catch {
    /// empty catch
  }
  return { mfaRemoved: removedMfaResponse?.mfaRemoved };
}

/**
 * Open Select Role modal.
 *
 * @returns Modal promise.
 */
export async function selectRole() {
  const modalOptions = {
    title: 'SELECT ROLE',
    component: () => import('./SelectRole.vue'),
  };

  try {
    return await openModal<Role['id']>(modalOptions);
  } catch {
    return null;
  }
}

/**
 * Open Generate Licenses modal.
 *
 * @returns Modal promise.
 */
export async function generateLicenses() {
  await openModal.safe({
    title: 'GENERATE LICENSES',
    component: () => import('./GenerateLicenses.vue'),
  });
}

/**
 * Open Idle Logout Warning modal.
 *
 * @return Modal promise.
 */
export async function idleLogoutWarning() {
  const modalOptions = {
    title: 'IDLE LOGOUT WARNING',
    component: () => import('./IdleLogoutWarning.vue'),
  };

  try {
    return await openModal<boolean>(modalOptions);
  } catch {
    return true;
  }
}

/**
 * Open Redeem Key modal.
 *
 * @returns Modal promise.
 */
export async function redeemKey() {
  await openModal.safe({
    title: 'REDEEM KEY',
    component: () => import('./RedeemKey.vue'),
  });
}

/**
 * Open Settings modal.
 *
 * @returns Modal promise.
 */
export async function settings() {
  await openModal.safe({
    title: 'SETTINGS',
    component: () => import('./Settings.vue'),
    // component: () => import('./Settings/index'),
  });

  // await openModal.safe({
  //   title: 'SETTINGS',
  //   size: 'xl',
  //   component: () => import('./SettingsV2.vue'),
  // });
}
