var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "v-input-mark",
        "input-radio",
        { checked: _setup.checked, disabled: _vm.disabled },
      ],
      on: { click: _setup.onClick },
    },
    [
      _c(
        "input",
        _vm._b(
          { ref: "input", attrs: { type: "radio" } },
          "input",
          { value: _vm.value },
          false
        )
      ),
      _vm._v(" "),
      _c("Transition", [
        _setup.checked
          ? _c(
              "div",
              { staticClass: "input-mark" },
              [_c("Icon", { attrs: { icon: _setup.faCircle } })],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }