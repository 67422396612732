import { server } from '@api/request';
import { Product, Organization, DLC } from '@models';
import { isObject, isArray } from '@tools/type-guards';

/** ... */
export type DependencyList = Product.DependencyInfo['id'][];
/** ... */
export type SceneList = Product.SceneInfo['id'][];
/** ... */
export type DroneList = Product.DroneInfo['id'][];
/** ... */
export type DLCList = Product.DLCInfo['id'][];
/** ... */
export type OrganizationList = Product.OrganizationInfo['id'][];
/** ... */
export type BundleList = Product['id'][];

/** `Product` certification information submission options.  */
export type CertificationOptions = Overwrite<
  Product.CertificationInfo,
  { modules: Product.ModuleInfo['id'][] }
>;

/**
 * {@link list List products} API request options.
 */
export interface ListOptions {
  type: 'basic' | 'certification';
}

/**
 * List all existing products.
 *
 * @param options Request options bag.
 * @returns A list of products.
 *
 * @see {@link ZephyrApi.Products.listAllProducts `ListAllProducts`} API route in `zephyr-serverless`
 */
export async function list(options: ListOptions = { type: 'basic' }) {
  const response = await server.get('v2/products');

  if (!isArray(response)) {
    throw new Error('[] invalid response data.');
  }

  let products = response.map(processProductData);

  if (options.type === 'certification') {
    products = products.filter(({ type }) => type === 'CERTIFICATION');
  }

  return products;
}

/**
 * {@link list List products by organization} API request options.
 */
export interface ListByOrganizationOptions {
  organizationId: Organization['id'];
}

/**
 * List all products accessible to a specified organization.
 *
 * NOTE: TEMPORARY
 *
 * @param options Request options bag.
 * @returns A list of products.
 *
 * @see {@link ZephyrApi.Organizations.listProducts `ListOrganizationProducts`} API route in `zephyr-serverless`
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  // eslint-disable-next-line no-console
  console.warn(
    "[products.listByOrganization] is a temporary call. Should be able to retrieve objects directly from an organization's data object.",
  );

  return await server.list(
    `v2/organizations/${options.organizationId}/products`,
    processProductData,
  );
}

/**
 * {@link get Get product} API request options.
 */
export interface GetOptions {
  productId: Product['id'];
}

/**
 * Get a specified product.
 *
 * @param options Request options bag.
 * @returns The specified product.
 *
 * @see {@link ZephyrApi.Products.getProduct `GetProduct`} API route in `zephyr-serverless`
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/products/${options.productId}`,
    processProductData,
  );
}

/**
 * {@link getAsAdmin Get product as admin} API request options.
 */
export interface GetAsAdminOptions {
  productId: Product['id'];
}

/**
 * Get a specified product as an admin.
 *
 * @param options Request options bag.
 * @returns The specified product.
 *
 * @see {@link ZephyrApi.Admin.Products.adminGetProduct `AdminGetProduct`} API route in `zephyr-serverless`
 */
export async function getAsAdmin(options: GetOptions) {
  return await server.get(
    `v2/admin/products/${options.productId}`,
    processProductData,
  );
}

/**
 * {@link create Create product} API request options.
 */
export interface CreateOptions {
  id: Product['id'];
  type: Product.Type;
  name: string;
  taxCode: string;
  tags?: string[];
  price?: number;
  salePrice?: number;
  upSell?: number;
  downSell?: number;
  useGeneric?: boolean;
  hideShop?: boolean;
  limit?: number;
  description?: string | null;
  allowBackorder?: boolean;
  stock: number | null;
  weight?: number | null;
  customsPrice?: number | null;
  instructionalVideo?: boolean;
  active?: boolean;
  dependencies?: DependencyList;
  scenes?: SceneList;
  drones?: DroneList;
  organizations?: OrganizationList;
  bundle?: BundleList;
  certification?: CertificationOptions;
  images?: ZephyrWeb.ImageInfo[];
  // ...
  checkoutPinned?: number | null;
  shippingService?: Product.ShippingService | null;
  shippingTable?: Product.ShippingTable[] | null;
  phoneRequired: boolean;
  dlc?: DLCList;
}

/**
 * Create a product.
 *
 * @param options Request options bag.
 * @returns The new product.
 *
 * @see {@link ZephyrApi.Admin.Products.createProduct `AdminCreateProduct`} API route in `zephyr-serverless`
 */
export async function create(options: CreateOptions) {
  return await server.post('v2/admin/products', options, processProductData);
}

/**
 * {@link update Update product} API request options.
 */
export interface UpdateOptions {
  productId: Product['id'];
  type?: Product.Type;
  name?: string;
  taxCode?: string;
  tags?: string[] | null;
  price?: number;
  salePrice?: number;
  upSell?: number;
  downSell?: number;
  useGeneric?: boolean;
  hideShop?: boolean;
  limit?: number;
  description?: string | null;
  allowBackorder?: boolean;
  stock?: number | null;
  weight?: number | null;
  customsPrice?: number | null;
  instructionalVideo?: boolean;
  active?: boolean;
  dependencies?: DependencyList | null;
  scenes?: SceneList | null;
  drones?: DroneList | null;
  organizations?: OrganizationList | null;
  bundle?: BundleList | null;
  certification?: CertificationOptions | null;
  addedImages?: ZephyrWeb.ImageInfo[];
  removedImages?: string[];
  licenseExpiration?: number | null;
  // ...
  checkoutPinned?: number | null;
  shippingService?: Product.ShippingService | null;
  shippingTable?: Product.ShippingTable[] | null;
  phoneRequired?: boolean;
  dlc?: DLCList | null;
}

/**
 * Get a specified product as an admin.
 *
 * @param options Request options bag.
 * @returns The specified product.
 *
 * @see {@link ZephyrApi.Admin.Products.update `AdminUpdateProduct`} API route in `zephyr-serverless`
 */
export async function update(options: UpdateOptions) {
  const { productId, ...data } = options;

  return await server.post(
    `v2/admin/products/${productId}`,
    data,
    processProductData,
  );
}

/**
 * {@link addDLC Add DLC to product} API request options.
 */
export interface AddDLCOptions {
  productId: Product['id'];
  dlcId: DLC['id'];
  private: boolean;
  os: 'win' | 'mac';
  name: string;
}

/**
 * Add DLC to specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.addDLC `AdminAddDlcToProduct`} API route in `zephyr-serverless`
 */
export async function addDLC(options: AddDLCOptions) {
  await server.post(`v2/admin/products/add-dlc`, options);
}

/**
 * {@link removeDLC Remove DLC from product} API request options.
 */
export interface RemoveDLCOptions {
  productId: Product['id'];
  dlcId: DLC['id'];
  os: 'win' | 'mac';
}

/**
 * Remove DLC from specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.removeDLC `AdminRemoveDlcFromProduct`} API route in `zephyr-serverless`
 */
export async function removeDLC(options: RemoveDLCOptions) {
  await server.post(`v2/admin/products/remove-dlc`, options);
}

/**
 * {@link del Delete product} API request options.
 */
export interface DeleteOptions {
  productId: Product['id'];
}

/**
 * Delete a specified product.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Products.adminDeleteProduct `AdminDeleteProduct`} API route in `zephyr-serverless`
 */
export async function del(options: DeleteOptions) {
  await server.delete(`v2/admin/products/${options.productId}`);
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidProduct(value: unknown): value is Product {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processProductData(data: unknown) {
  if (!isValidProduct(data)) {
    throw new Error('Invalid product data item.');
  }

  return { ...data } as Product;
}

//#endregion Helper Functions
