var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-braintree" },
    [
      _c("Transition", [
        _setup.loading
          ? _c("div", { staticClass: "busy-overlay" }, [_c("Spinner")], 1)
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-braintree-fields-wrapper",
          style: { opacity: _setup.loading ? "0.2" : "1" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "HostedField",
                    _vm._b(
                      { on: { mounted: _setup.onFieldMounted } },
                      "HostedField",
                      _setup.cardNumber,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "HostedField",
                    _vm._b(
                      { on: { mounted: _setup.onFieldMounted } },
                      "HostedField",
                      _setup.cardExpirationDate,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "HostedField",
                    _vm._b(
                      { on: { mounted: _setup.onFieldMounted } },
                      "HostedField",
                      _setup.cardCvv,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "HostedField",
                    _vm._b(
                      { on: { mounted: _setup.onFieldMounted } },
                      "HostedField",
                      _setup.cardPostalCode,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }