import { server } from '@api/request';

/**
 * {@link verify emails.verify} API request payload options.
 */
export interface VerifyOptions {
  email: string;
}

/**
 * Verify if a given email belongs to an existing account or not.
 *
 * @param options Request options payload.
 * @returns `true` if the email belongs to a user, otherwise `false`.
 */
export async function verify(options: VerifyOptions) {
  return (await server.post('v2/email/verify', options)) === true;
}
