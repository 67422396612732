var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "CardLoadable",
    { attrs: { loading: _vm.loading, "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h6", { staticClass: "mb-0" }, [
            _vm._v("Purchase Recommendations"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-card-body", { staticClass: "p-0" }, [
        !_vm.products.length
          ? _c("div", [
              _c(
                "p",
                {
                  staticClass: "text-muted px-3",
                  staticStyle: { "margin-top": "10px" },
                },
                [_c("i", [_vm._v("No Purchase Recommendations")])]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.products.length
          ? _c(
              "div",
              _vm._l(_vm.products, function (product, i) {
                return _c(
                  "div",
                  {
                    key: product.id,
                    class: [
                      "py-3",
                      "d-flex",
                      "justify-content-between",
                      "align-items-center",
                      "flex-row",
                      { "border-bottom": i < _vm.products.length - 1 },
                    ],
                  },
                  [
                    _c("div", { staticClass: "product-title px-3" }, [
                      _c("h2", { staticClass: "product-name" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(product.name) +
                            "\n            "
                        ),
                        product.recommended
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: "Recommended",
                                    expression: "'Recommended'",
                                  },
                                ],
                              },
                              [
                                _c("Icon", {
                                  attrs: { icon: _vm.icons.faStar },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "connector" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-price" }, [
                        _c(
                          "span",
                          { class: { strikethrough: product.onSale } },
                          [_vm._v(_vm._s(_vm._f("cost")(product.price)))]
                        ),
                        _vm._v(" "),
                        product.onSale
                          ? _c("span", { staticClass: "sale" }, [
                              _vm._v(_vm._s(_vm._f("cost")(product.salePrice))),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: [
                          "d-flex",
                          "flex-row",
                          "justify-content-between",
                          "px-3",
                          "product-buttons",
                        ],
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mx-3",
                            attrs: { variant: "secondary" },
                            on: {
                              click: function ($event) {
                                return _vm.showDetails(product)
                              },
                            },
                          },
                          [_vm._v("Details")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addToCart(product)
                              },
                            },
                          },
                          [_vm._v("\n            Add to Cart\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }