import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { auth } from '@auth';
import { Product } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'products/findById': Products['findById'];
  }

  export interface CommitMap {
    'products/SET': Products['SET'];
    'products/ADD': Products['ADD'];
    'products/UPDATE': Products['UPDATE'];
    'products/DELETE': Products['DELETE'];
    'products/CLEAR': Products['CLEAR'];
  }

  export interface DispatchMap {
    'products/list': Products['list'];
    'products/listCertificationTyped': Products['listCertificationTyped'];
    'products/get': Products['get'];
    'products/create': Products['create'];
    'products/update': Products['update'];
    'products/addDLC': Products['addDLC'];
    'products/removeDLC': Products['removeDLC'];
    'products/del': Products['del'];
  }
}

@Module({ namespaced: true })
export class Products
  extends VuexModule<Products.State, Root.State>
  implements Products.State
{
  items: Product[] = [];

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id }) ?? null;
  }

  // region Mutations

  @Mutation
  SET(options: Products.SetMutationOptions) {
    this.items = options.data;
  }

  @Mutation
  ADD(options: Products.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  @Mutation
  UPDATE(options: Products.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i === -1) {
      throw new Error(`Product with ID ${options.id} not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  @Mutation
  DELETE(options: Products.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.productId });

    if (index === -1) {
      throw new Error(`Product with ID ${options.productId} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  @Mutation
  CLEAR() {
    this.items = [];
  }

  // endregion Mutations

  // region Actions

  @Action
  async list(options?: Products.ListActionOptions) {
    const data = await api.products.list(options);

    this.context.commit('SET', { data });

    return this.items;
  }

  @Action
  async listCertificationTyped() {
    const data = await api.products.list({ type: 'certification' });

    this.context.commit('SET', { data });

    // return this.items;
    return data;
  }

  @Action
  async get(options: Products.GetActionOptions) {
    let data: Product;

    if (auth.isActiveRole(9)) {
      data = await api.products.getAsAdmin(options);
    } else {
      data = await api.products.get(options);
    }

    this.context.commit('ADD', data);

    return data;
  }

  @Action
  async create(options: Products.CreateActionOptions) {
    const data = await api.products.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  @Action
  async update(options: Products.UpdateActionOptions) {
    await api.products.update(options);

    return (await this.context.dispatch('get', options)) as ReturnType<
      Products['get']
    >;
  }

  @Action async addDLC(options: Products.AddDLCActionOptions) {
    console.log('add dlc store: ', options);
    await api.products.addDLC(options);
  }

  @Action async removeDLC(options: Products.RemoveDLCActionOptions) {
    await api.products.removeDLC(options);
  }

  @Action
  async del(options: Products.DeleteActionOptions) {
    await api.products.del(options);

    this.context.commit('DELETE', options);
  }

  // endregion Actions
}

export namespace Products {
  /** ... */
  export interface State {
    items: Product[];
  }

  /** ... */
  export interface SetMutationOptions {
    data: Product[];
  }

  /** ... */
  export type AddMutationOptions = Product;
  /** ... */
  export type UpdateMutationOptions = api.products.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.products.DeleteOptions;
  /** ... */
  export type ListActionOptions = api.products.ListOptions;
  /** ... */
  export type GetActionOptions = api.products.GetOptions;
  /** ... */
  export type CreateActionOptions = api.products.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.products.UpdateOptions;
  /** ... */
  export type AddDLCActionOptions = api.products.AddDLCOptions;
  /** ... */
  export type RemoveDLCActionOptions = api.products.RemoveDLCOptions;
  /** ... */
  export type DeleteActionOptions = api.products.DeleteOptions;
}

export default Products;
