var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "view-field", style: _setup.styles },
    [
      _c("label", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "value-switcher" },
        [
          _c("p", [_vm._v(_vm._s(_vm.value.length))]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { pill: "", size: "sm", variant: "primary" },
              on: { click: _setup.toggle },
            },
            [_vm._v("\n      " + _vm._s(_setup.buttonText) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ModalExpandableSection", {
        attrs: {
          options: _vm.options,
          selection: _vm.value,
          editing: _vm.editable,
          expanded: _setup.show,
        },
        on: {
          updated: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }