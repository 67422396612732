import { Vue, Component, Prop, Watch } from '@vue';
import { sortBy } from 'lodash';

// import RadialProgressBar from 'vue-radial-progress';
import RadialProgressBar from '@components/RadialProgressBar';
import { Report } from '@models';
import { formatReportDistance, formatReportSpeed } from '@utils/reports';

declare module '.' {
  /** `CardMostRecentFlightInfo` view component. */
  export type CardMostRecentFlightInfo = CardMostRecentFlightInfoComponent;

  export namespace CardCurrentCourses {
    /** `CardMostRecentFlightInfo` component properties. */
    export type Props = CardMostRecentFlightInfoProps;
  }
}

/** `CardMostRecentFlightInfo` component properties. */
export interface CardMostRecentFlightInfoProps {
  reports: Report[];
  loading?: boolean;
}

@Component({
  components: {
    RadialProgressBar,
  },
})
export default class CardMostRecentFlightInfoComponent extends Vue {
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Array) readonly reports!: Report[];

  // loading = true;
  loadingReport = true;
  report: Report | null = null;

  @Watch('reports', { immediate: true })
  onReportsUpdated(reports: Report[]) {
    this.report = null;

    const reportId = sortBy(reports, ['createdAt']).pop()?.id;

    if (reportId) {
      void this.loadReports(reportId);
    } else {
      this.loadingReport = false;
    }
  }

  /** ... */
  get pointsEarned() {
    return this.report?.score;
  }

  /** ... */
  get pointsTotal() {
    return this.report?.score;
  }

  /** ... */
  get points() {
    return this.report
      ? { scored: this.report.score, total: this.report.possibleScore }
      : null;
    // return { scored: 400, total: 500 };
  }

  /** ... */
  get topSpeedDisplay() {
    return this.report ? formatReportSpeed(this.report.topSpeed) : null;
  }

  /** ... */
  get averageSpeedDisplay() {
    return this.report ? formatReportSpeed(this.report.averageSpeed) : null;
  }

  /** ... */
  get maxAltitudeDisplay() {
    return this.report ? formatReportDistance(this.report.maxAltitude) : null;
  }

  /** ... */
  get averageAltitudeDisplay() {
    return this.report
      ? formatReportDistance(this.report.averageAltitude)
      : null;
  }

  /** ... */
  get items() {
    if (!this.report) return [];

    return [
      {
        key: 'topSpeed',
        label: 'Top Speed',
        value: this.topSpeedDisplay,
      },
      {
        key: 'averageSpeed',
        label: 'Average Speed',
        value: this.averageSpeedDisplay,
      },
      {
        key: 'maxAltitude',
        label: 'Max Altitude',
        value: this.maxAltitudeDisplay,
      },
      {
        key: 'averageAltitude',
        label: 'Average Altitude',
        value: this.averageAltitudeDisplay,
      },
    ];
  }

  /** ... */
  get displayModuleName() {
    return this.report?.module?.name ?? '--';
  }

  /** ... */
  get displaySceneName() {
    return this.report?.scene?.name ?? '--';
  }

  /**
   * ...
   *
   * @param reportId ...
   */
  private async loadReports(reportId: Report['id']) {
    this.loadingReport = true;

    try {
      this.report = await this.$api.reports.get({ reportId });
    } catch {
      //
    }

    this.loadingReport = false;
  }
}
