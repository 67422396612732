import type { VueRecaptcha } from 'vue-recaptcha';

export { avatars } from './avatars';
export { default as billingCycles } from './billing-cycles';
export { default as billingDays } from './billing-days';
export { billingTypes } from './billing-types';
export { countries, billableCountries, countryInfo } from './countries';
export { creditCards } from './credit-cards';
export { currencies } from './currencies';
export { dashboardMenus } from './dashboard-menus';
export { default as jrotcInstIds } from './jrotc-inst-ids';
export { default as nistGradingCriteria } from './nist-grading-criteria';
export { default as paymentCycles } from './payment-cycles';
export { default as roleRoutes } from './role-routes';
export { roles } from './roles';
export { default as scoreInfo } from './score-info';

/**
 * Google reCAPTCHA key to be used to initialize the {@link VueRecaptcha}
 * component as part of various forms that require a CAPTCHA for authorization.
 */
export const GOOGLE_RECAPTCHA_KEY = '6Lc_4CMUAAAAABlBhyYr0QxQ94lp6HlAdjJ8JAF1';
