import { render, staticRenderFns } from "./TableOverlay.vue?vue&type=template&id=0c17c28c&scoped=true"
import script from "./TableOverlay.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableOverlay.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableOverlay.vue?vue&type=style&index=0&id=0c17c28c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c17c28c",
  null
  
)

export default component.exports