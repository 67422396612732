import { Vue, Component, Prop } from '@vue';

import { faQuoteLeft } from '@icons/solid/faQuoteLeft';
import { faQuoteRight } from '@icons/solid/faQuoteRight';
import { faUser } from '@icons/solid/faUser';

declare module '.' {
  /** `SectionCustomerStories` view component. */
  export type SectionCustomerStories = SectionCustomerStoriesComponent;

  export namespace SectionCustomerStories {
    /** `SectionCustomerStories` component properties. */
    export type Props = SectionCustomerStoriesProps;
    /** ... */
    export type CustomerStory = CustomerStoryData;
  }
}

/** ... */
export interface CustomerStoryData {
  id: string;
  body: string;
  author: string;
  image: string;
}

/** `SectionCustomerStories` component properties. */
export interface SectionCustomerStoriesProps {
  stories: CustomerStoryData[];
}

@Component({ name: 'SectionCustomerStories' })
export default class SectionCustomerStoriesComponent extends Vue {
  @Prop({ type: Array, default: [] })
  readonly stories!: CustomerStoryData[];

  readonly icons = { faQuoteLeft, faQuoteRight, faUser };

  currentStoryIndex = 0;
  intervalDuration = 8_000;

  private intervalId: number | null = null;

  /** ... */
  get currentStory() {
    return this.stories[this.currentStoryIndex] ?? null;
  }

  /** ... */
  get animationDuration() {
    return (this.intervalDuration * 0.001).toString() + 's';
  }

  created() {
    this.startCycle();
  }

  beforeDestroy() {
    this.stopCycle();
  }

  /**
   * ...
   */
  setStoryId(index: number) {
    this.clearRotationInterval();

    this.currentStoryIndex = index;
  }

  /**
   * ...
   */
  startCycle() {
    if (this.intervalId) return;

    this.intervalId = window.setInterval(
      this.storyRotationHandler.bind(this),
      this.intervalDuration,
    );
  }

  /**
   * ...
   */
  stopCycle() {
    this.clearRotationInterval();
  }

  /**
   * ...
   */
  private storyRotationHandler() {
    this.currentStoryIndex =
      this.currentStoryIndex >= this.stories.length - 1
        ? 0
        : this.currentStoryIndex + 1;
  }

  /**
   * ...
   */
  private clearRotationInterval() {
    if (!this.intervalId) return;

    clearInterval(this.intervalId);

    this.intervalId = null;
  }
}
