var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "CardLoadable",
    { attrs: { loading: _vm.loading || _vm.loadingReport, "no-body": "" } },
    [
      _c(
        "b-card-body",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", [
                _c("div", { staticClass: "h6 mb-4" }, [
                  _vm._v("Most Recent Flight"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.report
            ? _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", [
                    _c("div", { staticClass: "h1 mb-0" }, [_vm._v("N/A")]),
                  ]),
                ],
                1
              )
            : _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", [
                    _c("div", { staticClass: "h5" }, [
                      _c("span", [_vm._v(_vm._s(_vm.displayModuleName))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted mx-1" }, [
                        _vm._v("on"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.displaySceneName))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "stat mb-1" },
                      [
                        _c("span", [_vm._v("Completed:")]),
                        _vm._v(" "),
                        _c("DisplayTextDate", {
                          staticClass: "text-muted",
                          attrs: {
                            value: _vm.report?.moduleStartTime,
                            format: "MM/dd/yyyy",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "stat mb-1" }, [
                      _c("span", [_vm._v("Top speed:")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.topSpeedDisplay)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "stat mb-1" }, [
                      _c("span", [_vm._v("Average speed:")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.averageSpeedDisplay)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "stat mb-1" }, [
                      _c("span", [_vm._v("Max Altitude:")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.maxAltitudeDisplay)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "stat mb-1" }, [
                      _c("span", [_vm._v("Average Altitude:")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.averageAltitudeDisplay)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "div",
                      { staticClass: "score-panel" },
                      [
                        _c("RadialProgressBar", {
                          attrs: {
                            diameter: 180,
                            "completed-steps": _vm.points.scored,
                            "total-steps": _vm.points.total,
                            "start-color": "#f35c23",
                            "stop-color": "#f35c23",
                            "inner-stroke-color": "#ffffff29",
                            "inner-stroke-width": 8,
                            "animate-speed": 1000,
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "score-breakdown" }, [
                          _c("span", { staticClass: "score-numerator" }, [
                            _vm._v(_vm._s(_vm.points.scored)),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "out of " + _vm._s(_vm.points.total) + " point(s)"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "b-card-body",
            { staticClass: "additional-info" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    _vm._l(_vm.items, function ({ key, label, value }) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass:
                            "px-0 py-1 d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("strong", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(label)),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(value))]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }