var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    {
      staticClass: "section-customer-stories",
      on: { mouseover: _vm.stopCycle, mouseleave: _vm.startCycle },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-5 justify-content-md-center" },
        [
          _c("b-col", { staticClass: "mx-auto", attrs: { lg: "6" } }, [
            _c("h2", [_vm._v("Customer Stories")]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentStory
        ? _c(
            "b-row",
            { staticClass: "justify-content-center mb-4" },
            [
              _c("b-col", { attrs: { lg: "6" } }, [
                _c(
                  "div",
                  { key: _vm.currentStory.id, staticClass: "story-body" },
                  [
                    _c("div", { staticClass: "story-content" }, [
                      _c(
                        "div",
                        { staticClass: "quote-block quote-block-left" },
                        [
                          _c("Icon", {
                            attrs: { icon: _vm.icons.faQuoteLeft },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pre", [_vm._v(_vm._s(_vm.currentStory.body))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "quote-block quote-block-right" },
                        [
                          _c("Icon", {
                            attrs: { icon: _vm.icons.faQuoteRight },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "story-footer" }, [
                      _c(
                        "span",
                        { staticClass: "story-thumbnail" },
                        [_c("Icon", { attrs: { icon: _vm.icons.faUser } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "story-author" }, [
                        _vm._v("- " + _vm._s(_vm.currentStory.author)),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "section-footer" }, [
              _c(
                "div",
                { staticClass: "pagination-btns" },
                _vm._l(_vm.stories, function ({ id }, i) {
                  return _c("div", {
                    key: id,
                    class: [
                      "pagination-btn",
                      { active: i === _vm.currentStoryIndex },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.setStoryId(i)
                      },
                    },
                  })
                }),
                0
              ),
              _vm._v(" "),
              _vm.intervalId
                ? _c("div", {
                    key: _vm.currentStoryIndex,
                    staticClass: "progress-bar",
                    style: {
                      animationDuration: _vm.animationDuration,
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }