import * as models from '@models';

import { openModal } from '../open-modal';

/**
 * ...
 */
export interface ConfirmModalOptions {
  text: string;
  message?: string;
}

/**
 * Open Confirm modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function confirm(options: ConfirmModalOptions) {
  const modalOptions = {
    title: 'CONFIRM',
    props: { ...options },
    component: () => import('./Confirm.vue'),
  };

  let output = false;

  try {
    output = (await openModal<boolean>(modalOptions)) ?? false;
  } catch {
    return output;
  }

  return output;
}

/**
 * Open Contact Sales modal.
 */
export async function contactSales() {
  await openModal.safe({
    title: 'CONTACT SALES',
    component: () => import('./ContactSales.vue'),
  });
}

/**
 * ...
 */
export interface CopyEmbedCodeOptions {
  code: string;
}

/**
 * Copy Embed Code modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function copyEmbedCode(options: CopyEmbedCodeOptions) {
  await openModal.safe({
    title: 'COPY EMBED CODE',
    props: { ...options },
    component: () => import('./CopyEmbedCode.vue'),
  });
}

/**
 * ...
 */
export interface GeneralInfoModalOptions {
  title: string;
  text: string;
}

/**
 * Open General Info modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function generalInfo(options: GeneralInfoModalOptions) {
  const modalOptions = {
    title: options.title,
    props: { text: options.text },
    component: () => import('./GeneralInfo.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * Open Issued Certifications Breakdown modal.
 *
 * @returns Modal promise.
 */
export async function issuedCertificationsBreakdown() {
  await openModal.safe({
    title: 'ISSUED CERTIFICATIONS BREAKDOWN',
    size: 'xl',
    component: () => import('./IssuedCertificationsBreakdown.vue'),
  });
}

/**
 * ...
 */
export interface LightboxModalOptions {
  title: string;
  images: string[];
  focusAt?: number;
}

/**
 * Open Lightbox modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function lightbox(options: LightboxModalOptions) {
  const { title, ...props } = options;

  await openModal.safe({
    title,
    size: 'xl',
    props,
    component: () => import('./Lightbox.vue'),
  });
}

/**
 * Open Manage Builds modal.
 *
 * @return Modal promise.
 */
export async function manageBuilds() {
  await openModal.safe({
    title: 'MANAGE BUILDS',
    component: () => import('./ManageBuilds.vue'),
  });
}

/**
 * Open User Stats modal.
 *
 * @return Modal promise.
 */
export async function userStats() {
  await openModal.safe({
    title: 'USER STATS',
    size: 'xl',
    component: () => import('./UserStats.vue'),
  });
}

/**
 * Open Transaction Stats modal.
 *
 * @return Modal promise.
 */
export async function transactionStats() {
  await openModal.safe({
    title: 'TRANSACTION STATS',
    size: 'xl',
    component: () => import('./TransactionStats.vue'),
  });
}

/**
 * ...
 */
export interface Option {
  label: string;
  value: string;
}
export interface SelectItemFromListModalOptions {
  title: string;
  options: Option[];
}

/**
 * Open Select Item from List Modal
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function selectItemFromList(
  options: SelectItemFromListModalOptions,
) {
  const modalOptions: openModal.Options = {
    title: options.title,
    props: { ...options },
    component: () => import('./SelectItemFromList.vue'),
  };

  let selection = null;

  try {
    selection = (await openModal(modalOptions)) ?? null;
  } catch {
    return selection;
  }

  return selection;
}

/**
 * ...
 */
export interface SelectListModalOptions {
  title: string;
  list: unknown;
  options: unknown;
  filters: unknown;
  textFuncs: unknown;
}

/**
 * Open Select List modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function selectList<T>(options: SelectListModalOptions) {
  const modalOptions: openModal.Options = {
    title: options.title,
    size: 'lg',
    props: { ...options },
    component: () => import('./SelectList/index.vue'),
  };

  let selection: T[] = [];

  try {
    selection = (await openModal(modalOptions)) ?? [];
  } catch {
    return selection;
  }

  return selection;
}

/**
 * ...
 */
export interface SelectUsersModalOptions {
  title: string;
  accounts: models.Organization[];
  courses: models.Course[];
  selectedCourses: models.Course[];
  selectedUsers: models.User[];
  unselectedUsers: models.User[];
  organizationId?: models.Organization['id'] | null;
}

/**
 * Open Select Users modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function selectUsers(options: SelectUsersModalOptions) {
  const modalOptions: openModal.Options = {
    title: 'SELECT USERS',
    size: 'xl',
    props: { ...options },
    component: () => import('./SelectUsers/index.vue'),
  };

  let users: models.User[] = [];

  try {
    users = (await openModal(modalOptions)) ?? [];
  } catch {
    users;
  }

  return users;
}

/**
 * ...
 */
export interface SelectUsersToEmailModalOptions {
  selectedInstitution?: unknown;
  selectedCourses?: unknown[];
  unselectedUsers?: unknown[];
  selectedUsers?: unknown[];
  textFuncs: SelectUsersToEmailModalOptions.TextFunctions;
}

export namespace SelectUsersToEmailModalOptions {
  /** ... */
  export interface TextFunctions {
    text: (user: models.User) => string;
    subtext: (user: models.User) => string;
  }
}

/**
 * Open Select Users to Email modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function selectUsersToEmail(
  options: SelectUsersToEmailModalOptions,
) {
  const modalOptions: openModal.Options = {
    title: 'SELECT USERS TO EMAIL',
    size: 'xl',
    props: { ...options },
    component: () => import('./SelectUsersToEmail/index.vue'),
  };

  let users: models.User[] = [];

  try {
    users = await openModal(modalOptions);
  } catch {
    return;
  }

  return users;
}

/**
 * Open Search Users modal
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function searchUsers() {
  const modalOptions: openModal.Options = {
    title: 'SEARCH USERS',
    size: 'lg',
    component: () => import('./SearchUsers/index.vue'),
  };

  let user: models.User | undefined = undefined;

  try {
    user = await openModal(modalOptions);
  } catch {
    return;
  }

  return user;
}

/**
 * Tax Collection modal
 */
export async function taxCollection() {
  return await openModal({
    title: 'TAX COLLECTION',
    size: 'xl',
    component: () => import('./TaxCollection.vue'),
  });
}

/**
 * Certification Stats modal
 *
 */
export async function certificationStats() {
  return await openModal({
    title: 'CERTIFICATION STATS',
    size: 'xl',
    component: () => import('./CertificationStats.vue'),
  });
}

/**
 * Decrypt Report modal
 */
export async function decryptReport() {
  return await openModal({
    title: 'DECRYPT REPORT',
    size: 'xl',
    component: () => import('./DecryptReport.vue'),
  });
}

/**
 * ...
 */
export interface ViewValueModalOptions {
  value: unknown;
  title?: string;
}

/**
 * View JSON Object modal
 */
export async function viewValue(options: ViewValueModalOptions) {
  await openModal({
    title: options.title ?? '',
    size: 'xl',
    props: { value: options.value },
    component: () => import('./ViewValue.vue'),
  });
}
