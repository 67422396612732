import { server } from '@api/request';
import { Organization } from '@models';

/**
 * Get generic Braintree token.
 *
 * @return A promise that resolves to a generic Braintree token.
 */
export async function getToken() {
  return await server.get<string>('v2/braintree/token');
}

/**
 * Get current user Braintree token.
 *
 * @return A promise that resolves to the user's Braintree token.
 */
export async function getUserToken() {
  return await server.get<string>('v2/braintree/user-token');
}

/**
 * ...
 */
export interface GetOrganizationTokenOptions {
  organizationId: Organization['id'];
}

/**
 * Get organization Braintree token.
 *
 * @param options API call options.
 * @return A promise that resolves to the account's Braintree token.
 */
export async function getOrganizationToken(
  options: GetOrganizationTokenOptions,
) {
  return await server.post<string>('v2/braintree/organization-token', options);
}
