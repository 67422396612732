import { Action, Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { modals } from '@modals';
import { Organization } from '@models';
import { auth, RoleId } from '@auth';
import * as currencies from '@utils/currencies';

import { faDollarSign } from '@icons/solid/faDollarSign';
import { faEdit } from '@icons/solid/faEdit';
import { faGift } from '@icons/solid/faGift';
import { faIdCard } from '@icons/solid/faIdCard';
import { faMinusCircle } from '@icons/solid/faMinusCircle';
import { faPlus } from '@icons/solid/faPlus';
import { faPlusMinus } from '@icons/solid/faPlusMinus';
import { faExchange } from '@icons/regular/faExchange';

const columns: TablePanel.Column<Organization>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  {
    key: 'stateProvince',
    label: 'State',
    value: ({ address }) => address?.stateProvince,
  },
  {
    key: 'country',
    label: 'Country',
    value: ({ address }) => address?.country,
  },
  {
    key: 'phone',
    label: 'Phone',
    type: 'phone',
    value: 'phone',
  },
  {
    key: 'billingType',
    label: 'Billing Type',
    value: 'billingType',
    component: 'TableCellOrganizationBillingType',
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
  {
    key: 'seats',
    label: 'Seats',
    value: 'maxSeats',
    component: 'TableCellOrganizationSeats',
  },
  {
    key: 'siteLicenses',
    label: 'Site Licenses',
    value: 'totalLicenses',
    component: 'TableCellOrganizationSiteLicenses',
  },
  // {
  //   key: 'maxSeats',
  //   label: 'Max Seats',
  //   value: 'maxSeats',
  // },
  // {
  //   key: 'usedSeats',
  //   label: 'Used Seats',
  //   value: 'usedSeats',
  // },
  // {
  //   key: 'availableSeats',
  //   label: 'Available Seats',
  //   value: ({ maxSeats, usedSeats }) => maxSeats - usedSeats,
  // },
  // {
  //   key: 'billMeLaterSeats',
  //   label: 'Current B.M.L. Seats',
  //   value: 'billMeLaterSeats',
  //   hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  // },
  {
    key: 'statementBalance',
    label: 'Statement Balance',
    value: ({ statementBalance, seatCost }) => {
      return currencies.format({
        amount: statementBalance ?? 0,
        currency: seatCost.currency,
        unitType: 'smallest',
      });
    },
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
  {
    key: 'currentBalance',
    label: 'Current Balance',
    type: 'currency',
    value: ({ currentBalance, seatCost }) => {
      return currencies.format({
        amount: currentBalance ?? 0,
        currency: seatCost.currency,
        unitType: 'smallest',
      });
    },
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
  {
    key: 'nextInvoiceDate',
    label: 'Next Invoice Date',
    type: 'date',
    value: 'nextInvoiceDate',
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
];

const operations: TablePanel.Row.Operation<Organization>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id: organizationId }) => {
      void modals.organization.edit({ organizationId });
    },
  },
  {
    label: 'Update User Seats',
    icon: faIdCard,
    // TODO (Alex) implement
    fn: ({ id: organizationId }) => {
      void modals.organization.updateUserLicenses({ organizationId });
    },
    hidden: ({ billingType }) => {
      return (
        !auth.isActiveRole(RoleId.LasAdmin) || billingType === 'SiteLicense'
      );
    },
  },
  {
    label: 'Gift Seats',
    icon: faGift,
    fn: ({ id: organizationId }) => {
      void modals.organization.giftSeats({ organizationId });
    },
    hidden: ({ billingType }) => {
      return (
        !auth.isActiveRole(RoleId.LasAdmin) || billingType === 'SiteLicense'
      );
    },
  },
  {
    label: 'Remove Seats',
    icon: faMinusCircle,
    fn: ({ id: organizationId }) => {
      void modals.organization.removeSeats({
        organizationId,
        seats: 1,
        creditInstitution: false,
      });
    },
    hidden: ({ billingType }) =>
      billingType === 'SiteLicense' || billingType === 'StudentPays',
  },
  {
    label: 'Change License',
    icon: faExchange,
    fn: ({ id: organizationId }) => {
      void modals.organization.changeLicense({ organizationId });
    },
    hidden: ({ billingType }) => {
      return (
        !auth.isActiveRole(RoleId.LasAdmin) || billingType !== 'SiteLicense'
      );
    },
  },
  {
    label: 'Manage Products',
    icon: faIdCard,
    fn: ({ id: organizationId }) => {
      void modals.organization.manageProducts({ organizationId });
    },
    hidden: ({ billingType }) => {
      return (
        !auth.isActiveRole(RoleId.LasAdmin) || billingType === 'SiteLicense'
      );
    },
  },
  {
    label: 'Edit Balance',
    icon: faDollarSign,
    fn: ({ id: organizationId }) => {
      void modals.organization.credit({ organizationId });
    },
    hidden: ({ billingType }) => {
      /**
       * TODO disabled in preparation for Stripe management of invoicing
       * we can re-enable if we find a way to manage organization balance
       */
      return true;
      return (
        !auth.isActiveRole(RoleId.LasAdmin) || billingType === 'SiteLicense'
      );
    },
  },
  // Site License related options
  {
    label: 'Add Licenses',
    icon: faPlus,
    fn: ({ id: organizationId }) => {
      void modals.organization.addLicenses({ organizationId });
    },
    hidden: ({ billingType }) => {
      return (
        !auth.isActiveRole(RoleId.InstitutionAdmin) ||
        billingType !== 'SiteLicense'
      );
    },
  },
  {
    label: 'Add/Remove Licenses',
    icon: faPlusMinus,
    fn: ({ id: organizationId }) => {
      void modals.organization.addRemoveLicenses({ organizationId });
    },
    hidden: ({ billingType }) => {
      return (
        !auth.isActiveRole(RoleId.LasAdmin) || billingType !== 'SiteLicense'
      );
    },
  },
  {
    label: 'Assign Licenses',
    icon: faIdCard,
    fn: ({ id: organizationId }) => {
      void modals.organization.manageLicenses({ organizationId });
    },
    hidden: ({ billingType }) => billingType !== 'SiteLicense',
  },
  // ...

  // {
  //   label: 'Set to Active',
  //   icon: faLevelUp,
  //   fn: ({ id }) => {
  // NOTE: Can't find method in original source.
  // $ctrl.setInstitutionToActive(organization);
  // },
  // hidden: (organization, { selectedRole }) => {
  //   return selectedRole?.roleId !== 9 || organization.active;
  // },
  // },
  // {
  //   label: 'Set to Inactive',
  //   icon: faLevelDown,
  //   fn: ({ id }) => {
  //     // NOTE: Can't find method in original source.
  //     // $ctrl.setInstitutionToInactive(organization);
  //   },
  //   hidden: (organization, { selectedRole }) => {
  //     return selectedRole?.roleId !== 9 || !organization.active;
  //   },
  // },
];

@Module({ namespaced: true })
export class OrganizationTablePanel
  extends TablePanel<Organization>
  implements TablePanel.Props<Organization>
{
  readonly module = 'organizations';
  readonly loadAction = 'list';
  readonly loadPageAction = 'loadPage';
  readonly pk = 'id';
  readonly label = 'INSTITUTIONS';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = ['name'];
  readonly createAction = 'createOrganization';
  readonly deleteAction = 'deleteOrganization';
  readonly tableSortType = 'name';

  get enabled() {
    return this.isActiveRole(RoleId.Reseller, RoleId.LasAdmin);
  }

  get progressive() {
    return this.isActiveRole(RoleId.LasAdmin);
  }

  @Action
  async createOrganization() {
    await modals.organization.create();
  }

  @Action
  async deleteOrganization({ items }: InstitutionTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'organizations', items });
  }
}

export namespace InstitutionTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Organization[];
  }
}
