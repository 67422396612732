import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

/**
 * ...
 */
export interface CartItemOption {
  productId: string;
  quantity: number;
  isUpsell?: boolean;
  isDownsell?: boolean;
}

/**
 * ...
 */
export interface CheckoutResponse {
  orderId: string;
}

/**
 * Do something.
 *
 * @return List of all currently-available products.
 */
export async function getProducts() {
  return await server.list('v2/products/live', processProductData);
}

/**
 * ...
 */
export interface GetShippingOptions {
  cart: { productId: string; quantity: number }[];
  shippingFirst: string;
  shippingLast: string;
  shippingAddress1: string;
  shippingCity: string;
  shippingStateProvince: string;
  shippingPostalCode: string;
  shippingCountry: string;
}

/**
 * Get available shipping costs for products based on region.
 *
 * @param options Request options bag.
 * @return Shipping costs.
 */
export async function getShipping(options: GetShippingOptions) {
  return await server.post<models.ShippingCost>('v2/shipping', options);
}

/**
 * {@link getTaxInfo Get tax info} API request options.
 */
export interface GetTaxInfoOptions {
  country: string;
  province: string;
  amount: number;
}

/**
 * Get tax information for a specific region.
 *
 * @param options Request options bag.
 * @return Tax information of the target region.
 */
export async function getTaxInfo(options: GetTaxInfoOptions) {
  return await server.post<models.TaxInfo>('v2/tax', options);
}

/**
 * ...
 */
export type TaxRateSummary = TaxRateSummary.Item[];

export namespace TaxRateSummary {
  export interface RateInfo {
    label: string;
    rate: string;
  }

  export interface Item {
    country: string;
    country_code: string;
    region: string | null;
    region_code: string | null;
    average_rate: RateInfo;
    minimum_rate: RateInfo;
  }
}

/**
 * Get tax rate summary from TaxJar.
 *
 * @returns Tax rate summary info.
 */
export async function getTaxRateSummary() {
  return await server.get<TaxRateSummary>('v2/tax/rate-summary');
}

/**
 * Get a list of tax-exempt countries.
 *
 * @return List of exempt country codes.
 */
export async function getTaxExemptCountries() {
  return await server.get<string[]>('v2/tax/exempt-countries');
}

/**
 * {@link getFees Get fees} API request options.
 */
export interface GetFeesOptions {
  cart: CartItemOption[];
  expedited: boolean;
  billingStateProvince?: string;
  billingPostalCode?: string;
  billingCountry?: string;
  shippingCountry?: string;
  shippingAddress1?: string;
  shippingCity?: string;
  shippingStateProvince?: string;
  shippingPostalCode?: string;
  discountCode?: string;
  organizationId?: models.Organization['id'];
}

export default interface Breakdown {
  taxable_amount: number;
  tax_collectable: number;
  combined_tax_rate: number;
  state_taxable_amount?: number;
  state_tax_rate?: number;
  state_tax_collectable?: number;
  county_taxable_amount?: number;
  county_tax_rate?: number;
  county_tax_collectable?: number;
  city_taxable_amount?: number;
  city_tax_rate?: number;
  city_tax_collectable?: number;
  special_district_taxable_amount?: number;
  special_tax_rate?: number;
  special_district_tax_collectable?: number;
  shipping?: {
    taxable_amount: number;
    tax_collectable: number;
    state_taxable_amount: number;
    state_sales_tax_rate: number;
    state_amount: number;
    special_taxable_amount: number;
    special_tax_rate: number;
    special_district_amount: number;
    county_taxable_amount: number;
    county_tax_rate: number;
    county_amount: number;
    combined_tax_rate: number;
    city_taxable_amount: number;
    city_tax_rate: number;
    city_amount: number;
  };
  line_items?: {
    taxable_amount: number;
    tax_collectable: number;
    state_taxable_amount?: number;
    state_sales_tax_rate?: number;
    state_amount?: number;
    special_tax_rate?: number;
    special_district_taxable_amount?: number;
    special_district_amount?: number;
    id?: string;
    county_taxable_amount?: number;
    county_tax_rate?: number;
    county_amount?: number;
    combined_tax_rate?: number;
    city_taxable_amount?: number;
    city_tax_rate?: number;
    city_amount?: number;

    // Canada line item attributes
    gst_taxable_amount?: number;
    gst_tax_rate?: number;
    gst?: number;
    pst_taxable_amount?: number;
    pst_tax_rate?: number;
    pst?: number;
    qst_taxable_amount?: number;
    qst_tax_rate?: number;
    qst?: number;

    // International line item attributes
    country_taxable_amount?: number;
    country_tax_rate?: number;
    country_tax_collectable?: number;
  }[];

  // Canada attributes
  gst_taxable_amount?: number;
  gst_tax_rate?: number;
  gst?: number;
  pst_taxable_amount?: number;
  pst_tax_rate?: number;
  pst?: number;
  qst_taxable_amount?: number;
  qst_tax_rate?: number;
  qst?: number;

  // International attributes
  country_taxable_amount?: number;
  country_tax_rate?: number;
  country_tax_collectable?: number;
}

export interface TaxForOrder {
  order_total_amount: number;
  shipping: number;
  taxable_amount: number;
  amount_to_collect: number;
  rate: number;
  has_nexus: boolean;
  freight_taxable: boolean;
  tax_source: string;
  exemption_type: string | null;
  jurisdictions: {
    country: string;
    state?: string;
    county?: string;
    city?: string;
  };
  breakdown?: Breakdown;
}

/**
 * ...
 */
export interface FeeInfo {
  discount: number;
  shipping: number;
  subtotal: number;
  tax: number;
  taxBreakdown?: TaxForOrder | undefined;
  total: number;
}

/**
 * Get expected transaction fees based on cart items and customer information.
 *
 * @param options Request options bag.
 * @return Expected transaction fees information.
 */
export async function getFees(options: GetFeesOptions) {
  return await server.post<FeeInfo>('v2/orders/fees', options);
}

/**
 * ...
 */
export interface CheckoutOptionsBase {
  email: string;
  phone?: string;
  cart: CartItemOption[];
  billingFirst?: string | null;
  billingLast?: string | null;
  billingCountry?: string | null;
  billingAddress1?: string | null;
  billingAddress2?: string | null;
  billingAddress3?: string | null;
  billingCity?: string | null;
  billingStateProvince?: string | null;
  billingPostalCode?: string | null;
  shippingFirst?: string | null;
  shippingLast?: string | null;
  shippingCountry?: string | null;
  shippingAddress1?: string | null;
  shippingAddress2?: string | null;
  shippingAddress3?: string | null;
  shippingCity?: string | null;
  shippingStateProvince?: string | null;
  shippingPostalCode?: string | null;
  expedited?: boolean | null;
  institutionId?: string | null;
  referralCode?: string | null;
  discountCode?: string | null;
}

/**
 * ...
 */
// export type CheckoutOptions = CheckoutOptionsBase &
//   (
//     | { paymentMethodNonce: string }
//     | { paymentMethodId: string }
//     | { orderId: string }
//   );

export interface CheckoutOptions extends CheckoutOptionsBase {
  /**
   * The total purchase amount calculated on the client and displayed to the
   * customer. The transaction will be aborted if this amount does not match
   * that which is calculated by the server.
   */
  calculatedTotal: number;
  paymentMethodNonce?: string;
  paymentMethodId?: string;
  orderId?: string;
}

/**
 * Place an order from the Zephyr shop with an existing user's cart.
 *
 * @param options Request options bag.
 * @return A response containing the order ID.
 */
export async function checkout(options: CheckoutOptions) {
  const { calculatedTotal, ...data } = options;

  return await server.post<CheckoutResponse>('v2/users/orders/braintree', {
    ...data,
    total: calculatedTotal,
  });
}

/**
 * Place an order from the Zephyr shop with a guest user's cart.
 *
 * @param options Request options bag.
 * @return A response containing the order ID.
 */
export async function guestCheckout(options: CheckoutOptions) {
  const { calculatedTotal, ...data } = options;

  return await server.post<CheckoutResponse>('v2/orders/braintree', {
    ...data,
    total: calculatedTotal,
  });
}

/**
 * Place an order from the Zephyr shop with an existing user's cart via PayPal.
 *
 * @param options Request options bag.
 * @return A response containing the order ID.
 */
export async function payPalCheckout(options: CheckoutOptions) {
  const { calculatedTotal, ...data } = options;

  return await server.post<CheckoutResponse>('v2/users/orders/paypal', {
    ...data,
    total: calculatedTotal,
  });
}

/**
 * Place an order from the Zephyr shop with an existing user's cart via PayPal.
 *
 * @param options Request options bag.
 * @return A response containing the order ID.
 */
export async function payPalGuestCheckout(options: CheckoutOptions) {
  const { calculatedTotal, ...data } = options;

  return await server.post<CheckoutResponse>('v2/orders/paypal', {
    ...data,
    total: calculatedTotal,
  });
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidProductData(value: unknown): value is models.Product {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processProductData(data: unknown) {
  if (!isValidProductData(data)) {
    throw new Error('Invalid product data item.');
  }

  return { ...data } as models.Product;
}

//#endregion Helper Functions
