import { ref, computed, provide, watch, reactive, type Ref } from 'vue';
import * as BootstrapVue from 'bootstrap-vue';
import { TablePanel as TP } from 'table-panel';

import { alert } from '@services/alert';
import { useStore } from '@store';
import { randomId } from '@tools/random-id';
import { CommitMap } from '@vuex';

import { faTrashCan } from '@icons/solid/faTrashCan';

import { createField } from './create-field';
import { useFilterTags, useFilterParams, FilterTagMap } from './filters';
import * as utils from './utils';

/** ... */
export type DataItem = Record<string, unknown>;
/** ... */
export type Columns = TP.Column<DataItem>[];
/** ... */
export type Operations = TP.Row.Operation<DataItem>[];

/**
 * ...
 */
export interface Row<T> {
  item: T;
  selected: boolean;
}

/**
 * Special Filters type
 */
export interface TableFilter extends TP.SpecialFilter<unknown> {
  enabled: boolean;
}

/**
 * ...
 */
export interface TagFilter extends TP.FilterTag {
  enabled: boolean;
}

/**
 * ...
 */
export interface UseTableOptions {
  table: string;
  pk: string;
  label: string;
  columns: Columns;
  operations?: Operations;
  createAction?: string;
  deleteAction?: string;
  tableQueryText?: string;
  filterFields: string[];
  filterTags: TP.FilterTag[];
  filterParams?: TP.FilterParam[];
  specialFilters: TP.SpecialFilter<DataItem>[];
  infoMessage?: string | null;
}

/** ... */
export type TableRefs = ReturnType<typeof useTable>;

export namespace TableRefs {
  /** ... */
  export type Action = Ref<string | undefined>;

  /** ... */
  export type Selection = Ref<Row<DataItem>[]>;

  /** ... */
  export type Filter = TableFilter;

  /** ... */
  export type Tag = TagFilter;

  /** ... */
  export type TagMap = FilterTagMap;
}

/**
 * ...
 *
 * @param options ...
 * @returns ...
 */
export function useTable(options: UseTableOptions) {
  const store = useStore();

  /** ... */
  const tableFilters = computed(() => {
    return options.specialFilters.map((filter) =>
      reactive({ ...filter, enabled: false, choice: null }),
    ) as TableFilter[];
  });

  //#region Auxiliary Data Loading

  const tablePromises = ref<Record<string, Promise<unknown>>>({});

  const addTablePromise = (promise: Promise<unknown>) => {
    const id = randomId();

    tablePromises.value = { ...tablePromises.value, [id]: promise };

    promise.finally(() => {
      const promises = { ...tablePromises.value };
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete promises[id];

      tablePromises.value = promises;
    });
  };

  const tableLoading = computed(() => {
    return Object.keys(tablePromises.value).length > 0;
  });

  provide('$tableLoading', tableLoading);

  //#endregion Auxiliary Data Loading

  /** ... */
  const showAdditionalActions = ref(false);

  /** ... */
  const fullscreen = ref(false);

  provide('$fullscreen', fullscreen);

  /** ... */
  const path = ref(`tablePanels/${options.table}`);

  provide('$path', path);

  /** ... */
  const createAction = ref(options.createAction);

  provide('$createAction', createAction);

  /** ... */
  const deleteAction = ref(options.deleteAction);

  provide('$deleteAction', deleteAction);

  /** ... */
  const queryText = computed(() => options.tableQueryText);

  provide('$queryText', queryText);

  //#region Property: Table Filter Tags

  const tableFilterTags = useFilterTags(options.filterTags);

  provide('$tableFilterTags', tableFilterTags);

  //#endregion Property: Table Filter Tags

  //#region Property: Table Filter Params

  const tableFilterParams = useFilterParams(
    options.filterParams ?? [],
    addTablePromise,
  );

  provide('$tableFilterParams', tableFilterParams);

  //#endregion Property: Table Filter Params

  /** ... */
  const infoMessage = ref(options.infoMessage);

  provide('$infoMessage', infoMessage);

  /** ... */
  const rows = utils.useRows(path.value);

  /** ... */
  const enabledFilters = computed(() =>
    tableFilters.value.filter(({ type, enabled, choice }) => {
      if (type === 'dropdown') return choice && choice !== '--';
      return enabled;
    }),
  );

  /** List of table items. */
  const items = computed(() => {
    const value: DataItem[] = [];

    // List of active filters to apply to table items.
    const filters = enabledFilters.value.length ? enabledFilters.value : null;

    for (const { item } of rows.value) {
      // Filter out any rows with values that are somehow falsy.
      if (!item) {
        continue;
      }

      // If there is at least one enabled filter, and does not pass all filter
      // checks, filter out the row.
      if (filters && !filters.every((o) => o.handler(item, o.choice))) {
        continue;
      }

      value.push(item);
    }

    return value;
  });

  // if (path.value.includes('users')) {
  //   console.log('TEST START!');

  //   setTimeout(() => {
  //     console.log('delete items');

  //     console.log('remove some users');

  //     const users = [...store.state.users.items];

  //     console.log(`users: ${users.length}`, users);
  //     users.length = 8;
  //     console.log(`users after remove: ${users.length}`);

  //     store.commit('users/SET', { data: users });
  //   }, 5_000);
  // }

  /** ... */
  const displayedItems = ref<DataItem[]>([]);

  /**
   * Array of table item PK values, representing the most recent arrangement
   * of items on the page.
   */
  const previousPageArrangement = ref<unknown[]>([]);

  /** ... */
  const onPageChangedCallback = computed(() => {
    const callback = (store.getters as unknown as GenericObject)[
      `${path.value}/onPageChanged`
    ];

    if (typeof callback !== 'function') return null;

    return callback as TP.OnPageChangedCallback<unknown>;
  });

  /** ... */
  const page = computed(() => {
    const page: Row<DataItem>[] = [];

    for (const item of displayedItems.value) {
      page.push(reactive({ item, selected: false }));
    }

    return page;
  });

  // onDisplayedItemsChanged
  watch(
    displayedItems,
    (value) => {
      // Compute the page's current arrangement array.
      const currentPageArrangement = value.map((item) => item[options.pk]);

      // Check to see if the arrangement has changed.
      const sameArrangement =
        // Arrangements' lengths must match.
        previousPageArrangement.value.length ===
          currentPageArrangement.length &&
        // Arrangements' items value AND order must match.
        currentPageArrangement.every(
          (item, i) => previousPageArrangement.value[i] === item,
        );

      if (sameArrangement) return;

      // Set update the `previousPageArrangement`.
      previousPageArrangement.value = currentPageArrangement;

      // Invoke the table's onPageChanged callback, if it exists.
      onPageChangedCallback.value?.(value);
    },
    { immediate: true },
  );

  /** ... */
  const selection = computed(() => {
    return page.value.filter(({ selected }) => selected);
  });

  provide('$selection', selection);

  /** ... */
  const maximized = computed(() => fullscreen.value);

  /** Update the text value for table's search query. */
  const updateSearch = (value: string | undefined) => {
    store.commit(
      `${path.value}/updateQuery` as keyof CommitMap,
      value as Parameters<typeof store.commit>[1],
    );
  };

  provide('$updateSearch', updateSearch);

  /** Reload the table data. */
  const refreshItems = async () => {
    await store.dispatch(`${path.value}/load`);

    // Manually invoke `onPageChangedCallback`, as the reload may not result
    // in a change to the displayed items while possibly discarding changes
    // related to the previous `onPageChangedCallback` call.
    onPageChangedCallback.value?.(displayedItems.value);
  };

  provide('$refreshItems', refreshItems);

  /** Trigger table item creation process, if applicable. */
  const createItem = () => {
    if (createAction.value) {
      void store.dispatch(`${path.value}/${createAction.value}`);
    }
  };

  provide('$createItem', createItem);

  /** Trigger table item deletion process, if applicable. */
  const deleteItem = (items?: DataItem[]) => {
    if (!deleteAction.value) return;

    if (!items) {
      if (!selection.value.length) {
        return alert.error('Must select an object to delete.');
      }

      items = selection.value.map(({ item }) => item);
    }

    void store.dispatch(`${path.value}/${deleteAction.value}`, { items });
  };

  provide('$deleteItem', deleteItem);

  const columns = computed(() => {
    return options.columns.map(createField) as BootstrapVue.BvTableFieldArray;
  });

  /** List of table field configs. */
  const fields = computed(() => {
    const fields = options.columns.map(
      createField,
    ) as BootstrapVue.BvTableFieldArray;

    // set operations to the front of table so it can Cool!be sticky
    if (options.operations) {
      fields.unshift({
        key: 'operations',
        label: 'Options',
        stickyColumn: true,
        thStyle: { width: '60px', minWidth: '60px' },
      });
    }

    fields.unshift({
      key: 'selector',
      label: '',
      stickyColumn: true,
      thStyle: { width: '60px', minWidth: '60px' },
    });

    return fields;
  });

  /** ... */
  const customCells = computed(() => {
    return options.columns.filter(({ component }) => component);
  });

  const operations = computed(() => {
    if (!options.operations) return null;

    const items = [...options.operations];

    if (options.deleteAction) {
      items.push({
        label: 'DELETE',
        icon: faTrashCan,
        variant: 'danger',
        fn: (item) => deleteItem([item]),
      });
    }

    return items;
  });

  /** ... */
  const allRowsSelected = computed({
    get: () => page.value.every(({ selected }) => selected),
    set: (value) => {
      for (const row of page.value) row.selected = value;
    },
  });

  return {
    path,
    queryText,
    columns,
    fields,
    fullscreen,
    items,
    displayedItems,
    showAdditionalActions,
    page,
    maximized,
    customCells,
    operations,
    allRowsSelected,
    tableFilters,
    tableFilterTags,
    tableFilterParams,
    enabledFilters,
    tableLoading,
    updateSearch,
    refreshItems,
    createItem,
    deleteItem,
    infoMessage,
  };
}
