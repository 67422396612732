import { Course, Organization } from '@models';
import { store } from '@store';

import { openModal } from '../open-modal';

/**
 * ...
 */
export interface CreateCourseModalOptions {
  courseId?: Course['id'];
  organizationId?: Organization['id'];
}

export interface CreateCourseProps {
  course?: Course;
}

/**
 * Open Create Course modal.
 *
 * @return Modal promise.
 */
export async function create(options?: CreateCourseModalOptions) {
  const props: CreateCourseProps = {};

  if (options?.courseId) {
    const course = await store.dispatch('courses/get', options);
    props.course = course;
  }

  const modalOptions = {
    title: 'CREATE COURSE',
    component: () => import('./CreateCourse.vue'),
    props,
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface EditCourseModalOptions {
  courseId: Course['id'];
  organizationId: Organization['id'];
}

/**
 * Open Edit Course modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function edit(options: EditCourseModalOptions) {
  const course = await store.dispatch('courses/get', options);

  const modalOptions = {
    title: 'EDIT COURSE',
    component: () => import('./EditCourse.vue'),
    props: { course },
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ManageStudentsModalOptions {
  courseId: Course['id'];
  organizationId: Organization['id'];
}

/**
 * Open Manage Students modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function manageStudents(options: ManageStudentsModalOptions) {
  const course = await store.dispatch('courses/get', options);

  const modalOptions = {
    title: `MANAGE STUDENTS: ${course.name}`,
    size: 'xl',
    props: { course },
    component: () => import('./ManageStudents/index.vue'),
  };

  await openModal.safe(modalOptions);
}
