import { Action, Module, Mutation, VuexModule } from '@vuex/decorators';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import type { TablePanel } from 'table-panel';

import { api } from '@api';
import type { InviteSearch } from '@api/modules/invites';
import { Invite } from '@models';
import { auth } from '@auth';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'invites/findById': Invites['findById'];
  }

  export interface CommitMap {
    'invites/SET': Invites['SET'];
    'invites/SET_LAST_EVALUATED_KEY': Invites['SET_LAST_EVALUATED_KEY'];
    'invites/ADD': Invites['ADD'];
    'invites/DELETE': Invites['DELETE'];
    'invites/CLEAR': Invites['CLEAR'];
  }

  export interface DispatchMap {
    'invites/loadPage': Invites['loadPage'];
    'invites/list': Invites['list'];
    'invites/get': Invites['get'];
    'invites/send': Invites['send'];
    'invites/resend': Invites['resend'];
    'invites/del': Invites['del'];
  }
}

@Module({ namespaced: true })
export class Invites
  extends VuexModule<Invites.State, Root.State>
  implements Invites.State
{
  items: Invite[] = [];
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Invites.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  SET_LAST_EVALUATED_KEY(options: {
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
  }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Invites.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  // @Mutation
  // UPDATE(options: UpdateInviteMutationOptions) {
  //   const items = [...this.items];
  //
  //   const i = findIndex(items, { id: options.droneId });
  //
  //   if (i === -1) {
  //     throw new Error(`Invite with ID ${options.droneId} not found.`);
  //   }
  //
  //   for (const key in options) items[i][key] = options[key];
  //
  //   this.items = items;
  // }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Invites.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.inviteId });

    if (index === -1) {
      throw new Error(`Invite with ID ${options.inviteId} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async loadPage(options: TablePanel.LoadPageOptions<Invite>) {
    const searchOptions = {
      limit: 25,
    } as InviteSearch.Options;

    if (isString(options.filter.contains)) {
      searchOptions.inviteId = options.filter.contains;
      searchOptions.email = options.filter.contains;
    }

    // Filter parameter values
    if (options.filter.params) {
      const organizationId = options.filter.params['organizationId'];

      if (isString(organizationId)) {
        searchOptions.organizationId = organizationId;
      }
    }

    let data: Invite[];

    if (this.lastEvaluatedKey && !options.clearPrevious) {
      searchOptions.startKey = this.lastEvaluatedKey;
      data = [...this.items];
    } else {
      data = [];
    }

    const res = await api.invites.search(searchOptions);

    data.push(...res.items);

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', {
      lastEvaluatedKey: res.lastEvaluatedKey,
    });

    return res;
  }

  /**
   * ...
   */
  @Action
  async list() {
    const role = auth.getAssertedActiveRole();

    let data: Invite[] = [];

    if (auth.isActiveRole(9)) {
      data = await api.invites.list();
    } else if (auth.isActiveRole(7) && role.reseller?.id) {
      data = await api.invites.getByReseller({ resellerId: role.reseller.id });
    } else if (auth.isActiveRole(4, 5) && role.organization) {
      data = await api.invites.getByOrganization({
        organizationId: role.organization.id,
      });
    }

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Invites.GetActionOptions) {
    const data = await api.invites.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async send(options: Invites.SendActionOptions) {
    await api.invites.send(options);

    await this.context.dispatch('list');
  }

  /**
   * ...
   */
  @Action async resend(options: Invites.ResendActionOptions) {
    await api.organizations.resendInvites(options);

    await this.context.dispatch('get', { inviteId: options.invites[0] });
  }

  /**
   * ...
   */
  @Action
  async del(options: Invites.DeleteActionOptions) {
    const { me } = this.context.rootState;

    options.admin = auth.isActiveRole(9);

    if (!options.admin) options.organizationId = me.organization?.id;

    await api.invites.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace Invites {
  /**
   * ...
   */
  export interface State {
    items: Invite[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Invite[];
  }

  /** ... */
  export type AddMutationOptions = Invite;
  /** ... */
  // export type UpdateMutationOptions = UpdateInviteOptions;
  /** ... */
  export type DeleteMutationOptions = api.invites.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.invites.GetOptions;
  /** ... */
  export type SendActionOptions = api.invites.SendOptions;
  /** ... */
  export type ResendActionOptions = api.organizations.ResendInviteOptions;
  /** ... */
  export type DeleteActionOptions = api.invites.DeleteOptions;
}

export default Invites;
