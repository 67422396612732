
import { Vue, Component, Prop } from '@vue';

@Component
export default class AppComponent extends Vue {
  @Prop({ type: String, default: null }) readonly size!: string | null;
  @Prop([String, Number]) readonly price!: string | number;

  /** ... */
  get style() {
    const style: Partial<CSSStyleDeclaration> = {};

    if (this.size) {
      style.fontSize = this.size;
    }

    return style;
  }

  /** ... */
  get amount() {
    // ...
    const amount = parseFloat(this.price.toString());

    if (isNaN(amount) || amount < 0) {
      throw new Error('[]');
    }

    return amount;
  }

  /** ... */
  get isFree() {
    return this.amount === 0;
  }

  /** ... */
  get displayPrice() {
    const [dollars, change] = this.amount
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      .split('.');

    return { dollars, change };
  }
}
