import { request } from '@api/request';

/**
 * ...
 */
export interface LocationInfo {
  ip: string;
  success: boolean;
  type: 'IPv4' | 'IPv6';
  continent: string;
  continentCode: string;
  country: string;
  countryCode: string;
  region: string;
  regionCode: string;
  city: string;
  latitude: number;
  longitude: number;
  isEu: boolean;
  postal: string;
  callingCode: string; // "1"
  capital: string;
  borders: string;
  flag: FlagInfo;
  connection: ConnectionInfo;
  timezone: TimezoneInfo;
}

export interface FlagInfo {
  img: string;
  emoji: string;
  emojiUnicode: string;
}

export interface ConnectionInfo {
  asn: number;
  org: string;
  isp: string;
  domain: string;
}

export interface TimezoneInfo {
  id: string;
  abbr: string; // "EST"
  isDst: boolean;
  offset: number;
  utc: string;
  currentTime: string;
}

/**
 * ...
 *
 * @returns ...
 */
export async function getInfo() {
  const {
    country_code,
    region_code,
    is_eu,
    calling_code,
    ...data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = await request.get<any>('http://ipwho.is');

  const locationInfo: LocationInfo = {
    ...data,
    countryCode: country_code,
    regionCode: region_code,
    isEu: is_eu,
    callingCode: calling_code,
  };

  locationInfo.flag = {
    img: data.flag.img,
    emoji: data.flag.emoji,
    emojiUnicode: data.flag.emoji_unicode,
  };

  locationInfo.timezone = {
    id: data.timezone.id,
    abbr: data.timezone.abbr,
    isDst: data.timezone.is_dst,
    offset: data.timezone.offset,
    utc: data.timezone.utc,
    currentTime: data.timezone.current_time,
  };

  return locationInfo;
}
