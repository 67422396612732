import { Module, VuexModule } from '@vuex/decorators';

import { Promotion } from '@interfaces';
import { Root } from '@store';
import { modals } from '@modals';
import { router } from '@router';
import { dates } from '@utils/dates';

import imageNistBucket from '@assets/images/nist/NIST_Buckets.png';
import imageOrqaPromo from '@assets/images/orqa-promo.png';
import imageIslandScenario from '@assets/images/sim-scenarios/island.png';

const PROMOTIONS: Promotion[] = [
  {
    id: 'processor-down',
    title: 'Payment Processor Down',
    body: 'Our payment processor is currently down. We are in the process of transitioning to a new payment processor in the coming weeks. We apologize for the inconvenience. If you need to make a purchase in the meantime, please contact support@littlearms.com for assistance.',
    navbarText: 'Payment processor is currently down',
    navbarTextMobile: 'Payment processor is currently down',
    navbarBackgroundColor: '#000',
    callToAction: {
      buttonVariant: 'primary',
      fn: () => {
        void router.push({ name: 'paymentProcessorChange2024' });
        window.scrollTo(0, 0);
      },
      text: 'More info',
    },
  },
  {
    id: '0',
    title: 'Certifications',
    body: 'The moment has come! Zephyr now offers Certifications!\n\nOur first certification is offered in partnership with the Airborne Public Safety Association (APSA). APSA has developed an APSA-NIST Basic Proficiency Evaluation for Remote Pilot (BPERP) Certificate in accordance with the NIST Open Lane Test Methods for Small Unmanned Aircraft Systems. Zephyr offers a BPERP training scenario and Virtual Certification option, too.\n\nClick the button below to find out more.',
    navbarText:
      'Certifications are here! Get started with the BPERP Certification',
    navbarTextMobile: 'BPERP Certification!',
    navbarBackgroundColor: '#44b1ff',
    image: imageNistBucket,
    callToAction: {
      buttonVariant: 'primary',
      fn: () => {
        void router.push({ name: 'certifications' });
        window.scrollTo(0, 0);
      },
      text: 'More info',
    },
    emoji: '🏷️',
  },
  {
    id: 'orqa-controller',
    title: 'Orqa FPV Controller Now Available',
    body: 'The Orqa FPV controller is now available for purchase through Zephyr!\n\nFunctionalities:\n- Built-in rechargeable battery\n- USB-C for connectivity and charging\n- Low latency Bluetooth radio links for mobile and desktop connectivity\n\nLook & Feel:\n- Gamepad style ergonomics\n- Perfect fit for small or big hands\n- Robust and solid feel\n- Programmable buttons\n\nDedicated FPV.Ctrl Mobile App to Manage your settings. Search "FPV.Ctrl" on iOS or Android app stores.\n\nOrder yours today!',
    navbarText: 'NEW Orqa FPV Controller Now Available',
    navbarTextMobile: 'New Orqa FPV Controller!',
    navbarBackgroundColor: '#9244ff',
    image: imageOrqaPromo,
    callToAction: {
      buttonVariant: 'primary',
      fn: () => {
        void modals.shop.productDetails({ productId: 'ORQA-CONTROLLER' });
      },
      text: 'More info',
    },
  },
  {
    id: '1',
    title: 'Warehouse',
    body: 'This fully-realized Warehouse scenario includes cargo containers, industrial shelving, and stacks of palettes inviting you to discover how tight of a space you can pilot your drone through. A plethora of modules await you as well, aimed at helping you hone your interior and exterior flight skills.',
    navbarText:
      'New Scenario: Warehouse! Hone your interior and exterior flight skills',
    navbarTextMobile: 'New Scenario! Warehouse',
    textAlign: 'text-left',
    youtubeVideoId: 'JU1WYuO2nOE',
    callToAction: {
      buttonVariant: 'primary',
      fn: () => {
        void modals.shop.productDetails({ productId: 'WAREHOUSE' });
      },
      text: 'More info',
    },
  },
  {
    id: '2',
    title: 'The Island',
    navbarText:
      'New Scenario: The Island! A massive island with a quaint village',
    navbarTextMobile: 'New Scenario! The Island',
    body: '\n\n\n\n\nA massive island with a quaint village, towering lighthouse, small harbor, and more points of interest to discover and explore.\n\nThe Island scenario includes a multitude of modules to develop your exterior flight skills.\n\n\n\n\n',
    image: imageIslandScenario,
    callToAction: {
      buttonVariant: 'primary',
      fn: () => {
        void modals.shop.productDetails({ productId: 'THE-ISLAND' });
      },
      text: 'More info',
    },
  },
];

@Module({ namespaced: true })
export class Promotions
  extends VuexModule<Promotions.State, Root.State>
  implements Promotions.State
{
  today = new Date();

  items = [
    ...PROMOTIONS.filter((promotion: Promotion) => {
      if (!promotion.startDate && !promotion.endDate) return true;

      let startDate, endDate;

      if (promotion.startDate && typeof promotion.startDate === 'string')
        startDate = new Date(promotion.startDate);

      if (promotion.endDate && typeof promotion.endDate === 'string')
        endDate = new Date(promotion.endDate);

      if (startDate && !endDate) return dates.isAfter(this.today, startDate);

      if (!startDate && endDate) return dates.isBefore(this.today, endDate);

      if (startDate && endDate)
        return dates.isBetween(this.today, startDate, endDate);

      return true;
    }),
  ];
}

export namespace Promotions {
  /**
   * ...
   */
  export interface State {
    items: Promotion[];
  }
}

export default Promotions;
