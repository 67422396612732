var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "print-address-line",
      style: { flexDirection: _vm.singleLine ? "row" : "column" },
    },
    [
      _c("span", [
        _vm._v(_vm._s(_vm.address.first) + " " + _vm._s(_vm.address.last)),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.address.address1))]),
      _vm._v(" "),
      _vm.address.address2
        ? _c("span", [_vm._v(_vm._s(_vm.address.address2))])
        : _vm._e(),
      _vm._v(" "),
      _vm.address.address3
        ? _c("span", [_vm._v(_vm._s(_vm.address.address3))])
        : _vm._e(),
      _vm._v(" "),
      _c("span", [
        _vm.address.stateProvince
          ? _c("span", [_vm._v(_vm._s(_vm.address.stateProvince))])
          : _vm._e(),
        _vm._v(" "),
        _vm.address.city
          ? _c("span", [_vm._v(_vm._s(_vm.address.city))])
          : _vm._e(),
        _vm._v(" "),
        _vm.address.postalCode
          ? _c("span", [_vm._v(_vm._s(_vm.address.postalCode))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.address.country))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }