var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "site-documentV2" },
    _vm._l(
      _vm.items,
      function ({ key, type, text, raw, classList, depth, style }, index) {
        return _c(
          "div",
          { key: key },
          [
            type === "heading"
              ? _c(
                  "b-row",
                  { class: classList },
                  [
                    _c("b-col", [
                      depth === 1
                        ? _c(
                            "h1",
                            {
                              class: classList ?? [
                                "doc-section-title",
                                "h1",
                                "mt-3",
                              ],
                            },
                            [
                              _vm._v(
                                "\n          " + _vm._s(text) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      depth === 2
                        ? _c(
                            "h2",
                            {
                              class: classList ?? [
                                "doc-section-title",
                                "h2",
                                "mt-3",
                              ],
                            },
                            [
                              _vm._v(
                                "\n          " + _vm._s(text) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      depth === 3
                        ? _c(
                            "h3",
                            {
                              class: classList ?? [
                                "doc-section-title",
                                "h3",
                                "mt-3",
                              ],
                            },
                            [
                              _vm._v(
                                "\n          " + _vm._s(text) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      depth === 4
                        ? _c(
                            "h4",
                            {
                              class: classList ?? [
                                "doc-section-title",
                                "h4",
                                "mt-3",
                              ],
                            },
                            [
                              _vm._v(
                                "\n          " + _vm._s(text) + "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-row",
              {
                style: {
                  textAlign: type === "paragraph" ? "justify" : "unset",
                },
                attrs: { "align-h": "between" },
              },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "12" } },
                  [
                    type === "list"
                      ? [_c("ul", { domProps: { innerHTML: _vm._s(text) } })]
                      : _vm._e(),
                    _vm._v(" "),
                    type === "paragraph"
                      ? [
                          _c("p", {
                            class: [...(classList ?? [])],
                            domProps: { innerHTML: _vm._s(text) },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            type === "hint-info"
              ? _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("div", { staticClass: "hint-info-card mb-3 mt-3" }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("Icon", {
                              staticStyle: { "padding-right": "10px" },
                              attrs: { icon: _vm.icons.faCircleInfo },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              class: classList,
                              domProps: { innerHTML: _vm._s(text) },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            type === "hint-warning"
              ? _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c(
                        "div",
                        { staticClass: "hint-warning-card mb-3 mt-3" },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("Icon", {
                                staticStyle: { "padding-right": "10px" },
                                attrs: { icon: _vm.icons.faLightbulbOn },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                class: classList,
                                domProps: { innerHTML: _vm._s(text) },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            type === "hint-danger"
              ? _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("div", { staticClass: "hint-danger-card mb-3 mt-3" }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("Icon", {
                              staticStyle: { "padding-right": "10px" },
                              attrs: { icon: _vm.icons.faTriangleExclamation },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              class: classList,
                              domProps: { innerHTML: _vm._s(text) },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }