
import { Vue, Component, Prop } from '@vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { uniqueId } from 'lodash';

/**
 * ...
 */
export interface Post {
  title: string;
  date: string;
  link: string;
  icon: IconDefinition;
}

@Component
export default class PostList extends Vue {
  @Prop(Array) readonly posts!: Post[];

  /**
   * ...
   */
  get items() {
    return this.posts.map((post) => ({ ...post, key: uniqueId() }));
  }
}
