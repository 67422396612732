var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [_c("h6", { staticClass: "mb-0" }, [_vm._v("My Content")])]
      ),
      _vm._v(" "),
      _c("b-card-body", { staticClass: "p-0" }, [
        _c("div", { staticClass: "separator-title" }, [_vm._v("My Scenarios")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scenes-list" },
          _vm._l(_setup.scenarios, function (scenario) {
            return _c(
              "div",
              {
                key: scenario.id,
                class: [
                  { highlighted: _setup.isScenarioHighlighted(scenario.id) },
                ],
              },
              [_vm._v("\n        " + _vm._s(scenario.name) + "\n      ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "separator-title my-2" }, [
          _vm._v("My Licenses"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scenes-list my-2" },
          _vm._l(_setup.licenses, function (license) {
            return _c(
              "div",
              {
                key: license.id,
                class: [
                  "py-2",
                  "d-flex",
                  "justify-content-baseline",
                  { highlighted: license.id === _setup.focusedId },
                ],
                on: {
                  mouseenter: function ($event) {
                    return _setup.setFocusedLicenseId(license.id)
                  },
                  mouseleave: function ($event) {
                    return _setup.setFocusedLicenseId()
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-flex flex-column justify-content-between px-2 license-name",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "m-0" },
                      [
                        _c("Icon", { attrs: { icon: _setup.faIdCard } }),
                        _vm._v(
                          "\n            " +
                            _vm._s(license.productName || license.productId) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      license.expiration
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n              Expires on\n              "
                              ),
                              _c("DisplayTextDate", {
                                attrs: {
                                  value: license.expiration,
                                  format: "MM/dd/yyyy",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("Does not expire")]),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _setup.unownedProducts.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "separator-title recommendations" }, [
                  _vm._v("\n        Purchase Recommendations\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "TransitionGroup",
                  { attrs: { appear: "" } },
                  _vm._l(_setup.unownedProducts, function (product, i) {
                    return _c(
                      "div",
                      {
                        key: product.id,
                        class: [
                          "py-3",
                          "d-flex",
                          "justify-content-between",
                          "align-items-center",
                          "product-container",
                          {
                            "border-bottom":
                              i < _setup.unownedProducts.length - 1,
                          },
                        ],
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "card-product px-3" },
                          [
                            _c("div", { staticClass: "h6 m-0 product-name" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(product.name || product.id) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "connector" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("DisplayTextCost", {
                                  class: { strikethrough: product.onSale },
                                  attrs: { value: product.price },
                                }),
                                _vm._v(" "),
                                product.onSale
                                  ? _c("DisplayTextCost", {
                                      attrs: { value: product.salePrice },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _setup.showDetails(product.id)
                                  },
                                },
                              },
                              [_vm._v("\n              Details\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _setup.addToCart(product)
                                  },
                                },
                              },
                              [_vm._v("\n              Add\n            ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }