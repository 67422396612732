var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { appear: "" } }, [
    _c("div", { staticClass: "promo-cart-item" }, [
      _c("div", { staticClass: "item-info-container" }, [
        _c("div", { staticClass: "item-info-col info-thumbnail" }, [
          _c("div", {
            staticClass: "item-thumbnail",
            style: { background: `url('${_vm.thumbnail}')` },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-info-section ml-4" }, [
          _c("div", { staticClass: "item-info-col info-details" }, [
            _c("div", { staticClass: "item-name" }, [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("div", { staticClass: "item-description" }, [
              _vm._v(_vm._s(_vm.description)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-info-col info-price" }, [
            _c("div", { staticClass: "item-prices-wrapper" }, [
              _vm.onSale
                ? _c(
                    "span",
                    { staticClass: "regular-price" },
                    [_c("DisplayTextCost", { attrs: { value: _vm.price } })],
                    1
                  )
                : _vm.isBundle
                ? _c(
                    "span",
                    { staticClass: "regular-price" },
                    [
                      _c("DisplayTextCost", {
                        attrs: { value: _vm.fullBundlePrice },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "current-price" },
                [_c("DisplayTextCost", { attrs: { value: _vm.finalPrice } })],
                1
              ),
            ]),
            _vm._v(" "),
            !_vm.inCart
              ? _c(
                  "button",
                  { staticClass: "v-btn v-btn-sml", on: { click: _vm.add } },
                  [_vm._v("\n            Add\n          ")]
                )
              : _c(
                  "button",
                  { staticClass: "v-btn v-btn-sml", on: { click: _vm.remove } },
                  [_vm._v("\n            Remove\n          ")]
                ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.bundledItems.length
        ? _c(
            "div",
            { staticClass: "sub-item-container" },
            _vm._l(_vm.bundledItems, function (item) {
              return _c(
                "CheckoutPromoItem",
                _vm._b({ key: item.id }, "CheckoutPromoItem", item, false)
              )
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }