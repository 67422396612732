import { isString, isNumber, isUndefined } from '@tools/type-guards';

interface NumberInfo {
  /** List of digits that represent the number. */
  digits: number[];
  /** The exponent of the number. */
  exponent: number;
  /** Number of digits in the integer part of the number. */
  integerDigitCount: number;
}

/** Maximum number of digits allowed in the format result. */
const MAX_DIGITS = 22;
/** Default character for representing `0` used in format result. */
const ZERO_CHAR = '0';
/** Default currency symbol character used in format result. */
const CURRENCY_SYM = '$';
/** Default decimal separator character used in format result. */
const DECIMAL_SEP = '.';
/** Default group separator character used in format result. */
const GROUP_SEP = ',';

/** ... */
const PATTERNS = {
  gSize: 3,
  lgSize: 3,
  maxFrac: 2,
  minFrac: 2,
  minInt: 1,
  negPre: '-\u00a4',
  negSuf: '',
  posPre: '\u00a4',
  posSuf: '',
};

/**
 * ...
 *
 * @param amount ...
 * @param currencySymbol ...
 * @param fractionSize ...
 * @return ...
 */
export function currencyFilter(
  amount: number,
  currencySymbol = CURRENCY_SYM,
  fractionSize = PATTERNS.maxFrac,
) {
  // If the currency symbol is empty, trim whitespace around the symbol.
  const currencySymbolRe = !currencySymbol ? /\s*\u00A4\s*/g : /\u00A4/g;

  return formatNumber(amount, fractionSize).replace(
    currencySymbolRe,
    currencySymbol,
  );
}

export default currencyFilter;

//#region Helper Functions

/**
 * ...
 *
 * @param parsedNumber ...
 * @param fractionSize ...
 * @return ...
 */
function roundNumber(parsedNumber: NumberInfo, fractionSize: number) {
  const digits = parsedNumber.digits;

  let fractionLen = digits.length - parsedNumber.integerDigitCount;

  // Determine fraction size if it is not specified.
  fractionSize = isUndefined(fractionSize)
    ? Math.min(Math.max(PATTERNS.minFrac, fractionLen), PATTERNS.maxFrac)
    : +fractionSize;

  // The index of the digit to where rounding is to occur.
  let roundAt = fractionSize + parsedNumber.integerDigitCount;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const digit = digits[roundAt]!;

  if (roundAt > 0) {
    // Drop fractional digits beyond `roundAt`.
    digits.splice(Math.max(parsedNumber.integerDigitCount, roundAt));

    // Set non-fractional digits beyond `roundAt` to 0.
    for (let j = roundAt; j < digits.length; j++) digits[j] = 0;
  } else {
    // We rounded to zero so reset the parsedNumber.
    fractionLen = Math.max(0, fractionLen);

    parsedNumber.integerDigitCount = 1;

    digits.length = Math.max(1, (roundAt = fractionSize + 1));
    digits[0] = 0;

    for (let i = 1; i < roundAt; i++) digits[i] = 0;
  }

  if (digit >= 5) {
    if (roundAt - 1 < 0) {
      for (let k = 0; k > roundAt; k--) {
        digits.unshift(0);
        parsedNumber.integerDigitCount++;
      }

      digits.unshift(1);
      parsedNumber.integerDigitCount++;
    } else {
      digits[roundAt - 1]++;
    }
  }

  // Pad out with zeros to get the required fraction length.
  for (; fractionLen < Math.max(0, fractionSize); fractionLen++) digits.push(0);

  // Do any carrying, e.g. a digit was rounded up to 10.
  const carry = digits.reduceRight((carry, d, i, digits) => {
    d += carry;
    digits[i] = d % 10;

    return Math.floor(d / 10);
  }, 0);

  if (carry) {
    digits.unshift(carry);
    parsedNumber.integerDigitCount++;
  }
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function getNumberInfo(value: number) {
  let numStr = value.toString();

  let exponent = 0;
  let digits: number[] = [];
  let integerDigitCount = numStr.indexOf(DECIMAL_SEP);

  // Decimal point?
  if (integerDigitCount > -1) {
    numStr = numStr.replace(DECIMAL_SEP, '');
  }

  // ...
  const exponentSymbolIndex = numStr.search(/e/i);

  // Exponential form?
  if (exponentSymbolIndex > 0) {
    // Work out the exponent.
    if (integerDigitCount < 0) integerDigitCount = exponentSymbolIndex;
    integerDigitCount += +numStr.slice(exponentSymbolIndex + 1);
    numStr = numStr.substring(0, exponentSymbolIndex);
  } else if (integerDigitCount < 0) {
    // There was no decimal point or exponent so it is an integer.
    integerDigitCount = numStr.length;
  }

  let zeros = numStr.length;

  // Count the number of leading zeros.

  let leadingZerosCount = 0;

  for (; numStr.charAt(leadingZerosCount) === ZERO_CHAR; leadingZerosCount++) {
    continue;
  }

  if (leadingZerosCount === zeros) {
    // The digits are all zero.
    digits = [0];
    integerDigitCount = 1;
  } else {
    // Count the number of trailing zeros.
    zeros--;

    while (numStr.charAt(zeros) === ZERO_CHAR) zeros--;

    // Trailing zeros are insignificant so ignore them.
    integerDigitCount -= leadingZerosCount;
    digits = [];

    // Convert string to array of digits without leading/trailing zeros.
    for (let i = 0; leadingZerosCount <= zeros; leadingZerosCount++, i++) {
      digits[i] = +numStr.charAt(leadingZerosCount);
    }
  }

  // If the number overflows the maximum allowed digits then use an exponent.
  if (integerDigitCount > MAX_DIGITS) {
    digits = digits.splice(0, MAX_DIGITS - 1);
    exponent = integerDigitCount - 1;
    integerDigitCount = 1;
  }

  return { digits, exponent, integerDigitCount } as NumberInfo;
}

/**
 * ...
 *
 * @param number ...
 * @param fractionSize ...
 * @return ...
 */
function formatNumber(number: number, fractionSize: number) {
  // Return an empty string if the provided number value is not valid.
  if (!(isString(number) || isNumber(number)) || isNaN(number)) return '';

  let isZero = false;
  let formattedText = '\u221e';

  if (isFinite(number)) {
    const parsedNumber = getNumberInfo(Math.abs(number));

    roundNumber(parsedNumber, fractionSize);

    const exponent = parsedNumber.exponent;

    let digits = parsedNumber.digits;
    let integerLen = parsedNumber.integerDigitCount;
    let decimals: number[] = [];

    isZero = digits.reduce((isZero: boolean, d: number) => isZero && !d, true);

    // Pad zeros for small numbers.
    while (integerLen < 0) {
      digits.unshift(0);
      integerLen++;
    }

    // Extract decimals digits.
    if (integerLen > 0) {
      decimals = digits.splice(integerLen, digits.length);
    } else {
      decimals = digits;
      digits = [0];
    }

    // Format the integer digits with grouping separators.
    const groups = [];

    if (digits.length >= PATTERNS.lgSize) {
      groups.unshift(digits.splice(-PATTERNS.lgSize, digits.length).join(''));
    }

    while (digits.length > PATTERNS.gSize) {
      groups.unshift(digits.splice(-PATTERNS.gSize, digits.length).join(''));
    }

    if (digits.length) {
      groups.unshift(digits.join(''));
    }

    formattedText = groups.join(GROUP_SEP);

    // Append the decimal digits.
    if (decimals.length) {
      formattedText += DECIMAL_SEP + decimals.join('');
    }

    if (exponent) {
      formattedText += `e+${exponent}`;
    }
  }

  let prefix;
  let suffix;

  if (number < 0 && !isZero) {
    prefix = PATTERNS.negPre;
    suffix = PATTERNS.negSuf;
  } else {
    prefix = PATTERNS.posPre;
    suffix = PATTERNS.posSuf;
  }

  return prefix + formattedText + suffix;
}

//#endregion Helper Functions
