import { render, staticRenderFns } from "./ModalFormSection.vue?vue&type=template&id=10407fea&scoped=true"
import script from "./ModalFormSection.vue?vue&type=script&setup=true&lang=ts"
export * from "./ModalFormSection.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ModalFormSection.vue?vue&type=style&index=0&id=10407fea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10407fea",
  null
  
)

export default component.exports