var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "pull-right",
        attrs: {
          href: "https://www.braintreegateway.com/merchants/g7cbctynsg4fbjcx/verified",
          target: "_blank",
        },
      },
      [
        _c(
          "img",
          _vm._b(
            {
              attrs: {
                src: "https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-dark.png",
                border: "0",
              },
            },
            "img",
            _setup.size,
            false
          )
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }