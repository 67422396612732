var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("CardStat", _vm._b({}, "CardStat", _vm.cardProps, false), [
    _c(
      "span",
      { staticClass: "percentage-display" },
      [
        _c("RadialProgressBar", {
          attrs: {
            diameter: 100,
            "completed-steps": _vm.completedSteps,
            "total-steps": _vm.totalSteps,
            "start-color": "#ffa93e",
            "stop-color": "#ff7f42",
            "inner-stroke-color": "#ffffff29",
            "inner-stroke-width": 5,
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "percentage-value h4 mb-0" }, [
          _vm._v(_vm._s(_vm.percentDisplay) + "%"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }