import { isObject } from '@tools/type-guards';

/** Response data object structure from the server. */
export interface ApiStandardResponse<T = unknown> {
  error?: boolean;
  data?: T;
  feedback?: string;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
export function isStandardResponse(data: unknown): data is ApiStandardResponse {
  return (
    isObject(data) && ('error' in data || 'data' in data || 'feedback' in data)
  );
}

/** Response data object structure from serverless. */
export interface ApiServerlessResponse {
  code?: string;
  message?: string;
  statusCode?: number;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
export function isServerlessResponse(
  data: unknown,
): data is ApiServerlessResponse {
  return (
    isObject(data) &&
    ('code' in data || 'message' in data || 'statusCode' in data)
  );
}
