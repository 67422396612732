var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CardLoadable,
    { attrs: { loading: !!_vm.loading, "no-body": "" } },
    [
      _c("b-card-header", { staticClass: "bg-transparent" }, [
        _c("h4", { staticClass: "mb-0 text-center" }, [
          _vm._v(_vm._s(_setup.title)),
        ]),
      ]),
      _vm._v(" "),
      !_vm.loading
        ? [
            _c(
              "b-card-body",
              { staticClass: "orqa-bg" },
              [
                _c("div", { staticClass: "overlay" }),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "justify-content-center align-items-center" },
                  [
                    _c("b-col", { staticClass: "col-auto" }, [
                      _c("div", [
                        _c("span", {
                          staticClass: "pre-wrap",
                          domProps: { innerHTML: _vm._s(_setup.body) },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-row justify-content-center" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _setup.openOrqaModal()
                          },
                        },
                      },
                      [_vm._v("Buy Now")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }