var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selector-panel" },
    [
      _c("SelectionGroup", {
        attrs: {
          label: _vm.optionsLabel || "Options",
          items: _vm.groups.left,
          text: _vm.text,
          subtext: _vm.subtext,
        },
        on: { removed: _vm.add },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "selector-center-col" },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { disabled: !_vm.groups.left.length },
              on: { click: _vm.addAll },
            },
            [_vm._v("\n      Add All\n    ")]
          ),
          _vm._v(" "),
          _c("Icon", { attrs: { icon: _vm.icons.faArrowRight } }),
          _vm._v(" "),
          _c("Icon", { attrs: { icon: _vm.icons.faArrowLeft } }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { disabled: !_vm.groups.right.length },
              on: { click: _vm.removeAll },
            },
            [_vm._v("\n      Remove All\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SelectionGroup", {
        attrs: {
          label: _vm.selectionLabel || "Selection",
          items: _vm.groups.right,
          text: _vm.text,
          subtext: _vm.subtext,
        },
        on: { removed: _vm.remove },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }