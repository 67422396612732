var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        { staticClass: "p-0" },
        [
          _c("h5", { staticClass: "mb-4" }, [_vm._v("Institution Seats")]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "percentage-display mb-4" },
            [
              _c(_setup.RadialProgressBar, {
                attrs: {
                  diameter: 150,
                  "completed-steps": _setup.completedSteps,
                  "total-steps": 100,
                  "start-color": "#ff8e40",
                  "stop-color": "#ff8e40",
                  "inner-stroke-color": "#ffffff29",
                  "inner-stroke-width": 5,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "percentage-value h4 mb-0" }, [
                _c("span", [
                  _vm._v(_vm._s(_setup.used) + " / " + _vm._s(_setup.total)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.organization.billingType === "Reseller"
            ? _c("p", [
                _vm._v(
                  "\n      To add more seats, please contact your administrator\n      "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: _setup.reseller.website, target: "_blank" },
                  },
                  [_vm._v("here")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "secondary", size: "lg" },
              on: { click: _setup.addInstitutionSeats },
            },
            [_vm._v("\n      Add Licenses\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }