var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "score-table-cell" },
    [
      _vm.loading
        ? _c("div", { staticClass: "cell-container centered no-value" }, [
            _c("span", [_vm._v("Loading...")]),
          ])
        : !_vm.initialized
        ? _c("div", { staticClass: "cell-container centered no-value" }, [
            _c("span", [_vm._v("[ Not Loaded ]")]),
          ])
        : !_vm.rating
        ? _c("div", { staticClass: "cell-container centered no-value" }, [
            _vm._v("--"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("Transition", [
        !_vm.loading && _vm.initialized
          ? _c("div", { staticClass: "cell-container" }, [
              _c("span", [_vm._v(_vm._s(_vm.displayValue))]),
              _vm._v(" "),
              _vm.rating
                ? _c(
                    "span",
                    { class: ["rating-indicator", "ml-2", _vm.rating.value] },
                    [_c("span", [_vm._v(_vm._s(_vm.rating.text))])]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }