var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-split-view" }, [
    _c("div", { staticClass: "nav-pane" }, [
      _c(
        "div",
        { staticClass: "nav-pane-side-panel" },
        _vm._l(_vm.panes, function (pane) {
          return _c(
            "div",
            {
              key: pane.id,
              class: [
                "side-panel-item",
                { active: pane.id === _setup.activePaneId },
              ],
              on: {
                click: function ($event) {
                  _setup.activePaneId = pane.id
                },
              },
            },
            [
              _c(_setup.Icon, {
                staticClass: "mr-3",
                attrs: { icon: pane.icon },
              }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(pane.label))]),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nav-pane-main-panel" }, [
        _c(
          "div",
          [
            _c(_setup.ModalHeader),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "nav-pane-main-panel" },
              [
                _c(
                  _setup.ModalBody,
                  _vm._b(
                    { staticClass: "main-panel-content" },
                    "ModalBody",
                    {
                      loading: _setup.props.loading,
                      processing: _setup.props.processing,
                    },
                    false
                  ),
                  [
                    _vm._t("default", null, null, {
                      activePanel: _setup.activePanel,
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("footer"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }