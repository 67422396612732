/**
 * Global site tag (gtag.js) - Google Analytics
 */

const GA_MEASUREMENT_ID = 'G-LD3FG1MB9H';

const gtagScript = document.createElement('script');
const scriptTag = document.getElementsByTagName('script')[0];

gtagScript.async = true;
gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;

scriptTag.parentNode.insertBefore(gtagScript, scriptTag);

window.dataLayer = window.dataLayer || [];

function gtag() {
  window.dataLayer.push(arguments);
}

gtag('js', new Date());

gtag('config', 'GA_MEASUREMENT_ID');
