import { render, staticRenderFns } from "./FormBraintree.vue?vue&type=template&id=2dbaafb8&scoped=true"
import script from "./FormBraintree.vue?vue&type=script&setup=true&lang=ts"
export * from "./FormBraintree.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FormBraintree.vue?vue&type=style&index=0&id=2dbaafb8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbaafb8",
  null
  
)

export default component.exports