import { Vue } from '@vue';
import { BootstrapVue } from 'bootstrap-vue';
import { VueNotifications } from 'vue-notifications';

import { store } from '@store';
import { router } from '@router';

import '@api';
import '@auth';
import '@services';
import '@components';
import '@directives';
import '@filters';
import '@modals';
import '@styles';

import App from './App.vue';

declare module '@vue' {
  interface ExtendedVue {
    $env: typeof ENVIRONMENT;
    $log: typeof console.log;
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $env: typeof ENVIRONMENT;
    $log: typeof console.log;
  }
}

const ENVIRONMENT = {
  isBuild: !!BUILD,
  isDevelopment: !!DEVELOPMENT,
};

Vue.prototype.$env = ENVIRONMENT;
// eslint-disable-next-line no-console
Vue.prototype.$log = console.log;

Vue.use(BootstrapVue);
Vue.use(VueNotifications);

Vue.config.productionTip = false;

/** Webpage Vue app instance. */
export const app = new Vue({
  el: '#app',
  name: 'Zephyr',
  router,
  store,
  render: (h) => h(App),
});
