var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["total-line", { large: _vm.large }] },
    [
      _c("p", { staticClass: "total-line-label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c("Spacer"),
      _vm._v(" "),
      _vm.pending
        ? _c("p", { staticClass: "total-line-value status" }, [
            _vm._v("Pending"),
          ])
        : _vm.loading
        ? _c(
            "p",
            { staticClass: "total-line-value status" },
            [
              _c("Spinner", { staticClass: "mr-2" }),
              _vm._v(" Calculating\n  "),
            ],
            1
          )
        : _c(
            "p",
            { class: ["total-line-value", { changed: _setup.valueChanged }] },
            [
              _c(
                "DisplayTextCost",
                _vm._b({}, "DisplayTextCost", { value: _vm.value }, false)
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }