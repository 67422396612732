
import { Vue, Component, Prop, Mutation } from '@vue';

import { Product } from '@models';
import { ensureError } from '@tools/ensure-error';

import { faStar } from '@icons/regular/faStar';

import * as store from '@store';

@Component
export default class CardUnownedProducts extends Vue {
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Array) readonly products!: store.Shop.Item[];

  readonly icons = { faStar };

  @Mutation('cart/ADD_ITEM') readonly addItem!: store.Cart['ADD_ITEM'];

  /**
   *
   * @param product
   */
  addToCart(product: Product) {
    let error: ensureError.Error | null = null;

    try {
      this.addItem({ item: product });
    } catch (err) {
      error = ensureError(err);
    }

    if (error) {
      if (error.code === 'DUPLICATE_ITEM_SCENES') {
        return this.$alert.warning(error.message);
      }
    }
  }

  /**
   *
   *  @param product
   */
  showDetails(product: Product) {
    void this.$modals.shop.productDetails({ productId: product.id });
  }
}
