
import { Vue, Component, Prop } from '@vue';

import { faPlus } from '@icons/regular/faPlus';
import { faMinus } from '@icons/regular/faMinus';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardExpandable: ComponentWithProps<Props>;
  }
}

/**
 * ...
 */
export interface Props {
  /** ... */
  label?: string;
}

@Component
export default class CardExpandable extends Vue {
  @Prop(String) readonly label!: string;

  readonly icons = { faPlus, faMinus };

  expanded = false;
}
