import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';

import { Discount } from '@models';
import { modals } from '@modals';
import { RoleId } from '@values/roles';

/** ... */
const columns: TablePanel.Column<Discount>[] = [
  {
    key: 'id',
    label: 'ID Code',
    value: 'id',
  },
  {
    key: 'amount',
    label: 'Amount',
    type: 'currency',
    value: 'amount',
    placeholder: 'N/A',
  },
  {
    key: 'percent',
    label: 'Percent',
    type: 'percent',
    value: 'percent',
    placeholder: 'N/A',
  },
  {
    key: 'uses',
    label: 'Uses/Max Uses',
    value: ({ maxUses, uses }) =>
      maxUses !== 0 ? `${uses ?? '--'}/${maxUses ?? '--'}` : null,
    placeholder: 'N/A',
  },
  {
    key: 'expirationDate',
    label: 'Expiration Date',
    type: 'date',
    value: 'expirationDate',
    placeholder: 'N/A',
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
    placeholder: 'N/A',
  },
  {
    key: 'product',
    label: 'Product',
    // value: ({ product }) => product?.name,
    value: ({ productId }) => productId,
    placeholder: 'N/A',
  },
  {
    key: 'institution',
    label: 'Institution',
    // value: ({ institution }) => institution?.name,
    value: ({ institutionId }) => institutionId,
    placeholder: 'N/A',
  },
  {
    key: 'user',
    label: 'User',
    // value: ({ user }) =>
    //   user ? `${user.lastName}, ${user.firstName} | ${user.email}` : null,
    value: ({ userId }) => userId,
    placeholder: 'N/A',
  },
];

/** ... */
const operations: TablePanel.Row.Operation<Discount>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: (discount) => modals.discount.edit({ discount }),
  },
];

@Module({ namespaced: true })
export class DiscountsTablePanel
  extends TablePanel<Discount>
  implements TablePanel.Props<Discount>
{
  readonly module = 'discounts';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'DISCOUNTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createDiscount';
  readonly deleteAction = 'deleteDiscount';
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;

  get enabled() {
    return this.isActiveRole(RoleId.LasAdmin);
  }

  @Action
  async createDiscount() {
    await modals.discount.create();
  }

  @Action
  async deleteDiscount({ items }: DiscountsTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'discounts', items });
  }
}

export namespace DiscountsTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Discount[];
  }
}
