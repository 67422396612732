var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-form",
    _vm._b({ staticClass: "form" }, "b-form", { name: _vm.name }, false),
    [
      _c(_setup.FormFieldInput, {
        ref: "first",
        staticClass: "flex-col-md-6 flex-col-sm-12",
        attrs: {
          field: _setup.v.first,
          label: "First Name",
          validate: _setup.fieldValidation,
        },
      }),
      _vm._v(" "),
      _c(_setup.FormFieldInput, {
        ref: "last",
        staticClass: "flex-col-md-6 flex-col-sm-12",
        attrs: {
          field: _setup.v.last,
          label: "Last Name",
          validate: _setup.fieldValidation,
        },
      }),
      _vm._v(" "),
      _c(_setup.FormFieldSelect, {
        ref: "country",
        attrs: {
          label: "Country",
          field: _setup.v.country,
          options: _setup.countryOptions,
          validate: _setup.fieldValidation,
        },
      }),
      _vm._v(" "),
      _setup.fieldInfo
        ? [
            _c(_setup.FormFieldInput, {
              ref: "address1",
              attrs: {
                label: _setup.fieldInfo.thoroughfare,
                field: _setup.v.address1,
                validate: _setup.fieldValidation,
              },
            }),
            _vm._v(" "),
            _setup.fieldInfo.premise
              ? _c(_setup.FormFieldInput, {
                  ref: "address2",
                  attrs: {
                    label: _setup.fieldInfo.premise + " (optional)",
                    field: _setup.v.address2,
                    validate: _setup.fieldValidation,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _setup.fieldInfo.localityName
              ? _c(_setup.FormFieldInput, {
                  ref: "city",
                  staticClass: "flex-col-md-4",
                  attrs: {
                    field: _setup.v.city,
                    label: _setup.fieldInfo.localityName,
                    validate: _setup.fieldValidation,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _setup.fieldInfo.administrativeArea
              ? [
                  _setup.administrativeAreaOptions
                    ? _c(_setup.FormFieldSelect, {
                        ref: "stateProvince",
                        class: [`flex-col-md-4`, "flex-col-sm-12"],
                        attrs: {
                          label: _setup.fieldInfo.administrativeArea.label,
                          field: _setup.v.stateProvince,
                          options: _setup.administrativeAreaOptions,
                          validate: _setup.fieldValidation,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [
                                      _vm._v(
                                        "\n            Select " +
                                          _vm._s(
                                            _setup.fieldInfo.administrativeArea
                                              .label
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3229383100
                        ),
                      })
                    : _c(_setup.FormFieldInput, {
                        ref: "stateProvince",
                        class: [`flex-col-md-4`, "flex-col-sm-12"],
                        attrs: {
                          label: _setup.fieldInfo.administrativeArea.label,
                          field: _setup.v.stateProvince,
                          validate: _setup.fieldValidation,
                        },
                      }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _setup.fieldInfo.postalCode
              ? _c(_setup.FormFieldInput, {
                  ref: "postalCode",
                  class: [`flex-col-md-4`, "flex-col-sm-12"],
                  attrs: {
                    label: _setup.fieldInfo.postalCode.label,
                    field: _setup.v.postalCode,
                    validate: _setup.fieldValidation,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }