import {
  http,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from '@services/http';

import { isArray } from '@tools/type-guards';

import { ApiRequestError, TransformError, TransformGroupError } from './errors';
import { isStandardResponse } from './validators';
import { logger } from './logger';

/** Alias for {@link AxiosRequestConfig}. */
type Config = AxiosRequestConfig;
/** Request response data transformer function. */
type Transformer<T> = (item: unknown) => T;

class ApiServerClient {
  /**
   * ...
   *
   * @param route ...
   * @return ...
   */
  async get<T = unknown>(route: string, transformer?: Transformer<T>) {
    const data = await requestWrapper(
      http.get(`${process.env.API_ORIGIN}/${route}`),
    );

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * ...
   *
   * @param route ...
   * @param transformer ...
   * @return ...
   */
  async list<T = unknown>(route: string, transformer?: Transformer<T>) {
    const data = await requestWrapper(
      http.get(`${process.env.API_ORIGIN}/${route}`),
    );

    if (!isArray(data)) {
      throw new Error(
        `[ServerlessApiRequest.list] data returned from the request to ${route} was not an array.`,
      );
    }

    if (!transformer) return data as T[];

    const transformedData: T[] = [];
    const transformErrors: TransformError[] = [];

    for (const item of data) {
      try {
        transformedData.push(transformer(item));
      } catch (err) {
        transformErrors.push(new TransformError(item, err));
      }
    }

    if (transformErrors.length) {
      // eslint-disable-next-line no-console
      console.error(new TransformGroupError(transformErrors));
    }

    return transformedData;
  }

  /**
   * ...
   *
   * @param route ...
   * @param payload ...
   * @param transformer ...
   * @return ...
   */
  async post<T = unknown>(
    route: string,
    payload?: unknown,
    transformer?: Transformer<T>,
  ) {
    const data = await requestWrapper(
      http.post(`${process.env.API_ORIGIN}/${route}`, payload),
    );

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * ...
   *
   * @param route ...
   * @param payload ...
   * @param transformer ...
   * @return ...
   */
  async query<T = unknown>(
    route: string,
    payload?: unknown,
    transformer?: Transformer<T>,
  ) {
    const data = await requestWrapper(
      http.post(`${process.env.API_ORIGIN}/${route}`, payload),
    );

    if (!isArray(data)) {
      throw new Error(
        `[ServerlessApiRequest.query] data returned from the request to ${route} was not an array.`,
      );
    }

    if (!transformer) return data as T[];

    const transformedData: T[] = [];
    const transformErrors: TransformError[] = [];

    for (const item of data) {
      try {
        transformedData.push(transformer(item));
      } catch (err) {
        transformErrors.push(new TransformError(item, err));
      }
    }

    if (transformErrors.length) {
      // eslint-disable-next-line no-console
      console.error(new TransformGroupError(transformErrors));
    }

    return transformedData;
  }

  /**
   * ...
   *
   * @param route ...
   * @param transformer ...
   * @return ...
   */
  async delete<T = unknown>(route: string, transformer?: Transformer<T>) {
    const data = await requestWrapper(
      http.delete(`${process.env.API_ORIGIN}/${route}`),
    );

    return transformer ? transformer(data) : (data as T);
  }
}

/** Zephyr API server client. */
export const server = new ApiServerClient();

class OldApiServerClient {
  /** ... */
  readonly serverless = server;

  /**
   * ...
   *
   * @param route ...
   * @param config ...
   * @param transformer ...
   * @return ...
   */

  async get<T = unknown>(
    route: string,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async get<T = unknown>(
    route: string,
    config?: Config,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async get<T = unknown>(route: string, ...args: unknown[]) {
    const config =
      !args[0] || typeof args[0] === 'function'
        ? undefined
        : (args[0] as AxiosRequestConfig);

    const transformer =
      typeof args[1] === 'function'
        ? (args[1] as Transformer<T>)
        : typeof args[0] === 'function'
        ? (args[0] as Transformer<T>)
        : null;

    const data = await requestWrapper(http.get(route, config));

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * ...
   *
   * @param route ...
   * @param config ...
   * @param transformer ...
   * @return ...
   */
  async list<T = unknown>(
    route: string,
    transformer?: Transformer<T>,
  ): Promise<T[]>;
  async list<T = unknown>(
    route: string,
    config?: Config,
    transformer?: Transformer<T>,
  ): Promise<T[]>;
  async list<T = unknown>(route: string, ...args: unknown[]) {
    const config =
      !args[0] || typeof args[0] === 'function'
        ? undefined
        : (args[0] as Config);

    const transformer =
      typeof args[1] === 'function'
        ? (args[1] as Transformer<T>)
        : typeof args[0] === 'function'
        ? (args[0] as Transformer<T>)
        : null;

    const data = await requestWrapper(http.get(route, config));

    if (!isArray(data)) {
      throw new Error(
        `[ApiRequest.list] data returned from the request to ${route} was not an array.`,
      );
    }

    return transformer ? data.map(transformer) : (data as T[]);
  }

  /**
   * ...
   *
   * @param route ...
   * @param payload ...
   * @param config ...
   * @param transformer ...
   * @return ...
   */
  async post<T = unknown>(
    route: string,
    payload?: unknown,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async post<T = unknown>(
    route: string,
    payload?: unknown,
    config?: Config,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async post<T = unknown>(
    route: string,
    payload?: unknown,
    ...args: unknown[]
  ) {
    const config =
      !args[0] || typeof args[0] === 'function'
        ? undefined
        : (args[0] as Config);

    const transformer =
      typeof args[1] === 'function'
        ? (args[1] as Transformer<T>)
        : typeof args[0] === 'function'
        ? (args[0] as Transformer<T>)
        : null;

    const data = await requestWrapper(http.post(route, payload, config));

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * ...
   *
   * @param route ...
   * @param payload ...
   * @param config ...
   * @param transformer ...
   * @return ...
   */
  async query<T = unknown>(
    route: string,
    payload?: unknown,
    transformer?: Transformer<T>,
  ): Promise<T[]>;
  async query<T = unknown>(
    route: string,
    payload?: unknown,
    config?: Config,
    transformer?: Transformer<T>,
  ): Promise<T[]>;
  async query<T = unknown>(
    route: string,
    payload?: unknown,
    ...args: unknown[]
  ) {
    const config =
      !args[0] || typeof args[0] === 'function'
        ? undefined
        : (args[0] as Config);

    const transformer =
      typeof args[1] === 'function'
        ? (args[1] as Transformer<T>)
        : typeof args[0] === 'function'
        ? (args[0] as Transformer<T>)
        : null;

    const data = await requestWrapper(http.post(route, payload, config));

    if (!isArray(data)) {
      throw new Error(
        `[ApiRequest.list] data returned from the request to ${route} was not an array.`,
      );
    }

    return transformer ? data.map(transformer) : (data as T[]);
  }

  /**
   * ...
   *
   * @param route ...
   * @param payload ...
   * @param config ...
   * @param transformer ...
   * @return ...
   */
  async put<T = unknown>(
    route: string,
    payload?: unknown,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async put<T = unknown>(
    route: string,
    payload?: unknown,
    config?: Config,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async put<T = unknown>(route: string, payload?: unknown, ...args: unknown[]) {
    const config =
      !args[0] || typeof args[0] === 'function'
        ? undefined
        : (args[0] as Config);

    const transformer =
      typeof args[1] === 'function'
        ? (args[1] as Transformer<T>)
        : typeof args[0] === 'function'
        ? (args[0] as Transformer<T>)
        : null;

    const data = await requestWrapper(http.put(route, payload, config));

    return transformer ? transformer(data) : (data as T);
  }

  /**
   * ...
   *
   * @param route ...
   * @param config ...
   * @param transformer ...
   * @return ...
   */

  async delete<T = unknown>(
    route: string,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async delete<T = unknown>(
    route: string,
    config?: Config,
    transformer?: Transformer<T>,
  ): Promise<T>;
  async delete<T = unknown>(route: string, ...args: unknown[]) {
    const config =
      !args[0] || typeof args[0] === 'function'
        ? undefined
        : (args[0] as Config);

    const transformer =
      typeof args[1] === 'function'
        ? (args[1] as Transformer<T>)
        : typeof args[0] === 'function'
        ? (args[0] as Transformer<T>)
        : null;

    const data = await requestWrapper(http.delete(route, config));

    return transformer ? transformer(data) : (data as T);
  }
}

/**
 * (Old) Zephyr API server client.
 *
 * @deprecated
 */
export const request = new OldApiServerClient();

export default request;

//#region Errors

class InvalidResponseError extends Error {
  name = 'InvalidResponseError';

  constructor(error: AxiosError | null) {
    let { message, ...props } = error ?? {};

    message =
      '[api.request] A request resulted in an invalid response' +
      (message ? `: ${message}` : '.');

    super(message);

    Object.assign(this, props);
  }
}

//#endregion Errors

//#region Helper Functions

/**
 * ...
 *
 * @param request ...
 * @return ...
 */
async function requestWrapper<T = unknown>(request: Promise<AxiosResponse<T>>) {
  let response: AxiosResponse<unknown> | null = null;
  let error: AxiosError | null = null;

  // Create a timestamp before making the request so its duration can be
  // calculated.
  const timestamp = Date.now();

  try {
    response = await request;
  } catch (err) {
    error = err as AxiosError;
  }

  // Ensure regardless of the result of the call that we're dealing with a valid
  // `AxiosResponse`. If not, some other issue internal to axios occurred.
  const result = error?.response ?? response ?? null;

  if (!result) {
    throw new InvalidResponseError(error);
  }

  // Log the resulting `AxiosResponse`.
  logger(result, Date.now() - timestamp);

  // If the error was thrown by axios during the call, throw using the
  // `AxiosError` object itself.
  if (error) {
    throw new ApiRequestError(error);
  }

  return result.data;
}

//#endregion Helper Functions
