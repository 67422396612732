var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "new-modal-header" }, [
    _setup.modal.dismissible
      ? _c("div", [_c(_setup.ModalCloseButton)], 1)
      : _vm._e(),
    _vm._v(" "),
    _setup.modal.title && !_vm.noTitle
      ? _c("div", { staticClass: "title" }, [
          _c("h4", { staticClass: "mt-5" }, [
            _vm._v(_vm._s(_setup.modal.title)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }