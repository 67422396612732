
import { Vue, Component, Prop } from '@vue';

import { faExclamationCircle } from '@icons/solid/faExclamationCircle';

export interface Announcement {
  title: string;
  details?: string;
}

@Component
export default class SiteAnnouncement extends Vue {
  @Prop(Object) readonly announcement!: Announcement;

  hovering = false;
  dismissed = false;

  readonly icons = {
    faExclamationCircle,
  };

  get title() {
    return this.announcement?.title ? this.announcement.title : null;
  }
  get details() {
    return this.announcement?.details ? this.announcement.details : null;
  }

  created() {
    console.log(this.announcement);
  }

  async showDetails() {
    if (!this.details || typeof this.details !== 'string') return;

    const options = {
      title: 'Maintenance Nov. 19-20',
      text: this.details,
    };

    await this.$modals.util.generalInfo(options);
  }
}
