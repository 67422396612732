import { currencyFilter } from '@filters/currency';
import { dateFilter } from '@filters/date';
import { Invoice, Transaction } from '@models';
import {
  Base64ImageObject,
  DocProperties,
  pdfGenerator,
} from '@services/pdf-generator';

import zephyrLogo from '@assets/images/zephyr_logo.png';

const DocProps: DocProperties = {
  format: 'letter',
  orientation: 'portrait',
  margin: 15,
  pageWidth: 0,
  pageHeight: 0,
  lineSpacing: 5,
  lineHeight: 12,
  // computed from above properties
  pageWidthInner: 0,
  pageHeightInner: 0,
  pageCenter: 0,
  // for tracking current vertical position in page
  yPos: 0,
};

/**
 * Generate an invoice PDF and open it within a print job dialogue window on the
 * user's computer.
 *
 * @param invoice The invoice to generate the PDF of.
 * @param transaction Transaction info to include in the PDF, if desired.
 */
export function printInvoice(invoice: Invoice, transaction?: Transaction) {
  const doc = pdfGenerator.create(DocProps);

  // Insert Zephyr Image
  // from the assets/images/zephyr_logo.png and /little-arms-logo.png
  const zephyrImage: Base64ImageObject = pdfGenerator.getImageElem(
    zephyrLogo,
    182.5,
    50,
  );

  pdfGenerator.addImage(
    doc,
    zephyrImage.img,
    'PNG',
    'left',
    zephyrImage.width,
    zephyrImage.height,
    DocProps,
  );

  // Invoice Details
  pdfGenerator.addText(doc, DocProps, 'Invoice Details', { size: 14 });

  const head = [['', '']];

  const body = [];

  let address = invoice.organizationBilling.address1.toString();

  if (invoice.organizationBilling.address2) {
    address += '\n' + invoice.organizationBilling.address2.toString();
  }

  if (invoice.organizationBilling.address3) {
    address += '\n' + invoice.organizationBilling.address3.toString();
  }

  address += '\n' + invoice.organizationBilling.city.toString();

  if (invoice.organizationBilling.stateProvince) {
    address += ', ' + invoice.organizationBilling.stateProvince.toString();
  }

  if (invoice.organizationBilling.postalCode) {
    address +=
      ' ' +
      invoice.organizationBilling.postalCode.toString() +
      '\n' +
      invoice.organizationBilling.country.toString();
  }

  // const costPerSeat = currencyFilter(invoice.costPerSeat, '$', 2).toString();

  body.push(['ID', `${invoice.id}`]);
  body.push(['Status', `${invoice.status}`]);

  const dueDate = dateFilter(invoice.dueDate, 'MM/dd/yyyy').toString();
  body.push(['Due Date', `${dueDate}`]);

  body.push(['Institution', `${invoice.organization.name}`]);
  body.push(['Institution Billing Address', `${address}`]);

  const lineItems = invoice.lineItems?.length
    ? invoice.lineItems
    : generateLineItems(invoice);

  lineItems.forEach((lineItem) => {
    body.push([
      'Items',
      `${lineItem.productId} | ${lineItem.quantity} x ${currencyFilter(
        lineItem.price,
        '$',
        2,
      ).toString()}`,
    ]);
  });

  // body.push(['Number of Licenses', `${invoice.seats}`]);
  // body.push(['Cost Per Seat', `${costPerSeat}`]);

  // Handle debits/credits
  if (invoice.details) {
    let creditsDebits = '';
    const creditsDebitsRow = ['Credits/Debits'];

    for (const item in invoice.details) {
      const price = currencyFilter(parseFloat(item), '$', 2).toString();
      creditsDebits += price + '\n';
    }

    creditsDebitsRow.push(creditsDebits);

    body.push(creditsDebitsRow);
  }

  const salesTax = currencyFilter(invoice.salesTax, '$', 2);
  body.push(['Sales Tax', `${salesTax}`]);

  const total = currencyFilter(invoice.amount, '$', 2);
  body.push(['Total', `${total}`]);

  pdfGenerator.createTable(head, body, doc, DocProps, { showHead: false });

  // If Transaction is detected, add details here
  if (transaction) {
    pdfGenerator.addText(doc, DocProps, 'Transaction Details', { size: 14 });

    const tHead = [['', '']];
    const tBody = [];

    tBody.push(['Transaction ID', `${transaction.id}`]);
    tBody.push(['Status', `${transaction.status}`]);
    tBody.push(['Card Type', `${transaction.creditCard.cardType.toString()}`]);
    tBody.push(['Card Used', `XXXX XXXX XXXX ${transaction.creditCard.last4}`]);
    tBody.push(['Card Expiration', `${transaction.creditCard.expirationDate}`]);

    pdfGenerator.createTable(tHead, tBody, doc, DocProps, {
      showHead: false,
    });
  }

  // Insert invoice message
  pdfGenerator.addText(
    doc,
    DocProps,
    `Thank you for your business! If you have any questions or concerns please contact us at support@littlearms.com`,
    { align: 'center' },
  );

  const today = new Date();

  doc.save(
    `Zephyr_Invoice_${invoice.id}-${
      today.getMonth() + 1
    }-${today.getDay()}-${today.getFullYear()}.pdf`,
  );
}

export default printInvoice;

//#region Helper Functions

function generateLineItems(invoice: Invoice) {
  return [
    {
      price: invoice.costPerSeat,
      productId: 'Seat',
      quantity: invoice.seats,
    },
  ];
}

//#endregion Helper Functions
