import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEye } from '@icons/solid/faEye';

import { dateFilter } from '@filters/date';
import { Order } from '@models';
import { modals } from '@modals';

const columns: TablePanel.Column<Order>[] = [
  {
    key: 'created',
    label: 'Created',
    value: ({ createdAt }) => dateFilter(createdAt, 'MM/dd/yyyy hh:mm:ss'),
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
  },
  {
    key: 'total',
    label: 'Total',
    type: 'currency',
    value: 'total',
  },
];

const operations: TablePanel.Row.Operation<Order>[] = [
  {
    label: 'View',
    icon: faEye,
    // fn: ({ id }) => {
    //   void modals.transaction.view({ orderId: id });
    // }
    fn: (order) => {
      void modals.transaction.view({ order });
    },
  },
];

@Module({ namespaced: true })
export class MyTransactionsTablePanel
  extends TablePanel<Order>
  implements TablePanel.Props<Order>
{
  readonly module = 'orders';
  readonly loadAction = 'listMine';
  readonly pk = 'id';
  readonly label = 'MY ORDERS';
  readonly columns = columns;
  readonly operations = operations;

  readonly enabled = true;
}
