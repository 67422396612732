import { Vue } from '@vue';

import { isFunction } from '@tools/type-guards';
import { importContext } from '@utils/import-context';

/** Required context for local Vue filter modules. */
const context = require.context('.', true, /\.\/[\w-]+(\.ts|\/index\.ts)$/);

importContext(context, ({ name, value }) => {
  // If the imported value is not a Vue filter, log a warning n the console
  // and skip registration.
  if (!isFunction(value)) {
    // eslint-disable-next-line no-console
    return console.warn(
      `[filters] the imported module "${name}" is not a valid Vue filter.`,
    );
  }

  Vue.filter(name, value);
});
