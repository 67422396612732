import { openModal } from '../open-modal';
import { useStore, store } from '@store';

/**
 * Open Cart modal.
 *
 * @return Modal promise.
 */
export async function cart() {
  const modalOptions = {
    title: 'CART',
    size: 'lg',
    component: () => import('./Cart.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * Open Create Product modal.
 *
 * @return Modal promise.
 */
export async function createProduct() {
  const modalOptions = {
    title: 'CREATE PRODUCT',
    component: () => import('./CreateProduct.vue'),
  };

  await openModal.safe(modalOptions);

  // prevCtrl.allProducts();
}

/**
 * ...
 */
export interface EditProductModalOptions {
  productId: string;
}

/**
 * Open Edit Product modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function editProduct(options: EditProductModalOptions) {
  const modalOptions = {
    title: 'EDIT PRODUCT',
    props: { ...options },
    component: () => import('./EditProduct.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ProductDetailsModalOptions {
  productId: string;
}

/**
 * Open Product Details modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function productDetails(options: ProductDetailsModalOptions) {
  if (!useStore().state.shop.products[options.productId])
    await store.dispatch('shop/load');

  const modalOptions = {
    title: 'PRODUCT DETAILS',
    size: 'lg',
    props: { ...options },
    component: () => import('./ProductDetails.vue'),
  };

  await openModal.safe(modalOptions);
}
