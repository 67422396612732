
import { Vue, Component, Prop } from '@vue';
import { State, Getter, Mutation } from '@vuex/class';
import { find, sumBy } from 'lodash';

import * as store from '@store';
import { ensureError } from '@tools/ensure-error';

@Component
export default class CheckoutPromoItem extends Vue {
  @Prop(String) readonly id!: string;
  @Prop(String) readonly name!: string;
  @Prop(String) readonly description!: string;
  @Prop(Number) readonly price!: number;
  @Prop(Number) readonly finalPrice!: number;
  @Prop(Boolean) readonly onSale!: boolean;
  @Prop(Array) readonly images!: unknown[];
  @Prop(Array) readonly scenes!: unknown[];
  @Prop(Array) readonly bundledItems!: unknown[];

  @State('shop') readonly shop!: store.Shop;
  @State('cart') readonly cart!: store.Cart;

  @Getter('shop/getProduct') readonly getProduct!: store.Shop['getProduct'];

  @Mutation('cart/ADD_ITEM')
  readonly addItem!: store.Cart['ADD_ITEM'];
  @Mutation('cart/REMOVE_ITEM')
  readonly removeCartItem!: store.Cart['REMOVE_ITEM'];

  /** ... */
  get thumbnail() {
    return this.images[0];
  }

  /** ... */
  get inCart() {
    return !!this.cart.items.find(({ id }) => id === this.id);
  }

  /** ... */
  get isBundle() {
    return !!this.bundledItems.length;
  }

  /** ... */
  get fullBundlePrice() {
    return sumBy(this.bundledItems, 'finalPrice');
  }

  /**
   * ...
   */
  add() {
    const item = this.getProduct(this.id);

    if (this.bundledItems.length) {
      for (const bi of this.bundledItems) {
        if (find(this.cart.items, { id: bi.id })) {
          this.removeCartItem({ itemId: bi.id });
        }
      }
    }

    let error: ErrorOrNullish = null;

    try {
      this.addItem({ item });
    } catch (err) {
      error = ensureError(err);
    }

    if (error) {
      console.error(error);
    }

    if (
      error?.code === 'DUPLICATE_ITEM_SCENES' ||
      error?.code === 'BASE_LICENSE_LIMIT_REACHED'
    ) {
      this.$alert.warning(error.message);
    }
  }

  /**
   * ...
   */
  remove() {
    this.removeCartItem({ itemId: this.id });
  }
}
