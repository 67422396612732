import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';
import { auth } from '@auth';

import { isObject } from '@tools/type-guards';

export * from 'axios';

declare module 'vue/types/vue' {
  interface Vue {
    /** {@link AxiosStatic} http instance used by the application. */
    $http: AxiosStatic;
  }
}

/**
 * Request interceptor that sets the "Authorization" header using the access
 * token belonging to the current Cognito user session, if one exists.
 */
async function onRequest(config: AxiosRequestConfig) {
  // Ensure the header value is an object.
  const headers = isObject(config.headers) ? config.headers : {};

  // Attempt tp get the access token from the current Cognito user session.
  // Obviously, this will result in `null` if no user session is active.
  const token = await auth.getAccessToken();

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return Object.assign(config, { headers });
}

/**
 * App http service for making authenticated requests to the server.
 */
export const http = axios.create();

http.interceptors.request.use(onRequest);

export default axios;
