var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", [
    _setup.enabled
      ? _c(
          "footer",
          [
            _c(
              "b-container",
              { staticClass: "px-5 footer-container" },
              [
                _c(
                  "b-row",
                  { staticClass: "py-4 justify-content-between" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "http://www.littlearmsstudios.com",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "little-arms-logo-wrapper" },
                                  [
                                    _c(_setup.Toothy),
                                    _vm._v(" "),
                                    _c(_setup.LittleArmsStudios),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _c("h6", { staticClass: "text-muted" }, [
                              _vm._v("Little Arms, Big Ideas!"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "max-width": "200px" } }, [
                            _c(
                              "p",
                              {
                                staticClass: "text-muted",
                                staticStyle: { "text-align": "justify" },
                              },
                              [
                                _vm._v(
                                  "\n                Fly without risking hardware, having to charge drone\n                batteries, and worrying about weather. Users can train\n                wherever and whenever they want.\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c("h4", [_vm._v("Company")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "no-bullets" },
                          _vm._l(
                            _setup.COMPANY_LINKS,
                            function ({ label, route }) {
                              return _c(
                                "li",
                                { key: route, staticClass: "py-1" },
                                [
                                  _c("RouterLink", { attrs: { to: route } }, [
                                    _vm._v(_vm._s(label)),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c("h4", [_vm._v("Platform")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "no-bullets" },
                          [
                            _c(
                              "li",
                              { staticClass: "py-1" },
                              [
                                _c(
                                  "RouterLink",
                                  { attrs: { to: "/individuals" } },
                                  [_vm._v("Individual Pricing")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "py-1" },
                              [
                                _c(
                                  "RouterLink",
                                  { attrs: { to: "/enterprise" } },
                                  [_vm._v("Enterprise Pricing")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _setup.PLATFORM_LINKS,
                              function ({ label, route }) {
                                return _c(
                                  "li",
                                  { key: route, staticClass: "py-1" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: route,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(label) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c("h4", [_vm._v("Resources")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "no-bullets" },
                          _vm._l(
                            _setup.RESOURCE_LINKS,
                            function ({ label, route }) {
                              return _c(
                                "li",
                                { key: route, staticClass: "py-1" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: route, target: "_blank" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(label) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "flex-wrap", attrs: { cols: "auto" } },
                      [
                        _c(
                          "RouterLink",
                          { attrs: { to: "system-requirements" } },
                          [
                            _c(
                              "div",
                              { staticClass: "platform-logo-container-footer" },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticStyle: {
                                      "padding-top": "15px",
                                      "padding-left": "15px",
                                      "padding-right": "15px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Requirements\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "platform-logo-image-wrapper-footer",
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "fa-3x",
                                      attrs: { icon: _setup.faApple },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "platform-logo-image-wrapper-footer",
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "fa-3x",
                                      attrs: { icon: _setup.faWindows },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "py-2" }),
                        _vm._v(" "),
                        _c(
                          "RouterLink",
                          { attrs: { to: "country-availability" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "platform-availability-container-footer",
                              },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticStyle: {
                                      "padding-top": "15px",
                                      "padding-left": "15px",
                                      "padding-right": "15px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Availability\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "platform-logo-image-wrapper-footer",
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "fa-3x",
                                      attrs: { icon: _setup.faEarthAmericas },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "pt-1 pb-4 justify-content-between" },
                  [
                    _c("b-col", { attrs: { cols: "auto" } }, [
                      _c("p", { staticClass: "m-0" }, [
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v("© Zephyr " + _vm._s(_setup.YEAR) + " "),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted m-2" }, [
                          _vm._v("|"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v("v" + _vm._s(_setup.VERSION)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { attrs: { cols: "auto" } },
                      _vm._l(
                        _setup.SOCIAL_LINKS,
                        function ({ key, icon, route }) {
                          return _c(
                            "a",
                            {
                              key: key,
                              staticClass: "social-link mx-2",
                              attrs: { href: route, target: "_blank" },
                            },
                            [_c("Icon", { attrs: { icon: icon } })],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }