import { api } from '@api';
import { Certification, User, Role } from '@models';

import { openModal } from '../open-modal';

export interface EditCertificationPermissionOptions {
  roles: Role[];
  userId: string;
}

export async function editCertificationPermissions(
  options: EditCertificationPermissionOptions,
) {
  const { roles, userId } = options;

  await openModal.safe({
    title: 'Edit Certification Permissions',
    props: { roles, userId },
    component: () => import('./EditCertificationPermissions.vue'),
  });
}

export interface BulkReviewCertificationOptions {
  status: 'APPROVED';
  users: User[];
}

export async function bulkReviewCertification(
  options: BulkReviewCertificationOptions,
) {
  const { users, status } = options;

  let subTitle;
  if (status === 'APPROVED') subTitle = 'Approve';
  // if (status === 'DENIED') subTitle = 'Deny';
  // if (status === 'REVOKED') subTitle = 'Revoke';

  await openModal.safe({
    title: `Bulk ${subTitle} Certifications`,
    props: { users, status },
    size: 'lg',
    component: () => import('./BulkReviewCertificationExam.vue'),
  });
}

/**
 * {@link quickReviewCertification Quick Review Certification} modal
 * configuration options.
 */
export interface QuickReviewCertificationOptions {
  status: 'APPROVED' | 'DENIED' | 'REVOKED';
  certifications: Certification[];
}

/**
 * Open Quick Review Certifications modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function quickReviewCertification(
  options: QuickReviewCertificationOptions,
) {
  const { certifications, status } = options;

  let subTitle;
  if (status === 'APPROVED') subTitle = 'Approve';
  if (status === 'DENIED') subTitle = 'Deny';
  if (status === 'REVOKED') subTitle = 'Revoke';

  await openModal.safe({
    title: `Quick ${subTitle} Certification`,
    props: { certifications, status },
    component: () => import('./QuickReviewCertification.vue'),
  });
}

/**
 * {@link issueCertificationExamToStudents Issue Certification Exam To Students}
 * modal configuration options.
 */
export interface IssueCertificationExamToStudentsModalOptions {
  students: User.BasicInfo[];
}

/**
 * Open Issue Certification Exam To Students modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function issueCertificationExamToStudents(
  options: IssueCertificationExamToStudentsModalOptions,
) {
  const { students } = options;

  await openModal.safe({
    title: 'ISSUE CERTIFICATION EXAM TO STUDENTS',
    props: { students },
    component: () => import('./IssueCertificationExamToStudents.vue'),
  });
}

/**
 * {@link view View Certification} modal configuration options.
 */
export interface ViewCertificationModalOptions {
  certificationId: Certification['id'];
}

/**
 * View Certification modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function view(options: ViewCertificationModalOptions) {
  // Get specified certification.
  const certification = await api.certifications.get(options);

  return openModal.safe({
    title: 'VIEW CERTIFICATION',
    size: 'lg',
    props: { certification },
    component: () => import('./ViewCertification/index.vue'),
  });
}

/**
 * {@link reviewAttempt Review Certification Attempt} modal configuration
 * options.
 */
export interface ReviewCertificationAttemptModalOptions {
  certificationId: Certification['id'];
}

/**
 * Review Certification Attempt modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function reviewAttempt(
  options: ReviewCertificationAttemptModalOptions,
) {
  // Get specified certification.
  const certification = await api.certifications.get(options);

  return openModal.safe({
    title: 'REVIEW CERTIFICATION ATTEMPT',
    props: { certification },
    component: () => import('./ReviewCertificationAttempt/index.vue'),
  });
}

/**
 * {@link submitAttempt Submit Certification Attempt} modal configuration
 * options.
 */
export interface SubmitCertificationAttemptModalOptions {
  certificationId: Certification['id'];
}

/**
 * Submit Certification Attempt modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function submitAttempt(
  options: SubmitCertificationAttemptModalOptions,
) {
  // Get specified certification.
  const certification = await api.certifications.get(options);

  return openModal.safe({
    title: 'SUBMIT CERTIFICATION ATTEMPT',
    props: { certification },
    component: () => import('./SubmitCertificationAttempt/index.vue'),
  });
}
