import { api } from '@api';
import * as models from '@models';
import { auth } from '@auth';
import { store } from '@store';

import { openModal } from '../open-modal';

/**
 * Add Seats modal configuration options.
 */
export interface AddSeatsModalOptions {
  seats: number;
  transactionAmount: number;
}

/**
 * Add Seats modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function addSeats(options: AddSeatsModalOptions) {
  const props = {
    transactionAmount: options.transactionAmount,
    seats: options.seats,
    hideExtensionCodeField: true,
  };

  await openModal.safe({
    title: 'ADD SEATS',
    size: 'md',
    props,
    component: () => import('./AddSeats.vue'),
  });
}

/**
 * Additional Info modal configuration options.
 */
export interface AdditionalInfoModalOptions {
  first?: string | null;
  last?: string | null;
  country?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateProvince?: string | null;
  postalCode?: string | null;
  phone?: string | null;
  shippingForm?: boolean;
  phoneRequired?: boolean;
}

/** ... */
export type AdditionalInfoModalOutput = ZephyrWeb.BillingInfo & {
  phone: string | null;
  first: string | null;
  last: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
};

/**
 * Additional Info modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function additionalInfo(options?: AdditionalInfoModalOptions) {
  const modalOptions: openModal.Options = {
    title: 'ADDITIONAL INFO',
    size: 'md',
    props: { ...(options ?? {}) },
    component: () => import('./AdditionalInfo.vue'),
  };

  let billingInfo: AdditionalInfoModalOutput | null = null;

  try {
    billingInfo =
      (await openModal<AdditionalInfoModalOutput>(modalOptions)) ?? null;
  } catch {
    // ...
  }

  return billingInfo;
}

/**
 * Login modal.
 *
 * @return Modal promise.
 */
interface referrerOption {
  referrer: string;
}

export async function login() {
  const modalOptions: openModal.Options = {
    title: 'USER LOGIN',
    size: 'md',
    component: () => import('./CheckoutLogin.vue'),
  };

  let referrer: referrerOption | null | undefined = null;

  try {
    referrer = await openModal(modalOptions);
  } catch {
    return referrer;
  }

  return referrer;
}

/**
 * Existing User Login modal configuration options.
 */
export interface ExistingUserLoginModalOptions {
  email: string;
}

/**
 * Existing User Login modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function existingUserLogin(
  options: ExistingUserLoginModalOptions,
) {
  const props = { initialEmail: options.email };

  const modalOptions: openModal.Options = {
    title: 'EXISTING USER LOGIN',
    size: 'md',
    props,
    component: () => import('./ExistingUserLogin.vue'),
  };

  let userLoggedIn: boolean;

  try {
    userLoggedIn = (await openModal<boolean>(modalOptions)) ?? false;
  } catch {
    userLoggedIn = false;
  }

  return userLoggedIn;
}

/**
 * Extend License modal configuration options.
 */
export interface ExtendLicenseModalOptions {
  transactionAmount?: number;
  courseId: models.Course['id'];
  extensionCode?: string;
}

/**
 * Extend License modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function extendLicense(options: ExtendLicenseModalOptions) {
  let course: models.Course | null = null;

  if (options.courseId) {
    course = await store.dispatch('courses/get', options);
  }

  const props: Record<string, unknown> = {
    extensionCode: options.extensionCode ?? null,
    hideExtensionCodeField: false,
    course: course,
  };

  if (typeof options.transactionAmount === 'number') {
    props['transactionAmount'] = options.transactionAmount;
  }

  await openModal.safe({
    title: 'EXTEND LICENSE',
    size: 'md',
    props,
    component: () => import('./ExtendLicense.vue'),
  });
}

/**
 * Pay Invoice modal configuration options.
 */
export interface PayInvoiceModalOptions {
  transactionAmount: number;
  invoiceId: models.Invoice['id'];
}

/**
 * Pay Invoice modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function payInvoice(options: PayInvoiceModalOptions) {
  let invoice: models.Invoice | null = null;

  if (options.invoiceId) {
    invoice = await store.dispatch('invoices/get', options);
  }

  const props = {
    transactionAmount: options.transactionAmount,
    hideExtensionCodeField: true,
    invoice,
  };

  await openModal.safe({
    title: 'PAY INVOICE',
    size: 'md',
    props,
    component: () => import('./PayInvoice.vue'),
  });
}

/**
 * Purchase Seats modal configuration options.
 */
export type PurchaseSeatsModalOptions = {
  organizationId: models.Organization['id'];
  transactionAmount?: number;
} & ({ courseId: models.Course['id'] } | { seats: number });

/**
 * Purchase Seats modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function purchaseSeats(options: PurchaseSeatsModalOptions) {
  let course: models.Course | null = null;

  if ('courseId' in options) {
    course = await store.dispatch('courses/get', {
      courseId: options.courseId,
      organizationId: options.organizationId,
    });
  }

  let title;
  let props;
  let component;

  if (course) {
    // Student Purchase Seat.
    title = 'PURCHASE SEATS';
    props = { ...options, course };
    component = () => import('./PurchaseSeat.vue');
  } else {
    // Institution Purchase Seats.
    title = 'PURCHASE SEATS FOR INSTITUTION';
    props = { ...options };
    component = () => import('./PurchaseSeatForOrganization.vue');
  }

  const modalOptions = { title, size: 'md', props, component } as const;

  try {
    await openModal<boolean>(modalOptions);
  } catch {
    return false;
  }

  return true;
}

/**
 * First Name/Last Name update required
 */
export interface FirstLastNameModalOptions {
  originalFirstName: string | null;
  originalLastName: string | null;
}

/**
 * First Name Last Name Update modal
 *
 * @param options Modal options.
 * @return Modal promise
 */
export async function firstLastName(options: FirstLastNameModalOptions) {
  const props = {
    originalFirstName: options.originalFirstName,
    originalLastName: options.originalLastName,
  };

  await openModal.safe({
    title: 'NAME UPDATE REQUIRED',
    size: 'md',
    props,
    component: () => import('./FirstLastName.vue'),
  });
}

/**
 * Purchase Cert for Student
 */

export interface PurchaseCertificationForStudentsModalOptions {
  organizationId: models.Organization['id'];
}

/**
 * Purchase Certification on Behalf of Students
 *
 * @param options ...
 * @return Modal promise.
 */
export async function purchaseCertificationForStudents(
  options: PurchaseCertificationForStudentsModalOptions,
) {
  const { organizationId } = options;

  const organization = await api.organizations.get({
    organizationId,
    admin: auth.isActiveRole(9),
  });

  const modalOptions = {
    title: 'PURCHASE CERTIFICATION FOR STUDENTS',
    props: { organization },
    component: () => import('./PurchaseCertificationForStudents.vue'),
  };

  return openModal.safe(modalOptions);
}
