var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { appear: "" } }, [
    _vm.opened
      ? _c(
          "div",
          {
            staticClass: "context-menu",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "ul",
              _vm._l(_vm.items, function (item) {
                return _c("li", { key: item.key }, [
                  item.type === "separator"
                    ? _c("div", { staticClass: "menu-item-separator" })
                    : _c(
                        "div",
                        {
                          staticClass: "menu-item-button",
                          on: {
                            click: function ($event) {
                              return _vm.onSelected(item.click)
                            },
                          },
                        },
                        [
                          item.icon
                            ? _c(
                                "span",
                                { staticClass: "icon-wrapper" },
                                [_c("Icon", { attrs: { icon: item.icon } })],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-wrapper" }, [
                            _vm._v(_vm._s(item.label)),
                          ]),
                        ]
                      ),
                ])
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }