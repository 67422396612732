import * as vuexClass from 'vuex-class';

// export * from 'vuex-class';

/** ... */
export type VuexDecorator = <V extends Vue>(proto: V, key: string) => void;

/**
 * ...
 */
export interface StateTransformer {
  // (state: unknown, getters: unknown): unknown;
}

/**
 * ...
 */
export interface BindingOptions {
  namespace?: string;
}

/**
 * ...
 */
export interface BindingHelper {
  <V extends Vue>(proto: V, key: string): void;
  (type: string, options?: BindingOptions): VuexDecorator;
}

/**
 * ...
 */
export interface StateBindingHelper extends BindingHelper {
  (type: StateTransformer, options?: BindingOptions): VuexDecorator;
}

/** ... */
export const State = vuexClass.State as StateBindingHelper;
/** ... */
export const Getter = vuexClass.Getter as BindingHelper;
/** ... */
export const Action = vuexClass.Action as BindingHelper;
/** ... */
export const Mutation = vuexClass.Mutation as BindingHelper;
/** ... */
export const namespace = vuexClass.namespace;
