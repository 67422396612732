var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selector-group" }, [
    _c("h3", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _c("div", { staticClass: "list-container" }, [
      _c(
        "ul",
        [
          !_vm.itemsLength
            ? _c("li", [
                _c("h4", { staticClass: "text-center" }, [_vm._v("No Items")]),
              ])
            : _vm._l(_vm.items, function ({ id, item }) {
                return _c(
                  "li",
                  {
                    key: id,
                    on: {
                      click: function ($event) {
                        return _vm.$emit("removed", id)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.text(item))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-subtext" }, [
                        _vm._v(_vm._s(_vm.subtext(item))),
                      ]),
                    ]),
                  ]
                )
              }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }