var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "CardLoadable",
    {
      attrs: {
        header: "Reviews Completed",
        loading: _vm.loading,
        "no-body": "",
      },
    },
    [
      _c(
        "b-card-body",
        [
          _c("ChartLine", {
            attrs: { "chart-data": _vm.certificationChartData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }