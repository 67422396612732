/**
 * ...
 *
 * @param input ...
 * @returns ...
 */
export function camelToReadableFilter(input: string) {
  const result = input.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default camelToReadableFilter;
