import { server } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

import { CartItemOption } from './shop';

/**
 * List all existing discounts.
 *
 * @return A list of discounts.
 */
export async function list() {
  return await server.list('v2/discounts', processDiscountData);
}

/**
 * ...
 */
export interface GetOptions {
  discountId: models.Discount['id'];
}

/**
 * Get a specified discount.
 *
 * @param options Request options bag.
 * @return The specified discount.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/discounts/${options.discountId}`,
    processDiscountData,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  discountId: models.Discount['id'];
  organizationId?: models.Organization['id'];
  userId?: models.User['id'];
  productId?: models.Product['id'];
  info?: string;
  amount?: number;
  percent?: number;
  maxUses?: number;
  expirationDate?: string;
}

/**
 * Create discount.
 *
 * @param options Request options object.
 * @return Discount information object.
 */
export async function create(options: CreateOptions) {
  return await server.post('v2/discounts', options, processDiscountData);
}

/**
 * ...
 */
export interface UpdateOptions {
  discountId: models.Discount['id'];
  organizationId?: models.Organization['id'];
  userId?: models.User['id'];
  productId?: models.Product['id'];
  info?: string;
  amount?: number;
  percent?: number;
  maxUses?: number;
  expirationDate?: string;
}

/**
 * Update a specific discount.
 *
 * @param options Request options object.
 */
export async function update(options: UpdateOptions) {
  const { discountId, ...data } = options;

  return await server.post(
    `v2/discounts/${discountId}`,
    data,
    processDiscountData,
  );
}

/**
 * ...
 */
export interface CalculateOptions {
  discountId: models.Discount['id'];
  cart: CartItemOption[];
}

/**
 * Calculate a discount amount for a given collection of products (cart).
 *
 * @param options Request options object.
 */
export async function calculate(options: CalculateOptions) {
  const data = { discountCode: options.discountId, cart: options.cart };

  return await server.post<number>('v2/discounts/calculate', data);
}

/**
 * ...
 */
export interface DeleteOptions {
  discountId: models.Discount['id'];
}

/**
 * Delete a specified discount.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  await server.delete(`v2/discounts/${options.discountId}`);
}

// region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidDiscountItem(value: unknown): value is models.Discount {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processDiscountData(data: unknown) {
  if (!isValidDiscountItem(data)) {
    throw new Error('Invalid discount data item.');
  }

  return { ...data } as models.Discount;
}

// endregion Helper Functions
