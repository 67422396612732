import Vue from 'vue';

import { modals } from './modals';

declare module 'vue/types/vue' {
  interface Vue {
    /** Series of Zephyr database API request-based services. */
    $modals: typeof modals;
  }
}

export type * as AccountModals from './account';
export type * as AnnouncementModals from './announcement';
export type * as AssignmentModals from './assignment';
export type * as CertificationsModals from './certifications';
export type * as CheckoutModals from './checkout';
export type * as ConfirmModals from './confirm';
export type * as CourseModals from './course';
export type * as DeveloperModals from './developer';
export type * as DiscountModals from './discount';
export type * as DlcModals from './dlc';
export type * as DroneModals from './drone';
export type * as ExtensionCodesModals from './extension-codes';
export type * as InvoiceModals from './invoice';
export type * as ModuleModals from './module';
export type * as OrganizationModals from './organization';
export type * as PaymentMethodModals from './payment-method';
export type * as ProductModals from './product';
export type * as ReportModals from './report';
export type * as ResellerModals from './reseller';
export type * as SceneModals from './scene';
export type * as SettingsModals from './settings';
export type * as ShopModals from './shop';
export type * as TransactionModals from './transaction';
export type * as UserModals from './user';
export type * as UtilModals from './util';
export type * as UnityCatalogModals from './unity-catalog';

export * from './uib-modals';
export * as modals from './modal-groups';
export * from './modals';

Vue.prototype.$modals = modals;
