var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    {
      staticClass: "section-promotions",
      on: { mouseover: _vm.stopCycle, mouseleave: _vm.startCycle },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-5 justify-content-md-center" },
        [
          _vm.currentPromo
            ? _c(
                "b-row",
                { staticClass: "justify-content-center mb-4" },
                [
                  _c(
                    "b-col",
                    { staticClass: "promo-container", attrs: { lg: "12" } },
                    [
                      _c("div", { staticClass: "promo-title" }, [
                        _c("h2", [_vm._v(_vm._s(_vm.currentPromo.title))]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { key: _vm.currentPromo.id, staticClass: "promo-body" },
                        [
                          _vm.currentPromo.image
                            ? _c("div", {
                                staticClass: "promo-image",
                                style: {
                                  backgroundImage: `url(${_vm.currentPromo.image})`,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.currentPromo.textAlign
                                  ? _vm.currentPromo.textAlign
                                  : "text-center",
                                "promo-content",
                              ],
                            },
                            [
                              _c("pre", [
                                _vm._v(_vm._s(_vm.currentPromo.body)),
                              ]),
                              _vm._v(" "),
                              _vm.videoUrl
                                ? _c(
                                    "div",
                                    { staticClass: "promo-video-container" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "promo-video" },
                                        [
                                          _c("iframe", {
                                            attrs: {
                                              width: "560",
                                              height: "315",
                                              src: _vm.videoUrl,
                                              title: "YouTube video player",
                                              frameborder: "0",
                                              allow:
                                                "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                                              allowfullscreen: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.currentPromo.callToAction
                            ? _c(
                                "div",
                                { staticClass: "promo-call-to-action" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant:
                                          _vm.currentPromo.callToAction
                                            .buttonVariant,
                                      },
                                      on: {
                                        click: _vm.currentPromo.callToAction.fn,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.currentPromo.callToAction.text
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "section-footer" }, [
              _c(
                "div",
                { staticClass: "pagination-btns" },
                _vm._l(_vm.promotions, function ({ id }, i) {
                  return _c("div", {
                    key: id,
                    class: [
                      "pagination-btn",
                      { active: i === _vm.currentPromoIndex },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.setPromoId(i)
                      },
                    },
                  })
                }),
                0
              ),
              _vm._v(" "),
              _vm.intervalId
                ? _c("div", {
                    key: _vm.currentPromoIndex,
                    staticClass: "progress-bar",
                    style: { animationDuration: _vm.animationDuration },
                  })
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }