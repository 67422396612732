import { ref, computed, reactive, watch } from 'vue';
import { useVuelidate, ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { countryInfo, billableCountries } from '@values/countries';

/**
 * ...
 */
export interface RegionInfo {
  name: string;
  code: string;
}

/** ... */
export type AddressType = 'billing' | 'shipping';

/**
 * ...
 */
export interface CountryInfo {
  name: string;
  code: string;
  regions: Record<string, RegionInfo> | null;
}

/** ... */
export type CountryOptionsOverrides = Record<string, CountryInfo>;

/** ... */
export type CountryFilter = (countryCode: string) => boolean;

/**
 * ...
 */
export interface AddressFormData {
  country: string | null;
  stateProvince: string | null;
  postalCode: string | null;
  // ...
  first?: string | null;
  last?: string | null;
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  city?: string | null;
}

/** ... */
const FORM_KEYS: (keyof AddressFormData)[] = [
  'country',
  'stateProvince',
  'postalCode',
  'first',
  'last',
  'address1',
  'address2',
  'address3',
  'city',
];

/**
 * ...
 */
export interface AddressFormProps<T extends AddressFormData> {
  /** ... */
  value: T;
  /** ... */
  type: AddressType;
  /** ... */
  countryOverrides?: CountryOptionsOverrides | null;
  /** ... */
  countryFilter?: CountryFilter | null;
}

function getAddressInfo(country: string) {
  return countryInfo[country]?.addressInfo ?? null;
}

function createFormData<T extends AddressFormData>(initialData: T) {
  const formData = { ...initialData };

  formData.country = formData.country ?? 'US';
  formData.stateProvince = formData.stateProvince ?? null;
  formData.postalCode = formData.postalCode ?? null;

  return formData;
}

/**
 * ...
 */
export function useFormState<T extends AddressFormData>(
  props: AddressFormProps<T>,
  onFormUpdated: (data: T) => void,
) {
  /** ... */
  const validateFields = ref(false);

  const formData = reactive(createFormData(props.value)) as T;

  /** ... */
  const fieldKeys = Object.keys(formData) as (keyof T)[];

  /** ... */
  const fieldInfo = computed(() =>
    !formData.country ? null : getAddressInfo(formData.country) ?? null,
  );

  /** ... */
  const fieldValidation = computed(() => {
    return validateFields.value || 'valid-only';
  });

  /** ... */
  const countryOptions = computed(() => {
    let items = [...billableCountries];

    if (props.countryFilter) {
      items = items.filter(({ code }) => props.countryFilter?.(code));
    }

    return items.map(({ name, code }) => ({
      text: name,
      value: code,
    })) as ZephyrWeb.OptionItem<string>[];
  });

  /** ... */
  const administrativeAreaOptions = ref<
    ZephyrWeb.OptionItem<string | null>[] | null
  >(null);

  /**
   * ...
   *
   * @param country ...
   * @returns ...
   */
  const getAdministrativeArea = (country: string | null) => {
    if (!country) return null;

    const aa = getAddressInfo(country)?.administrativeArea ?? null;

    if (!(props.countryOverrides && country in props.countryOverrides)) {
      return aa;
    }

    const label = aa?.label ?? 'Region';

    // If no overrides are found, but original option values exist, fall back to
    // those.
    const regions = props.countryOverrides[country]?.regions ?? aa?.options;

    if (!regions) return { label };

    const options = Object.values(regions);

    return { label, options };
  };

  /** ... */
  const administrativeArea = computed(() => {
    return getAdministrativeArea(formData.country);
  });

  const rules = computed(() => {
    const country = { required };

    // const stateProvince = fieldInfo.value?.administrativeArea ? { required } : {};
    const stateProvince = administrativeArea.value ? { required } : {};
    const postalCode = fieldInfo.value?.postalCode ? { required } : {};
    // const postalCode = formData.country === 'US' ? { required } : {};

    const args = {
      country,
      stateProvince,
      postalCode,
    } as ValidationArgs<T>;

    if ('first' in formData) {
      args.first = { required };
    }

    if ('last' in formData) {
      args.last = { required };
    }

    if ('address1' in formData) {
      args.address1 = fieldInfo.value?.thoroughfare ? { required } : {};
    }

    if ('address2' in formData) {
      args.address2 = {};
    }

    if ('address3' in formData) {
      args.address3 = {};
    }

    if ('city' in formData) {
      args.city = fieldInfo.value?.localityName ? { required } : {};
    }

    return args;
  });

  /** ... */
  const v = useVuelidate(rules, formData);

  /** ... */
  const invalid = computed(() => v.value.$invalid);

  const setCountryValue = (value: string | null) => {
    const isValid = optionsContainsValue(countryOptions.value, value);

    value = isValid ? value : null;

    formData.country = value ?? 'US';
  };

  const setStateProvince = (value: string | null) => {
    const aa = getAdministrativeArea(formData.country);

    let stateProvince: string | null = null;

    for (const { name, code } of aa?.options ?? []) {
      if (code === value || name === value) {
        stateProvince = code;
      }
    }

    formData.stateProvince = stateProvince;
  };

  const getUpdatedValues = (data: T) => {
    const values: Partial<T> = {};

    for (const key of fieldKeys) {
      if (data[key] !== formData[key]) {
        values[key] = data[key];
      }
    }

    return values;
  };

  watch(
    Object.keys(props.value).map((key) => () => props.value[key as keyof T]),
    () => {
      const data = props.value;

      const { country, stateProvince, ...values } = getUpdatedValues(data);

      if (country !== undefined) {
        setCountryValue(country);
      }

      if (stateProvince !== undefined) {
        setStateProvince(stateProvince);
      }

      for (const [key, value] of Object.entries(values)) {
        formData[key as keyof T] = value;
      }
    },
    { immediate: true },
  );

  watch(
    [formData, administrativeArea],
    (newValues, oldValues) => {
      let stateProvince = formData.stateProvince;

      if (newValues[1] !== oldValues[1]) {
        const value = newValues[1];

        stateProvince = null;
        let options: ZephyrWeb.OptionItem<string | null>[] | null = null;

        if (value?.options?.length) {
          options = [];

          for (const { name, code } of value.options) {
            if (
              code === formData.stateProvince ||
              name === formData.stateProvince
            ) {
              stateProvince = code;
            }

            options.push({ text: name, value: code });
          }
        }

        administrativeAreaOptions.value = options;
      }

      // logDataDiff(props.value, formData);

      const value: T = {
        ...props.value,
        country: formData.country,
        stateProvince: null,
        postalCode: null,
      };

      if ('first' in value) {
        Object.assign(value, { first: formData.first });
      }

      if ('last' in value) {
        Object.assign(value, { last: formData.last });
      }

      if ('address3' in value) {
        Object.assign(value, { address3: formData.address3 });
      }

      if (value.country === 'US') {
        value.postalCode = formData.postalCode;
      }

      if (fieldInfo.value?.thoroughfare) {
        value.address1 = formData.address1 ?? null;
      }

      if (fieldInfo.value?.premise) {
        value.address2 = formData.address2 ?? null;
      }

      if (fieldInfo.value?.localityName) {
        value.city = formData.city ?? null;
      }

      if (administrativeArea.value) {
        value.stateProvince = stateProvince;
      }

      if (fieldInfo.value?.postalCode) {
        value.postalCode = formData.postalCode ?? null;
      }

      if (formData.country) {
        const aao = formData?.country
          ? getAddressInfo(formData.country)?.administrativeAreaOverride
          : null;

        if (aao) {
          formData.stateProvince = aao;
          value.stateProvince = aao;
        }
      }

      onFormUpdated(value);
    },
    { immediate: true },
  );

  /**
   * Run a validation check on the form.
   *
   * @returns `true` if the form is valid, otherwise `false`.
   */
  const validate = () => {
    validateFields.value = true;

    v.value.$touch();

    return !invalid.value;
  };

  return {
    formData,
    v,
    fieldInfo,
    fieldValidation,
    invalid,
    countryOptions,
    administrativeArea,
    administrativeAreaOptions,
    getAdministrativeArea,
    validate,
  };
}

//#region Helper Functions

/**
 * ...
 *
 * @param options ...
 * @param value ...
 */
function optionsContainsValue<T>(options: ZephyrWeb.OptionItem<T>[], value: T) {
  return !!options.find((option) => option.value === value);
}

/**
 * ...
 */
function doesDataDiff(a: AddressFormData, b: AddressFormData) {
  if (a !== b) return true;

  for (const key of FORM_KEYS) {
    if (a[key] !== b[key]) return true;
  }

  return false;
}

/**
 * ...
 */
function logDataDiff(
  a: AddressFormData | undefined,
  b: AddressFormData | undefined,
) {
  const refChanged = a !== b;

  const propDiffs: {
    key: keyof AddressFormData;
    valueA: Nullable<string>;
    valueB: Nullable<string>;
  }[] = [];

  for (const key of FORM_KEYS) {
    const valueA = a?.[key];
    const valueB = b?.[key];

    if (valueA !== valueB) {
      propDiffs.push({ key, valueA, valueB });
    }
  }

  const hasDiff = refChanged || !!propDiffs.length;

  const logs: string[] = [
    '[form-state] logDataDiff results:',
    `• data does difference: ${hasDiff ? 'YES' : 'NO'}`,
  ];

  if (hasDiff) {
    logs.push(
      `• reference object changed: ${refChanged ? 'YES' : 'NO'}`,
      `• property differences:\n\n` +
        propDiffs
          .map(
            ({ key, valueA, valueB }) =>
              `   - ${key} -> a: "${valueA}", b: "${valueB}"`,
          )
          .join('\n'),
    );
  }

  // eslint-disable-next-line no-console
  console.log(logs.join('\n\n') + '\n\n');
}

//#endregion Helper Functions
