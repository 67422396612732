import { api } from '@api';
import { Organization } from '@models';
import { auth } from '@auth';

import { openModal } from '../open-modal';

import { SiteLicenseUpdateOption } from './SiteLicensePrompt.vue';

/**
 * Add Seats modal configuration options.
 */
export interface AddSeatModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Add Institution (Organization) Seats modal.
 *
 * @returns Modal promise.
 */
export async function addSeats(options: AddSeatModalOptions) {
  const organization = await api.organizations.get(options);

  await openModal.safe({
    title: 'ADD INSTITUTION SEATS',
    props: { organization },
    component: () => import('./AddOrganizationSeats.vue'),
  });
}

/**
 * Open Create Organization modal.
 *
 * @returns Modal promise.
 */
export async function create() {
  await openModal.safe({
    title: 'CREATE INSTITUTION',
    // component: () => import('./CreateOrganization/index.vue'),
    component: () => import('./CreateOrganization.vue'),
  });
}

/**
 * Credit Organization modal configuration options.
 */
export interface CreditOrganizationModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Credit Organization modal.
 *
 * @returns Modal promise.
 */
export async function credit(options: CreditOrganizationModalOptions) {
  const organization = await api.organizations.get({
    organizationId: options.organizationId,
    admin: auth.isActiveRole(9),
  });

  await openModal.safe({
    title: 'CREDIT INSTITUTION',
    props: { organization },
    component: () => import('./CreditOrganization.vue'),
  });
}

/**
 * Edit Organization modal configuration options.
 */
export interface EditOrganizationModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Edit Organization modal.
 *
 * @returns Modal promise.
 */
export async function edit(options: EditOrganizationModalOptions) {
  const organization = await api.organizations.get({
    organizationId: options.organizationId,
    admin: auth.isActiveRole(9),
  });

  await openModal.safe({
    title: 'EDIT INSTITUTION',
    props: { organization },
    component: () => import('./EditOrganization.vue'),
  });
}

/**
 * Edit Organization modal configuration options.
 */
export interface GiftOrganizationSeatsModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Gift Institution Seats modal.
 *
 * @returns Modal promise.
 */
export async function giftSeats(options: GiftOrganizationSeatsModalOptions) {
  const { organizationId } = options;

  const organization = await api.organizations.get({
    organizationId,
    admin: auth.isActiveRole(9),
  });

  // Reseller deprecated until redesign is needed
  // if (options.resellerId) {
  //   props.reseller = await api.reseller.get(options);
  // }

  await openModal.safe({
    // title: options.resellerId ? 'ADD INSTITUTION SEATS' : 'GIFT INSTITUTION SEATS',
    title: 'GIFT INSTITUTION SEATS',
    props: { organization },
    component: () => import('./GiftOrganizationSeats.vue'),
  });
}

/**
 * Add (Organization) Licenses modal configuration options.
 */
export interface AddLicensesModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Add (Organization) Licenses modal.
 *
 * @returns Modal promise.
 */
export async function addLicenses(options: AddLicensesModalOptions) {
  const { organizationId } = options;

  const { allowAddLicense } = await api.organizations.get({
    organizationId,
  });

  const title = allowAddLicense ? 'ADD LICENSES' : 'REQUEST LICENSES';
  const actionType = allowAddLicense ? 'add' : 'request';

  await openModal.safe<undefined, import('./AddLicenses.vue').Props>({
    title,
    props: { organizationId, actionType },
    component: () => import('./AddLicenses.vue'),
  });
}

/**
 * Add/Remove (Organization) Licenses modal configuration options.
 */
export interface AddRemoveLicensesModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Add/Remove (Organization) Licenses modal.
 *
 * @returns Modal promise.
 */
export async function addRemoveLicenses(
  options: AddRemoveLicensesModalOptions,
) {
  await openModal.safe({
    title: 'ADD/REMOVE LICENSES',
    props: options,
    component: () => import('./AddRemoveLicenses.vue'),
  });
}

/**
 * Manage Organization Licenses modal configuration options.
 */
export interface ManageOrganizationLicensesModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Manage Organization Licenses modal.
 *
 * @returns Modal promise.
 */
export async function manageLicenses(
  options: ManageOrganizationLicensesModalOptions,
) {
  const { organizationId } = options;

  const organization = await api.organizations.get({
    organizationId,
    admin: auth.isActiveRole(9),
  });

  await openModal.safe({
    title: 'MANAGE INSTITUTION LICENSES',
    size: 'xl',
    props: { organization },
    component: () => import('./ManageLicenses.vue'),
  });
}

/**
 * Remove Institution Seats modal configuration options.
 */
export interface RemoveOrganizationSeatsModalOptions {
  organizationId: Organization['id'];
  seats?: number;
  creditInstitution?: boolean;
}

/**
 * Open Remove Institution Seats modal.
 *
 * @returns Modal promise.
 */
export async function removeSeats(
  options: RemoveOrganizationSeatsModalOptions,
) {
  const { organizationId, ...otherProps } = options;

  const organization = await api.organizations.get({
    organizationId,
    admin: auth.isActiveRole(9),
  });

  const modalOptions = {
    title: 'REMOVE INSTITUTION SEATS',
    props: { organization, ...otherProps },
    component: () => import('./RemoveOrganizationSeats.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * Set Institution Logo modal configuration options.
 */
interface SetOrganizationLogoModalOptions {
  organizationId: Organization['id'];
  logo: Organization['logo'];
}

/**
 * Open Set Institution Logo modal.
 *
 * @param options ...
 * @returns Modal promise that resolves to a result data object containing
 * logo
 */
export async function setLogo(options: SetOrganizationLogoModalOptions) {
  const modalOptions = {
    title: 'SET INSTITUTION LOGO',
    props: { ...options },
    component: () => import('./SetOrganizationLogo.vue'),
  };

  let logo: string | null = null;

  try {
    logo = (await openModal<string>(modalOptions)) ?? null;
  } catch {
    return null;
  }

  return { logo };
}

interface UpdateUserLicensesOptions {
  organizationId: Organization['id'];
}

/**
 * ...
 *
 * @param options ...
 * @returns ...
 */
export async function updateUserLicenses(options: UpdateUserLicensesOptions) {
  await openModal.safe({
    title: 'UPDATE USER LICENSES',
    props: { organizationId: options.organizationId },
    component: () => import('./UpdateUserLicenses.vue'),
  });
}

/**
 * Change License modal configuration options.
 */
interface ChangeLicenseModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Change License modal.
 */
export async function changeLicense(options: ChangeLicenseModalOptions) {
  await openModal.safe({
    title: 'CHANGE LICENSE',
    props: { organizationId: options.organizationId },
    component: () => import('./ChangeLicense.vue'),
  });
}

/**
 * Manage Organization Product modal configuration options.
 */
interface ManageOrganizationProductOptions {
  organizationId: Organization['id'];
}

/**
 * ...
 *
 * @param options ...
 * @returns ...
 */
export async function manageProducts(
  options: ManageOrganizationProductOptions,
) {
  await openModal.safe({
    title: 'MANAGE ORGANIZATION PRODUCTS',
    props: { organizationId: options.organizationId },
    component: () => import('./ManageOrganizationProducts.vue'),
  });
}

/**
 * Set Next Invoice Date modal configuration options.
 */
interface SetNextInvoiceDateModalOptions {
  organizationId: Organization['id'];
}

/**
 * ...
 *
 * @param options ...
 * @returns ...
 */
export async function setNextInvoiceDate(
  options: SetNextInvoiceDateModalOptions,
) {
  await openModal.safe({
    title: 'SET NEXT INVOICE DATE',
    props: { organizationId: options.organizationId },
    component: () => import('./SetNextInvoiceDate.vue'),
  });
}

/**
 * Open Site (Organization) License Prompt modal.
 *
 * @returns Modal promise.
 */
export async function siteLicensePrompt() {
  const modalOptions = {
    title: 'SITE LICENSE PROMPT',
    component: () => import('./SiteLicensePrompt.vue'),
  };

  let selection: SiteLicenseUpdateOption | null = null;

  try {
    selection =
      (await openModal<SiteLicenseUpdateOption>(modalOptions)) ?? null;
  } catch {
    selection = null;
  }

  return selection;
}

/**
 * Open Complete Site License Setup modal configuration options.
 */
export interface CompleteSiteLicenseSetupModalOptions {
  organizationId: Organization['id'];
}

/**
 * Open Complete Site License Setup modal.
 *
 * @returns Modal promise.
 */
export async function completeSiteLicenseSetup(
  options: CompleteSiteLicenseSetupModalOptions,
) {
  const organization = await api.organizations.get(options);

  await openModal.safe({
    props: { organization },
    component: () => import('./CompleteSiteLicenseSetup.vue'),
  });
}
