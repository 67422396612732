
import { Vue, Component, Prop } from '@vue';

import { faEye } from '@icons/duotone/faEye';

@Component
export default class ProductCard extends Vue {
  @Prop(String) readonly id!: string;
  @Prop(String) readonly name!: string;
  @Prop(Array) readonly images!: string[];
  @Prop(Number) readonly stock!: number;

  readonly icons = { faEye };

  showEditStockPopup = false;
  stockAmount = 0;

  created() {
    this.stockAmount = this.stock;
  }

  /**
   * ...
   */
  viewProduct() {
    void this.$modals.product.edit({ productId: this.id });
  }

  /**
   * ...
   */
  async updateStock() {
    let error: unknown = null;

    try {
      await this.$api.products.update({ id: this.id, stock: this.stockAmount });
    } catch (err) {
      error = err;
    }

    if (error) {
      return this.$alert.error(error);
    }

    this.$alert.success('Updated product stock.');

    this.showEditStockPopup = false;
  }
}
