var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [_c("h6", { staticClass: "mb-0" }, [_vm._v("Recent Activity")])]
      ),
      _vm._v(" "),
      _c(
        "b-card-body",
        { staticClass: "p-3" },
        [
          _setup.loading
            ? _c("CardLoadPlaceholder", { attrs: { cards: 1, lines: 2 } })
            : _setup.items.length
            ? _c(
                "div",
                { staticClass: "recent-activity-items-container" },
                _vm._l(_setup.items, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "recent-activity-item" },
                    [
                      _c("div", { class: ["status", item.type] }, [
                        _vm._v(_vm._s(item.type)),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      item.type === "certification"
                        ? _c(
                            "span",
                            [
                              _c(_setup.LabelCertificationStatus, {
                                attrs: { status: item.status },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: item.fn },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.buttonText) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("div", [_c("p", [_c("i", [_vm._v("No Activity Detected")])])]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }