var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Transition",
    { attrs: { appear: "" }, on: { "after-enter": _setup.onAfterEnter } },
    [
      _c(
        "div",
        {
          class: [
            "pack-option-card",
            { entering: _setup.entering, selected: _setup.selected },
          ],
          style: { transitionDelay: `${0.03 * _vm.index}s` },
        },
        [
          _c(
            "div",
            { staticClass: "card-content" },
            [
              _vm.pack.badge
                ? _c("div", { staticClass: "pack-badge" }, [
                    _c("span", [_vm._v(_vm._s(_vm.pack.badge))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h3", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.pack.title)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.pack.description)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pack-price" },
                [
                  _c("DisplayPrice", {
                    attrs: { size: "4rem", price: _vm.pack.price },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.pack.oneTimePurchase
                ? _c("p", { staticClass: "disclaimer" }, [
                    _c("i", [_vm._v("One-time fee")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "pack-feature-list" },
                _vm._l(_vm.pack.features, function (feature) {
                  return _c(
                    "li",
                    {
                      key: feature.key,
                      class: { included: feature.isIncluded },
                    },
                    [
                      _c("Icon", {
                        attrs: {
                          icon: feature.isIncluded
                            ? _setup.faCheckCircle
                            : _setup.faTimes,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(feature.name))]),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "option-select-btn",
                  attrs: {
                    variant: "primary",
                    disabled: _setup.selected,
                    block: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("selected", _vm.pack.key)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_setup.selected ? "Selected" : "Select") +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }