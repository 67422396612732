
import { defineComponent } from 'vue';
import { BFormInput } from 'bootstrap-vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormInput: ComponentWithProps<Props>;
  }
}

/**
 * `FormInput` component properties.
 */
export interface Props extends BFormInput.Props {
  label: string;
}

export default defineComponent({
  name: 'FormInput',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
