import { Module, VuexModule } from '@vuex/decorators';

import { NewsPost } from '@interfaces';
import { Root } from '@store';

/** List of static news posts. */
const POSTS: NewsPost[] = [
  {
    link: 'https://youtu.be/bN0I-EzAo0Q?si=Wmlp0rRY__QsQ2c7',
    title:
      'Zephyr: Pro Training Simulator for Drones by Roswell Flight Test Crew',
    date: 'May 7, 2023',
  },
  {
    link: 'https://www.youtube.com/watch?v=AhoSRjgCxCU',
    title: 'Boys and Girls Club Drone Class',
    date: 'August 17, 2023',
  },
  {
    link: 'https://www.einpresswire.com/article/648243607/vipc-supports-virginia-smart-community-stem-camp-to-help-students-fly-drones-and-explore-emerging-technology-careers',
    title:
      'VIPC Supports Virginia Smart Community STEM Camp To Help Students Fly Drones And Explore Emerging Technology Careers',
    date: 'August 4, 2023',
  },
  {
    link: 'https://www.skydio.com/blog/skydio-for-all-virginia-smart-community-stem-camp',
    title: "Learning to fly at Virginia's SMART Community STEM Camp",
    date: 'July 31, 2023',
  },
  {
    link: 'https://www.thedronegirl.com/2023/05/05/auvsi-xponential-2023/',
    title: 'XPONENTIAL 2023 IS NEXT WEEK: 11 THINGS NOT TO MISS',
    date: 'May 2, 2023',
  },
  {
    link: 'https://www.thedronegirl.com/2023/05/03/zephyr-drone-flight-simulator/',
    title:
      'ZEPHYR: THE ULTIMATE DRONE FLIGHT SIMULATOR DESIGNED FOR PROFESSIONAL TRAINING',
    date: 'May 3, 2023',
  },
  {
    link: 'https://www.suasnews.com/2021/08/nist-open-lane-test-coming-to-the-zephyr-drone-simulator/',
    title: 'NIST Open Lane Test coming to the Zephyr Drone Simulator',
    date: 'March 8, 2022',
  },
  {
    link: 'https://letsflywisely.com/best-drone-flight-simulator/',
    title: 'Best Drone Flight Simulator',
    date: 'February 17, 2022',
  },
  {
    link: 'https://www.droneblog.com/drone-flight-simulator/',
    title: '9 Best Drone Flight Simulators for 2022 (FPV and Commercial)',
    date: '',
  },
  {
    link: 'https://www.uavadviser.com/best-drone-flight-simulators/',
    title: '10 Best Drone Flight Simulators',
    date: '',
  },
  {
    link: 'https://blog.flykit.app/best-uas-simulators-drone-training-guide-2021/',
    title: 'The Best UAS Simulators: Complete Drone Training Guide 2021',
    date: '2021',
  },
  {
    link: 'https://circuitdigest.com/article/drone-simulation-software-what-are-they-and-which-one-you-shoud-choose',
    title:
      'Drone Simulation Softwares - What are they and which one should you use?',
    date: 'December 11, 2021',
  },
  {
    link: 'http://hamptonroadsmessenger.com/posts/tidewater-chapter-of-the-tuskegee-airmen-inc-holds-first-annual-youth-summer-drone-program/',
    title:
      'Tidewater Chapter of the Tuskegee Airmen, Inc. Holds First Annual Youth Summer Drone Program',
    date: 'August 19, 2020',
  },
  {
    link: 'https://www.suasnews.com/2020/02/new-drone-pilot-training-program-builds-skills-for-the-renewable-energy-industry/',
    title:
      'New Drone Pilot Training Program Builds Skills for the Renewable Energy Industry',
    date: 'February 29, 2020',
  },
  {
    link: 'https://www.einpresswire.com/article/510821134/new-drone-pilot-training-program-builds-skills-for-the-renewable-energy-industry?fbclid=IwAR3OdDYXMo2_G3H7uNW8S_oiv13Jv1YXzZ2Ybb5DoPPNK5Lsja54v4W7VGk',
    title:
      'New Drone Pilot Training Program Builds Skills for the Renewable Energy Industry',
    date: 'February 28, 2020',
  },
  {
    link: 'https://www.unmannedsafetyinstitute.org/news-media/unmanned-safety-institute-partners-with-little-arms-studios-to-bring-drone-simulation-training-to-learners',
    title:
      'Unmanned Safety Institute Partners with Little Arms Studios to Bring Drone Simulation Training to Learners',
    date: 'August 29, 2019',
  },
  {
    link: 'https://www.roboticsbusinessreview.com/unmanned/drone-pilot-training-programs-simulation-software/',
    title: 'Drone Pilot Training Programs Turn to Simulation Software',
    date: 'July 09, 2018',
  },
  {
    link: 'https://www.suasnews.com/2018/05/unmanned-aircraft-programs-at-colleges-and-universities-across-the-us-rely-on-zephyr-drone-simulator-for-flight-training/',
    title:
      'Unmanned Aircraft Programs at Colleges and Universities Across the US Rely on Zephyr Drone Simulator for Flight Training',
    date: 'May 15, 2018',
  },
  {
    link: 'https://www.suasnews.com/2018/04/little-arms-studios-partners-with-skip-fredricks-to-bring-cinematic-training-modules-to-its-zephyr-drone-simulator/',
    title:
      'Little Arms Studios Partners with Skip Fredricks to Bring Cinematic Training Modules to its Zephyr Drone Simulator',
    date: 'April 20, 2018',
  },
  {
    link: 'http://www.policemag.com/channel/technology/news/2018/03/30/zephyr-drone-simulator-trains-first-responder-drone-pilots.aspx',
    title: 'Zephyr Drone Simulator Trains First Responder Drone Pilots',
    date: 'March 30, 2018',
  },
  {
    link: 'http://uasweekly.com/2017/10/26/aviation-maintenance-school-showcase-drone-training-program-local-high-schoolers/?utm_source=newsletter&utm_medium=email&utm_campaign=uasweekly_newsletter_2017_10_26&utm_term=2017-11-06',
    title:
      'Aviation Maintenance School to Showcase Drone Training Program for Local High Schoolers',
    date: 'October 26, 2017',
  },
  {
    link: 'https://uavcoach.com/zephyr-flight-proficiency/',
    title:
      'What Does It Mean to Be an Expert Drone Pilot? Flight Proficiency and the Zephyr Drone Simulator',
    date: 'September 5, 2017',
  },
  {
    link: 'http://www.rotordrone-digital.com/rotordrone/sep_oct_2017?pg=24#pg24',
    title: 'Zephyr Drone Simulator - Learn to virtually master your drone',
    date: 'September 2017',
  },
  {
    link: 'https://www.suasnews.com/2017/08/zephyr-drone-simulator-streamline-skyop-llc-drone-pilot-training/',
    title:
      'Zephyr Drone Simulator to Streamline SkyOp, LLC Drone Pilot Training',
    date: 'August 28, 2017',
  },
  {
    link: 'https://www.suasnews.com/2017/07/little-arms-studios-launches-new-zephyr-drone-simulator-bundles-expands-transmitter-compatibility/',
    title:
      'Little Arms Studios Launches New Zephyr Drone Simulator Bundles & Expands Transmitter Compatibility',
    date: 'July 24, 2017',
  },
  {
    link: 'https://www.suasnews.com/2017/05/fpv-australia-adopts-zephyr-drone-simulator-rpas-certification-training/',
    title:
      'FPV Australia Adopts the Zephyr Drone Simulator for Its RPAS Certification Training - sUAS News',
    date: 'May 23, 2017',
  },
  {
    link: 'https://bluetoad.com/publication/?i=418968#%7B%22issue_id%22:418968,%22page%22:14%7D',
    title: 'Here Come the Drone Sims –  Civil Aviation Training Magazine',
    date: 'March 2017',
  },
  {
    link: 'https://www.suasnews.com/2016/11/little-arms-studios-team-behind-zephyr-drone-simulator-avisight-announce-strategic-partnership/',
    title:
      'Little Arms Studios, the Team behind Zephyr Drone Simulator, and Avisight Announce Strategic Partnership',
    date: 'Nov. 23, 2016',
  },
  {
    link: 'http://www.expouav.com/news/latest/announcing-trying-enterprise-grade-drone-pilot-training-simulators/',
    title:
      'Announcing and Trying Our an Enterprise-grade Drone Pilot Training Simulator – Commercial UAV News ',
    date: 'Sept. 16, 2016',
  },
  {
    link: 'https://youtu.be/m4MqFw74I7c',
    title: 'Zephyr Launch Trailer',
    date: 'Sept. 7, 2016',
  },
  {
    link: 'http://www.auvsi.org/little-arms-studios-announces-drone-training-simulator',
    title:
      'Little Arms Studios Announces Drone Training Simulator – AUVSI News',
    date: 'Sept. 2, 2016',
  },
  {
    link: 'http://uasweekly.com/2016/09/01/little-arms-studios-unveil-zephyr-drone-training-simulator-interdrone-2016/',
    title:
      'sUAS Weekly Article - Little Arms Studios Unveil Zephyr at InterDrone 2016',
    date: 'Sept. 1, 2016',
  },
  {
    link: 'http://www.drones-mx.com/dmx/zephyr-el-simulador-de-vuelo-disenado-para-empresas/',
    title:
      'Zephyr, the Flight Simulator Designed for Companies (this article is in Spanish) - DronesMX',
    date: 'Aug. 24, 2016',
  },
  {
    link: 'http://www.suasnews.com/2016/08/little-arms-studios-launches-drone-simulator-zephyr-beta/',
    title:
      'sUAS Weekly Article - Little Arms Studios Launches Drone Simulator Zephyr Beta',
    date: 'Aug. 16, 2016',
  },
];

@Module({ namespaced: true })
export class News
  extends VuexModule<News.State, Root.State>
  implements News.State
{
  posts = [...POSTS];
}

export namespace News {
  /**
   * ...
   */
  export interface State {
    posts: NewsPost[];
  }
}

export default News;
