var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.props.tag,
    { tag: "component", staticClass: "display-text-number" },
    [_vm._v(_vm._s(_setup.displayValue))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }