/** `About` view component. */
export const About = () => {
  return import('@views/About.vue');
};

// /** `AppStream` view component. */
// export const AppStream = () => {
//   return import('@views/AppStreamView');
// };

/** `APSA` view component. */
export const APSA = () => {
  return import('@views/APSA.vue');
};

/** `LoginCallback` view component. */
export const LoginCallback = () => {
  return import('@client/views/LoginCallbackView.vue');
};

/** `BridgeTutorial` view component. */
export const BridgeTutorial = () => {
  return import('@views/BridgeTutorial.vue');
};

/** `Cart` view component. */
export const Cart = () => {
  return import('@views/CartView.vue');
};

/** `Certifications` view component. */
export const Certifications = () => {
  return import('@client/views/Certifications');
};

/** `Checkout` view component. */
export const Checkout = () => {
  return import('@views/Checkout');
};

/** `CheckoutInformation` view component. */
export const CheckoutInformation = () => {
  return import('@views/CheckoutInformation');
};

/** `CheckoutPayment` view component. */
export const CheckoutPayment = () => {
  return import('@views/CheckoutPayment');
};

/** `CheckoutShipping` view component. */
export const CheckoutShipping = () => {
  return import('@views/CheckoutShipping');
};

/** `CheckoutSummary` view component. */
export const CheckoutSummary = () => {
  return import('@views/CheckoutSummary');
};

/** `Cookies` view component. */
export const Cookies = () => {
  return import('@views/Cookies.vue');
};
/** `Country Availability` view component. */
export const CountryAvailability = () => {
  return import('@views/CountryAvailability.vue');
};

/** `Dashboard` view component. */
export const Dashboard = () => {
  return import('@views/Dashboard.vue');
};

/** `DashboardCertifications` view component. */
export const DashboardCertifications = () => {
  return import('@views/DashboardCertificationsView.vue');
};

/** `DashboardHome` view component. */
export const DashboardHome = () => {
  return import('@views/DashboardHome/index.vue');
};

/** `DashboardManage` view component. */
export const DashboardManage = () => {
  return import('@client/views/DashboardManageView.vue');
};
/** `DashboardManage` view component. */
export const DashboardDeveloper = () => {
  return import('@client/views/DashboardDeveloper.vue');
};

/** `DashboardOrders` view component. */
export const DashboardOrders = () => {
  return import('@views/DashboardOrdersView.vue');
};

/** `DashboardReport` view component. */
export const DashboardReport = () => {
  return import('@views/DashboardReport.vue');
};

/** `DashboardReports` view component. */
export const DashboardReports = () => {
  return import('@views/DashboardReportsView');
};

/** `DashboardUnityModules` view component. */
export const DashboardUnityModules = () => {
  return import('@views/DashboardUnityModules.vue');
};

/** `DashboardCourse` view component. */
export const DashboardCourse = () => {
  return import('@views/DashboardCourse.vue');
};

/** `DashboardCourseAssignment` view component. */
export const DashboardCourseAssignment = () => {
  return import('@views/DashboardCourseAssignment.vue');
};

/** `DashboardCourseStudent` view component. */
export const DashboardCourseStudent = () => {
  return import('@views/DashboardCourseStudent.vue');
};

/** `DashboardMyContent` view component. */
export const DashboardMyContent = () => {
  return import('@views/DashboardMyContent.vue');
};

/** `DashboardMyCertifications` view component. */
export const DashboardMyCertifications = () => {
  return import('@views/DashboardMyCertifications.vue');
};

/** `DroneResponders` view component. */
export const DroneResponders = () => {
  return import('@views/DroneResponders.vue');
};

/** `Enterprise` view component. */
export const Enterprise = () => {
  return import('@views/Enterprise');
};

/** `Error404` view component. */
export const Error404 = () => {
  return import('@client/views/Error404View.vue');
};

/** `FAQ` view component. */
export const FAQ = () => {
  return import('@views/FaqView.vue');
};

/** `FaqEditor` view component. */
export const FaqEditor = () => {
  return import('@views/FaqEditorView.vue');
};

/** `Features` view component. */
export const Features = () => {
  return import('@views/Features/index.vue');
};

/** `Individuals` view component. */
export const Individuals = () => {
  return import('@views/Individuals');
};

/** `Invoice` view component. */
export const Invoice = () => {
  return import('@views/InvoiceView.vue');
};

/** `LoginLauncher` view component. */
export const LoginLauncher = () => {
  return import('@views/LoginLauncherView.vue');
};

/** `Logout` view component. */
export const Logout = () => {
  return import('@views/LogoutView.vue');
};

/** `Main` view component. */
export const Main = () => {
  return import('@views/Main');
};

/** `News` view component. */
export const News = () => {
  return import('@views/News/index.vue');
};

/** `NistTutorial` view component. */
export const NistTutorial = () => {
  return import('@views/NistTutorial.vue');
};

/** `Onboarding` view component. */
export const Onboarding = () => {
  return import('@views/OnboardingView.vue');
};

/** `OrderStatus` view component. */
export const OrderStatus = () => {
  return import('@views/OrderStatusView.vue');
};

/** `Pricing` view component. */
export const Pricing = () => {
  return import('@views/Pricing');
};

/** `PrivacyPolicy` view component. */
export const PrivacyPolicy = () => {
  return import('@views/PrivacyStatement.vue');
};

/** `PublicProfile` view component. */
export const PublicProfile = () => {
  return import('@views/PublicProfile');
};

/** `Returns` view component. */
export const Returns = () => {
  return import('@views/Returns.vue');
};

/** `Restricted` view component. */
export const Restricted = () => {
  return import('@views/Restricted.vue');
};

/** `SignOut` view component. */
export const SignOut = () => {
  return import('@views/SignOut.vue');
};

/** `ShippingPolicy` view component. */
export const ShippingPolicy = () => {
  return import('@views/ShippingPolicy.vue');
};

/** `Shop` view component. */
export const Shop = () => {
  return import('@client/views/Shop');
};

/** `SimulatorTermsAndConditions` view component. */
export const SimulatorTermsAndConditions = () => {
  return import('@views/SimulatorTermsAndConditions.vue');
};

/** `System Requirements` view component. */
export const SupportedControllers = () => {
  return import('@views/SupportedControllers.vue');
};

/** `System Requirements` view component. */
export const SystemRequirements = () => {
  return import('@views/SystemRequirements.vue');
};

/** `TermsAndConditions` view component. */
export const TermsAndConditions = () => {
  return import('@views/TermsAndConditions.vue');
};

/** `Tutorials` view component. */
export const Tutorials = () => {
  return import('@views/Tutorials.vue');
};

/** `Verification` view component. */
export const Verification = () => {
  return import('@views/Verification.vue');
};

/** `Payment Processor Change 2024` view component. */
export const PaymentProcessorChange2024 = () => {
  return import('@views/PaymentProcessorChange2024.vue');
};
