import { Vue, Component, Prop } from '@vue';

@Component
export default class ParallaxWrapper extends Vue {
  @Prop({ type: Number, default: 0.1 }) readonly factor!: number;
  // @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  private offset = 0;

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

  /** ... */
  get transform() {
    if (this.disabled) return '';

    const finalOffset = this.factor * this.offset;

    return `translateY(${finalOffset}px)`;
  }

  /** ... */
  get disabled() {
    return this.$store.state.displayMode === 'mobile';
  }

  /**
   * ...
   */
  private onScroll() {
    if (this.disabled || !this.$el) return;

    const winCenter = 0.5 * window.outerHeight;

    // ...
    const { top, height } = this.$el.getBoundingClientRect();

    // ...
    const center = top + height;

    // ...
    this.offset = Math.round(
      Math.min(winCenter, Math.max(-winCenter, center - winCenter)),
    );
  }
}
