import { server, request } from '@api/request';
import * as models from '@models';
import { isObject } from '@tools/type-guards';

/**
 * List all existing invoices.
 *
 * @returns A list of invoices.
 */
export async function list() {
  return await server.list('v2/admin/invoices', processInvoiceData);
}

/**
 * ...
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @returns A list of invoices.
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/invoices`,
    processInvoiceData,
  );
}

/**
 * ...
 */
export interface GetOptions {
  invoiceId: models.Invoice['id'];
}

/**
 * Get a specified invoice.
 *
 * @param options Request options bag.
 * @returns The specified invoice.
 */
export async function get(options: GetOptions) {
  return await server.get(
    `v2/invoices/${options.invoiceId}`,
    processInvoiceData,
  );
}

/**
 * ...
 */
export interface RetrievePdfOptions {
  invoiceId: models.Invoice['id'];
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @returns ...
 */
export async function retrievePdf(options: RetrievePdfOptions) {
  return await request.post<string>(
    `${process.env.API_ORIGIN}/v2/invoices/pdf`,
    options,
    { responseType: 'arraybuffer' },
  );
}

/**
 * ...
 */
export interface CreateOptions {
  organizationId: string;
  lineItems: models.Invoice.LineItem[];
  dueDate: string;
  salesTax: number;
  qbInvoiceUrl?: string;
  qbInvoiceId?: string;
  pdf?: string;
}

/**
 * Create an invoice.
 *
 * @param options Request options bag.
 * @returns The specified invoice.
 */
export async function create(options: CreateOptions) {
  return await server.post('v2/admin/invoices', options, processInvoiceData);
}

/**
 * ...
 */
export interface UpdateOptions {
  invoiceId: models.Invoice['id'];
  status?: models.Invoice.Status;
  lineItems?: models.Invoice.LineItem[];
  dueDate?: string;
  qbInvoiceUrl?: string;
  qbInvoiceId?: string;
  pdf?: string;
  salesTax?: number;
}

/**
 * Update a specified invoice.
 *
 * @param options Request options bag.
 * @returns The specified invoice.
 */
export async function update(options: UpdateOptions) {
  return await server.post(
    `v2/admin/invoices/${options.invoiceId}`,
    options,
    processInvoiceData,
  );
}

/**
 * ...
 */
export interface PayOptions {
  invoiceId: models.Invoice['id'];
  paymentMethodId?: models.PaymentMethod['id'];
  paymentMethodNonce?: string;
}

/**
 * Pay a specified invoice.
 *
 * @param options Request options bag.
 * @returns The specified invoice.
 */
export async function pay(options: PayOptions) {
  const { invoiceId, ...data } = options;

  await server.post(`v2/invoices/${invoiceId}/pay`, data);
}

/**
 * ...
 */
export interface CreditOptions {
  invoiceId: models.Invoice['id'];
  amount: number;
}

/**
 * Credit a specified invoice.
 *
 * @param options Request options bag.
 * @returns The specified invoice.
 */
export async function credit(options: CreditOptions) {
  const { invoiceId, ...data } = options;
  if (typeof data.amount === 'string') data.amount = parseFloat(data.amount);

  await server.post(`v2/admin/invoices/${invoiceId}/credit`, data);
}

/**
 * ...
 */
export interface DebitOptions {
  invoiceId: models.Invoice['id'];
  amount: number;
}

/**
 * Debit a specified invoice.
 *
 * @param options Request options bag.
 * @returns The specified invoice.
 */
export async function debit(options: DebitOptions) {
  const { invoiceId, ...data } = options;
  if (typeof data.amount === 'string') data.amount = parseFloat(data.amount);

  return await server.post(`v2/admin/invoices/${invoiceId}/debit`, data);
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidInvoiceItem(value: unknown): value is models.Invoice {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processInvoiceData(data: unknown) {
  // if (!isInvoice(data)) {
  if (!isValidInvoiceItem(data)) {
    throw new Error('Invalid invoice data item.');
  }

  return { ...data } as models.Invoice;
}

//#endregion Helper Functions
