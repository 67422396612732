import { ref, onBeforeUnmount, type Ref } from 'vue';
import { Vue, Component } from '@vue';
import VueRouter from 'vue-router';
import type { RouteConfig, RouterView, RouterLink } from 'vue-router';

import { api } from '@api';
import { auth } from '@auth';
import { alert } from '@services/alert';
import { isArray } from '@tools/type-guards';
import * as views from '@views';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    RouterView: typeof RouterView;
    RouterLink: typeof RouterLink;
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * The title that should be assigned to `document.title` upon successful
     * visitation to the route. It will be applied with the format
     * `Route Title - Zephyr`.
     */
    title?: string;
    /**
     * Describes the requirement (if any) the user must meet in order to be
     * allowed access to the route.
     */
    accessRequirement?: auth.AccessRequirement;
  }
}

Vue.use(VueRouter);

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'main',
    component: views.Main,
    props: ({ query: { referralId, referral } }) => ({
      referralId: isArray(referralId) ? null : referralId,
      referral: isArray(referral) ? null : referral,
    }),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'About',
    },
    component: views.About,
  },
  // {
  //   path: '/stream',
  //   name: 'appStream',
  //   meta: {
  //     title: 'Streaming with AppStream',
  //   },
  //   component: views.AppStream,
  // },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      title: 'Cart',
    },
    component: views.Cart,
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: {
      title: 'Checkout',
    },
    component: views.Checkout,
    redirect: {
      name: 'paymentProcessorChange2024',
    },
    children: [
      {
        path: 'information',
        name: 'checkoutInformation',
        meta: {
          title: 'Checkout',
        },
        component: views.CheckoutInformation,
      },
      {
        path: 'shipping',
        name: 'checkoutShipping',
        meta: {
          title: 'Checkout',
        },
        component: views.CheckoutShipping,
      },
      {
        path: 'payment',
        name: 'checkoutPayment',
        meta: {
          title: 'Checkout',
        },
        component: views.CheckoutPayment,
      },

      {
        path: 'summary',
        name: 'checkoutSummary',
        meta: {
          title: 'Checkout',
        },
        component: views.CheckoutSummary,
        props: ({ query }) => ({
          orderId: query['orderId'] ?? null,
          orderEmail: query['orderEmail'] ?? false,
        }),
      },
    ],
  },
  {
    path: '/cookies',
    name: 'cookies',
    meta: {
      title: 'Cookies',
    },
    component: views.Cookies,
  },
  {
    path: '/country-availability',
    name: 'countryAvailability',
    meta: {
      title: 'Country Availability',
    },
    component: views.CountryAvailability,
  },
  {
    path: '/faq-editor',
    name: 'faqEditor',
    component: views.FaqEditor,
    meta: {
      accessRequirement: 'auth',
    },
  },
  {
    path: '/payment-processor-change-2024',
    name: 'paymentProcessorChange2024',
    component: views.PaymentProcessorChange2024,
  },

  //#region Dashboard Routes

  {
    path: '/dashboard',
    component: views.Dashboard,
    meta: {
      title: 'Dashboard',
      accessRequirement: 'auth',
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        meta: {
          title: 'Dashboard',
          accessRequirement: 'auth',
        },
        component: views.DashboardHome,
      },
      {
        path: 'manage',
        name: 'dashboardManage',
        component: views.DashboardManage,
      },
      {
        path: 'developer',
        name: 'dashboardDeveloper',
        component: views.DashboardDeveloper,
      },
      {
        path: 'orders',
        name: 'dashboardOrders',
        component: views.DashboardOrders,
      },
      {
        path: 'my-content',
        name: 'dashboardMyContent',
        component: views.DashboardMyContent,
      },
      {
        path: 'my-certifications',
        name: 'dashboardMyCertifications',
        component: views.DashboardMyCertifications,
      },
      {
        path: 'report',
        name: 'dashboardReport',
        component: views.DashboardReport,
      },
      {
        path: 'reports/:referredUser?',
        name: 'dashboardReports',
        component: views.DashboardReports,
        props: true,
      },
      {
        path: 'unity-modules',
        name: 'dashboardUnityModules',
        component: views.DashboardUnityModules,
      },
      {
        path: 'organization/:organizationId/course/:courseId',
        name: 'dashboardCourse',
        component: views.DashboardCourse,
        props: true,
      },
      {
        path: 'organization/:organizationId/course/:courseId/assignment/:assignmentId',
        name: 'dashboardCourseAssignment',
        component: views.DashboardCourseAssignment,
        props: true,
      },
      {
        path: 'organization/:organizationId/course/:courseId/student/:studentId',
        name: 'dashboardCourseStudent',
        component: views.DashboardCourseStudent,
        props: true,
      },
      {
        path: 'certifications',
        name: 'dashboardCertifications',
        component: views.DashboardCertifications,
      },
    ],
  },

  //#endregion Dashboard Routes

  {
    path: '/faq/:faqId?',
    name: 'faq',
    meta: {
      title: 'FAQs',
    },
    beforeEnter() {
      window.location.href = 'https://wiki.zephyr-sim.com/help/faqs';
    },
  },
  {
    path: '/features/:question?',
    name: 'features',
    meta: {
      title: 'Features',
    },
    component: views.Features,
    props: true,
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: 'News',
    },
    component: views.News,
  },
  {
    path: '/order-status/:orderId?',
    name: 'orderStatus',
    meta: {
      title: 'Order Status',
    },
    component: views.OrderStatus,
    props: true,
  },
  {
    path: '/order-status-2/:orderId?',
    redirect: { name: 'orderStatus' },
  },
  {
    path: '/pricing',
    name: 'pricing',
    meta: {
      title: 'Pricing',
    },
    component: views.Pricing,
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: {
      title: 'Privacy Policy',
    },
    component: views.PrivacyPolicy,
  },
  {
    path: '/returns',
    name: 'returns',
    meta: {
      title: 'Returns',
    },
    component: views.Returns,
  },
  {
    path: '/shipping-policy',
    name: 'shippingPolicy',
    meta: {
      title: 'Shipping Policy',
    },
    component: views.ShippingPolicy,
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      title: 'Shop',
    },
    component: views.Shop,
    props: ({ query: { referrer, scene } }) => ({
      referrer: isArray(referrer) ? null : referrer,
      scene: isArray(scene) ? null : scene,
    }),
  },
  {
    path: '/simulator-terms-and-conditions',
    name: 'simulatorTermsAndConditions',
    meta: {
      title: 'Simulator Terms and Conditions',
    },
    component: views.SimulatorTermsAndConditions,
  },
  {
    path: '/supported-controllers',
    name: 'supportedControllers',
    meta: {
      title: 'Supported Controllers',
    },
    component: views.SupportedControllers,
  },
  {
    path: '/system-requirements',
    name: 'systemRequirements',
    meta: {
      title: 'System Requirements',
    },
    component: views.SystemRequirements,
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    meta: {
      title: 'Terms and Conditions',
    },
    component: views.TermsAndConditions,
  },
  {
    path: '/nist-tutorial',
    name: 'nistTutorial',
    component: views.NistTutorial,
  },
  {
    path: '/bridge-tutorial',
    name: 'bridgeTutorial',
    component: views.BridgeTutorial,
  },
  {
    path: '/restricted',
    name: 'restricted',
    component: views.Restricted,
  },
  {
    path: '/individuals',
    name: 'individuals',
    component: views.Individuals,
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: views.Enterprise,
  },

  //#region Auth Routes

  {
    path: '/register/:inviteId?',
    name: 'register',
    async beforeEnter(to, _from) {
      const options: auth.RedirectRegistrationOptions = {};

      const inviteId = to.params['inviteId'];

      if (inviteId) {
        const invite = await getInvite(inviteId);

        if (invite) {
          options.userEmail = invite.email;
        }
      }

      const orderId = getSearchParams().get('orderId');

      if (orderId) {
        options.orderId = orderId;
      }

      await auth.registerWithRedirect(options);
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: {
      title: 'Personalize your Account',
      accessRequirement: 'auth',
    },
    component: views.Onboarding,
  },
  {
    path: '/login',
    name: 'login',
    async beforeEnter() {
      const options: auth.RedirectLoginOptions = {};

      const params = getSearchParams();

      const invitation = params.get('invitation');
      const organization = params.get('organization');

      const loginHint = params.get('login_hint');
      const screenHint = params.get('screen_hint');

      if (organization) {
        options.organization = organization;
      } else {
        options.prompt = 'login';
      }

      if (invitation) {
        options.invitation = invitation;
      }

      if (loginHint) {
        options.userEmail = loginHint;
      }

      if (screenHint) {
        options.screenHint = screenHint;
      }

      await auth.loginWithRedirect(options);
    },
  },
  // {
  //   path: '/login/launcher',
  //   name: 'loginLauncher',
  //   component: views.LoginLauncher,
  // },
  {
    path: '/login/callback',
    name: 'loginCallback',
    component: views.LoginCallback,
  },
  {
    path: '/logout',
    name: 'logout',
    alias: ['/signout'],
    component: views.Logout,
  },
  {
    path: '/password-reset',
    redirect: {
      name: 'login',
    },
  },
  {
    path: '/password-reset-request',
    redirect: {
      name: 'login',
    },
  },

  //#endregion Auth Routes

  {
    path: '/tutorials',
    name: 'tutorials',
    meta: {
      title: 'Tutorials',
    },
    component: views.Tutorials,
  },
  {
    path: '/invoice/:invoiceId?',
    name: 'invoice',
    meta: {
      accessRequirement: 'auth',
      title: 'Your Invoice',
    },
    component: views.Invoice,
    props: true,
  },
  {
    path: '/public-profile/:userId',
    name: 'publicProfile',
    meta: {
      title: 'Your Public Profile',
    },
    component: views.PublicProfile,
    props: ({ params, query }) => ({
      userId: params['userId'] ?? null,
      previewMode: query['previewMode'] ?? false,
    }),
  },
  {
    path: '/certifications',
    name: 'certifications',
    meta: {
      title: 'Certifications',
    },
    component: views.Certifications,
  },
  {
    path: '/drone-responders',
    name: 'droneResponders',
    component: views.DroneResponders,
  },
  {
    path: '/verification/:certificationId?',
    name: 'verification',
    component: views.Verification,
    props: true,
  },
  {
    path: '/apsa',
    name: 'apsa',
    component: views.APSA,
  },
  {
    path: '*',
    meta: {
      title: 'Page Not Found',
    },
    component: views.Error404,
    // beforeEnter(_to, _from, next) {
    //   next('/');
    // },
  },
];

//#region Development Routes

if (DEVELOPMENT) {
  routes.push(
    {
      path: '/debug-modals',
      name: 'debugModals',
      component: () => import('@views/DebugModals.vue'),
    },
    {
      path: '/testing',
      name: 'testing',
      meta: {
        title: 'Testing',
      },
      component: () => import('@views/TestingView'),
    },
  );
}

//#endregion Development Routes

/** App `vue-router` instance. */
export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(async (to, from, next) => {
  // If the target route is `logout`, navigation can safely proceed.
  if (to.name === 'logout') return next();

  // Determine access requirement, if any, for the target route.
  const toRouteRequirement = auth.getRouteAccessRequirement(to.matched);

  // If there is no requirement, navigation can safely proceed.
  if (!toRouteRequirement) return next();

  // If both the current and target route require auth, assume the user has
  // previously passed an authorization so that navigation is not delayed
  // by a request to get user session data.

  if (
    toRouteRequirement !== 'auth' ||
    auth.getRouteAccessRequirement(from.matched) !== 'auth'
  ) {
    await auth.isLoggedIn();
  }

  // ...
  if (auth.meetsAccessRequirement(toRouteRequirement)) return next();

  // ...

  if (toRouteRequirement === 'auth') {
    return auth.loginWithRedirect({
      returnTo: {
        path: to.path,
        params: to.params,
        query: to.query,
      },
    });
  }

  alert.warning(
    'You were redirected to this page because you did not have access to the page you attempted to visit.',
  );

  next({ name: 'dashboard' });
});

router.afterEach((to) => {
  const routeTitle =
    to.meta?.title ??
    to.matched.find((route) => !!route.meta.title)?.meta.title;

  if (routeTitle) {
    document.title = `${routeTitle} - ${SITE_TITLE}`;
  } else {
    document.title = SITE_TITLE;
  }
});

/**
 * Composable that returns the app's `vue-router` instance.
 *
 * @returns App router instance.
 */
export function useRouter() {
  return router;
}

/**
 * Composable that returns the current route.
 *
 * @returns Current route.
 */
export function useRoute() {
  /** ... */
  const route = ref(router.currentRoute) as Ref<typeof router.currentRoute>;

  onBeforeUnmount(
    router.afterEach(() => {
      route.value = router.currentRoute;
    }),
  );

  return route;
}

//#region Helper Functions

function getSearchParams() {
  return new URLSearchParams(window.location.search);
}

async function getInvite(inviteId: string) {
  try {
    return await api.invites.get({ inviteId });
  } catch {
    return null;
  }
}

//#endregion Helper Functions
