var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "checkout-cart-item" }, [
    _vm.removable
      ? _c(
          "button",
          {
            staticClass: "v-btn v-btn-sml v-btn-icon v-btn-danger",
            on: { click: _setup.remove },
          },
          [_c("Icon", { attrs: { icon: _setup.faTimes } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "item-info-col info-thumbnail" }, [
      _c(
        "div",
        { class: ["item-thumbnail", { loading: !_setup.imageLoaded }] },
        [
          !_setup.imageLoaded
            ? _c("span", { staticClass: "thumbnail-overlay" })
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            staticClass: "thumbnail-image",
            attrs: { src: _setup.thumbnail },
            on: {
              load: function ($event) {
                _setup.imageLoaded = true
              },
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item-info-col info-details" }, [
      _c("div", { staticClass: "item-name" }, [_vm._v(_vm._s(_vm.name))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item-info-col info-quantity" }, [
      _c("div", { staticClass: "quantity-ctrl-panel" }, [
        _c(
          "button",
          {
            staticClass: "v-btn v-btn-sml v-btn-icon",
            attrs: { disabled: _vm.quantity >= _vm.limit },
            on: { click: _setup.decrease },
          },
          [_c("Icon", { attrs: { icon: _setup.faMinus } })],
          1
        ),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.quantity))]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "v-btn v-btn-sml v-btn-icon",
            attrs: { disabled: _vm.quantity <= 1 },
            on: { click: _setup.increase },
          },
          [_c("Icon", { attrs: { icon: _setup.faPlus } })],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item-info-col info-price" },
      [
        _setup.discounted
          ? _c(_setup.DisplayTextCost, {
              staticClass: "regular-price",
              attrs: { value: _vm.price },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(_setup.DisplayTextCost, {
          staticClass: "current-price",
          attrs: { value: _vm.finalPrice },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }