import { api } from '@api';
import { PaymentMethod } from '@models';

import { openModal } from '../open-modal';

/**
 * Edit Payment Method modal configuration options.
 */
export interface EditPaymentMethodModalOptions {
  paymentMethodId: string;
}

/**
 * Open Edit Payment Method modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function edit(options: EditPaymentMethodModalOptions) {
  const paymentMethod = await api.paymentMethods.get(options);

  const modalOptions = {
    title: 'EDIT PAYMENT METHOD',
    component: () => import('./EditPaymentMethod.vue'),
    props: { paymentMethod },
  };

  return await openModal.safe(modalOptions);
}

/**
 * Remove Payment Method modal configuration options.
 */
export interface RemovePaymentMethodModalOptions {
  id: PaymentMethod['id'];
  digits: PaymentMethod['digits'];
  image: PaymentMethod['image'];
  expiration: PaymentMethod['expiration'];
  firstName: PaymentMethod['firstName'];
  lastName: PaymentMethod['lastName'];
  zip: PaymentMethod['postalCode'];
}

/**
 * Open Remove Payment Method modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function remove(options: RemovePaymentMethodModalOptions) {
  // const paymentMethod = await api.paymentMethods.get(options);

  const modalOptions = {
    title: 'REMOVE PAYMENT METHOD',
    component: () => import('./RemovePaymentMethod.vue'),
    props: { paymentMethod: options },
  };

  return await openModal.safe(modalOptions);
}
