
import { Vue, Component, Prop } from '@vue';
import { State, Getter } from '@vuex/class';
import { BvTableField } from 'bootstrap-vue';

import type { User } from '@models';
import * as store from '@store';
import { roundTo } from '@tools/math';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellScore: ComponentWithProps<Props>;
  }
}

/**
 * `TableCellScore` component properties.
 */
export interface Props {
  /** ... */
  field: Field;
  /** ... */
  item: User;
  /** ... */
  value: '--' | number | null;
}

/** ... */
type Field = BvTableField & { key: string };

/**
 * ...
 */
const RATING_TEXT = { poor: 'Poor', average: 'Average', good: 'Good' };

@Component
export default class TableCellScore extends Vue {
  @Prop(Object) readonly field!: Field;
  @Prop(Object) readonly item!: User;
  @Prop() readonly value!: '--' | number | null;

  @State(({ users }) => users.loadingMetrics)
  readonly loadingMetrics!: store.Users['loadingMetrics'];

  @Getter('metrics/rate') readonly rateMetric!: store.Metrics['rate'];

  /** ... */
  get key() {
    return this.field.key;
  }

  /** ... */
  get initialized() {
    return this.metrics !== null;
  }

  /** ... */
  get loading() {
    return this.loadingMetrics;
  }

  /** ... */
  get displayValue() {
    if (this.value === '--') return null;
    if (this.value === null) return 'N/A';

    if (!isValidMetric(this.key)) return this.value;

    return roundTo(100 * this.value, 2).toString() + '%';
  }

  /** ... */
  get rating() {
    if (
      !isValidMetric(this.key) ||
      this.value === null ||
      this.value === '--'
    ) {
      return null;
    }

    const rating = this.rateMetric(this.key, this.value);

    return !rating ? null : { value: rating, text: RATING_TEXT[rating] };
  }

  /** ... */
  private get metrics() {
    const metrics = this.$store.state.users.metrics ?? {};

    return metrics[this.item.id] ?? null;
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidMetric(value: unknown): value is store.Metrics.Catogory {
  return (
    value === 'reportsWithHighScore' ||
    value === 'reportsWithViolations' ||
    value === 'reportsWithCrashes'
  );
}

//#endregion Helper Functions
