/**
 * ...
 *
 * @param x ...
 * @param lower ...
 * @param upper ...
 * @returns ...
 */
export function clamp(x: number, lower: number, upper: number) {
  if (x < lower) {
    x = lower;
  } else if (x > upper) {
    x = upper;
  }

  return x;
}

/**
 * Round a number to the specified decimal place.
 *
 * @param x ...
 * @param place ...
 * @return ...
 */
export function roundTo(x: number, place = 0) {
  const f = 10 ** place;

  return Math.round(x * f) / f;
}

/**
 * ...
 *
 * @param t ...
 * @param length ...
 * @return ...
 */
export function repeat(t: number, length: number) {
  return clamp(t - Math.floor(t / length) * length, 0, length);
}
