import { request } from '@api/request';
import { Reseller, Organization, Invite, Product } from '@models';
import * as typeGaurds from '@tools/type-guards';

/**
 * List all existing resellers.
 *
 * @return A list of resellers.
 */
export async function list() {
  return await request.list('/api/resellers', processResellerData);
}

/**
 * ...
 */
export interface GetResellerOptions {
  id: number;
}

/**
 * Get a specified reseller.
 *
 * @param options Request options bag.
 * @return The specified reseller.
 */
export async function get(options: GetResellerOptions) {
  return await request.get(`/api/resellers/${options.id}`, processResellerData);
}

/**
 * ...
 */
export interface CreateOptions {
  // id: number;
  id: string;
  name: string;
  website: string;
  contactEmail: string;
  cut: string;
  products?: Product[];
  // institutions?: Institution[];
  accounts?: Organization[];
  invites?: Invite[];
}

/**
 * Create a reseller.
 *
 * @param options Request options bag.
 * @return The specified reseller.
 */
export async function create(options: CreateOptions) {
  return await request.post(
    `/api/resellers/${options.id}`,
    options,
    processResellerData,
  );
}

/**
 * ...
 */
export interface AddProductsOptions {
  id: number;
  productIds: string[];
}

/**
 * Add products to a reseller.
 *
 * @param options Request options bag.
 * @return ...
 */
export async function addProducts(options: AddProductsOptions) {
  return await request.post(
    `/api/resellers/${options.id}/add-product`,
    options,
    processResellerData,
  );
}

/**
 * ...
 */
export interface RemoveProductsOptions {
  id: number;
  productIds: string[];
}

/**
 * Remove products from a reseller.
 *
 * @param options Request options bag.
 * @return ...
 */
export async function removeProducts(options: RemoveProductsOptions) {
  return await request.post(
    `/api/resellers/${options.id}/remove-product`,
    options,
    processResellerData,
  );
}

/**
 * ...
 */
export interface InviteOptions {
  id: number;
  emails: string[];
}

/**
 * Invite reseller users.
 *
 * @param options Request options bag.
 * @return ...
 */
export async function inviteUsers(options: InviteOptions) {
  await request.post(
    `/api/resellers/${options.id}/invite`,
    options,
    processResellerData,
  );
}

//region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidResellertData(value: unknown): value is Reseller {
  if (!typeGaurds.isObject(value)) return false;

  return (
    typeGaurds.isString(value.id) && typeGaurds.isString(value.announcementId)
  );
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processResellerData(data: unknown) {
  if (!isValidResellertData(data)) {
    throw new Error('Invalid reseller announcement data.');
  }

  return { ...data } as Reseller;
}

//#endregion Helper Functions
