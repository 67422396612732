import { Organization } from '@models';

/** Billing type selection options. */
// TODO re-enable billing types when we release stripe
export const billingTypeOptions: ZephyrWeb.OptionItem<Organization.BillingType>[] =
  [
    { text: 'Point of Transaction', value: 'POT', disabled: true },
    { text: 'Billed', value: 'BillMeLater', disabled: true },
    { text: 'Student Pays', value: 'StudentPays', disabled: true },
    { text: 'Site License', value: 'SiteLicense' },
    // { text: 'Reseller', value: 'Reseller' },
  ];
