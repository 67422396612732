var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "CardLoadable",
    {
      staticClass: "card-products",
      attrs: { loading: _vm.loading, "no-body": "" },
    },
    [
      _c(
        "b-card-header",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [_c("h6", { staticClass: "mb-0" }, [_vm._v("Products")])]
      ),
      _vm._v(" "),
      _c(
        "b-card-body",
        { staticClass: "p-0" },
        [
          _c("b-table", {
            staticClass: "border-bottom border-200 m-0",
            attrs: {
              fields: _vm.fields,
              items: _vm.products,
              "per-page": "5",
              "current-page": _vm.pageNumber,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function ({ value, item }) {
                  return [
                    _c("b-avatar", { attrs: { src: item.images[0] } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(value)),
                    ]),
                  ]
                },
              },
              {
                key: "cell(stock)",
                fn: function ({ value, item }) {
                  return [
                    _c("span", { staticClass: "d-flex align-items-center" }, [
                      _c("span", { staticClass: "mr-3" }, [
                        _vm._v(_vm._s(value)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "column-button",
                          on: {
                            click: function ($event) {
                              return _vm.update(item.id)
                            },
                          },
                        },
                        [_c("Icon", { attrs: { icon: _vm.icons.faEdit } })],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "py-3", attrs: { "no-gutters": "" } },
            [
              _c("b-col", { staticClass: "pl-3" }),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "pr-3", attrs: { cols: "auto" } },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0",
                    attrs: {
                      "total-rows": _vm.products.length,
                      "per-page": "5",
                    },
                    model: {
                      value: _vm.pageNumber,
                      callback: function ($$v) {
                        _vm.pageNumber = $$v
                      },
                      expression: "pageNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }