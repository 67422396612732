import { watch, onMounted, onUnmounted } from 'vue';

import { useStore } from '@store';
import type { Me } from '@store/modules/me';

/**
 * Get current user data from the store. An error is thrown if there is none.
 *
 * @returns Store user data.
 */
export function useMe() {
  const store = useStore();

  const me = store.state.me;

  if (!me.id) {
    throw new Error(
      '[useMe] no user data to fetch. Be sure this composable is only used in a context where user data is certain to present,',
    );
  }

  return me as Me.UserSessionInfo;
}

/**
 * Register using addEventListener on mounted, and removeEventListener
 * automatically on unmounted.
 *
 * @param event
 * @param listener
 * @returns ...
 */
export function useEventListener<E extends keyof WindowEventMap>(
  event: E,
  listener: (this: Window, event: WindowEventMap[E]) => void,
  options?: boolean | AddEventListenerOptions,
) {
  const add = () => {
    if (options !== undefined) {
      window.addEventListener(event, listener, options);
    } else {
      window.addEventListener(event, listener);
    }
  };

  const remove = () => {
    if (options !== undefined) {
      window.removeEventListener(event, listener, options);
    } else {
      window.removeEventListener(event, listener);
    }
  };

  onMounted(add);
  onUnmounted(remove);

  return remove;
}

/**
 * ...
 *
 * @param handler
 * @param timeout
 * @returns ...
 */
export function useInterval(handler: () => void, timeout?: number) {
  let intervalId: number;

  const set = () => {
    intervalId = window.setInterval(handler, timeout);
  };

  const clear = () => {
    window.clearInterval(intervalId);
  };

  onMounted(set);
  onUnmounted(clear);

  return clear;
}

/**
 * Set an attribute on the DOM root node.
 *
 * @param name Attribute name.
 * @param callback ...
 * @returns ...
 */
export function useSetRootAttribute(
  name: string,
  callback: () => string | null | undefined,
) {
  const attrName = `data-${name}`;

  return watch(
    callback,
    (value) => {
      if (value) {
        document.documentElement.setAttribute(attrName, value);
      } else {
        document.documentElement.removeAttribute(attrName);
      }
    },
    { immediate: true },
  );
}
