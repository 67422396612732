var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "section-proud-partners" },
    [
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c("b-col", { staticClass: "mx-auto", attrs: { lg: "8" } }, [
            _c("h2", [_vm._v("Proud Partners")]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "partner-cards-wrapper" },
              _vm._l(_vm.partners, function ({ id, ...partner }) {
                return _c(
                  "div",
                  {
                    key: id,
                    staticClass: "logo-image-container",
                    attrs: {
                      "data-anim-name": "slideInUp",
                      "data-anim-group": "1",
                    },
                  },
                  [
                    _c("a", {
                      style: { backgroundColor: partner.color },
                      attrs: { target: "_blank", href: partner.link },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "logo-image",
                      style: { backgroundImage: partner.logo },
                    }),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "partner-cards-bg" }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }