import { request } from '@api/request';
import { ExtensionCode, Course, User } from '@models';

/**
 * List all existing extension codes.
 *
 * @return A list of extension codes.
 */
export async function list() {
  return await request.list('/api/extension-codes');
}

/**
 * ...
 */
export interface GetOptions {
  extensionCodeId: ExtensionCode['id'];
  courseId: Course['id'];
}

/**
 * Get a specified extension code.
 *
 * @param options Request options bag.
 * @return The specified extension code.
 */
export async function get(options: GetOptions) {
  return await request.get(
    `/api/extension-codes/${options.extensionCodeId}/${options.courseId}`,
  );
}

/**
 * ...
 */
export interface CreateOptions {
  extensionCodeId: ExtensionCode['id'];
  courseId: Course['id'];
  userId: User['id'];
}

/**
 * Create an extension code.
 *
 * @param options Request options bag.
 * @return The created extension code.
 */
export async function create(options: CreateOptions) {
  return await request.post('/api/extension-codes', options);
}

/**
 * ...
 */
export interface ValidateOptions {
  extensionCodeId: ExtensionCode['id'];
  courseId: Course['id'];
}

/**
 * Check to see if provided extension code is valid or not.
 *
 * @param options Request options bag.
 * @return `true` if the extension code is valid, otherwise `false`.
 */
export async function validate(options: ValidateOptions) {
  return await request.get<boolean>(
    `/api/extension-codes/validate/${options.extensionCodeId}/${options.courseId}`,
  );
}

/**
 * ...
 */
export interface DeleteOptions {
  extensionCodeId: ExtensionCode['id'];
}

/**
 * Delete an extension code.
 *
 * @param options Request options bag.
 */
export async function del(options: DeleteOptions) {
  await request.delete(`/api/extension-codes/${options.extensionCodeId}`);
}
