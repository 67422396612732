import { currencyFilter } from './currency';

/**
 * ...
 *
 * @param amount ...
 * @param currencySymbol ...
 * @param fractionSize ...
 * @return ...
 */
export function costFilter(
  amount: number,
  currencySymbol?: string,
  fractionSize?: number,
) {
  return amount === 0
    ? 'Free'
    : currencyFilter(amount, currencySymbol, fractionSize);
}

export default costFilter;
