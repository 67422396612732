
import { Vue, Component, Prop } from '@vue';
import { startCase } from 'lodash';

import { faCaretRight } from '@icons/solid/faCaretRight';
import { faCaretDown } from '@icons/solid/faCaretDown';

import { dateFilter } from '@filters/date';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    DisplayObject: ComponentWithProps<Props>;
  }
}

/**
 * ...
 */
interface ObjectProperty {
  key: string;
  label: string;
  blank: boolean;
  value: unknown;
  type: string;
  expanded: boolean;
}

/**
 * ...
 */
export interface Props {
  object: object;
  include?: string[];
  expanded?: boolean;
  appearance?: 'regular' | 'json';
}

@Component
export default class DisplayObject extends Vue {
  @Prop({ type: [Object, Array] }) readonly object!: object;
  @Prop(Array) readonly include?: string[];
  @Prop(Boolean) readonly expanded?: boolean;
  @Prop(String) readonly appearance?: 'regular' | 'json';

  readonly icons = { faCaretRight, faCaretDown };

  /** ... */
  get properties() {
    let entries = Object.entries(this.object);

    if (this.include) {
      entries = entries.filter(([key]) => (this.include ?? []).includes(key));
    }

    return entries.map(this.mapObjectProperty.bind(this));
  }

  /**
   * ...
   */
  onClick(prop: ObjectProperty) {
    if (prop.type === 'object') {
      prop.expanded = !prop.expanded;
    }
  }

  /**
   * ...
   *
   * @param entry ...
   * @return ...
   */
  private mapObjectProperty(entry: [string, unknown]) {
    const [key, value] = entry;

    // ...
    const label = this.appearance === 'json' ? key : startCase(key);
    // const label = key;

    // ...
    const blank = value === null || value === undefined || value === '';

    // ...
    const type =
      value === null
        ? 'null'
        : value === undefined
        ? 'undefined'
        : value instanceof Date
        ? dateFilter(value, 'MM/dd/yyyy')
        : typeof value;

    const prop = {
      key,
      label,
      blank,
      value,
      type,
      expanded: this.expanded ?? false,
    };

    return Vue.observable(prop) as ObjectProperty;
  }
}
