import { api } from '@api';
import { Order } from '@models';
import { auth } from '@auth';

import { openModal } from '../open-modal';

/** ... */
export type ViewTransactionModalOptions =
  | { orderId: string; admin?: boolean }
  | { order: Order };

/**
 * Open View Transaction modal.
 *
 * @return Modal promise.
 */
export async function view(options: ViewTransactionModalOptions) {
  let order: Order;

  if ('order' in options) {
    order = options.order;
  } else {
    options.admin = auth.isActiveRole(9);
    order = await api.orders.get(options);
  }

  const modalOptions = {
    title: 'VIEW ORDER',
    component: () => import('./ViewTransaction.vue'),
    props: { order },
  };

  await openModal.safe(modalOptions);
}
