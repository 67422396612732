import { Discount } from '@models';

import { openModal } from '../open-modal';

export interface EditDiscountModalOptions {
  discount: Discount;
}

/**
 * Open Create Discount modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function create() {
  const modalOptions = {
    title: 'CREATE DISCOUNT',
    component: () => import('./CreateDiscount.vue')
  };

  try {
    await openModal(modalOptions);
  } catch (_) {
    return;
  }
}

/**
 * Open Edit Discount modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function edit(options: EditDiscountModalOptions) {
  const modalOptions = {
    title: 'EDIT DISCOUNT',
    props: { ...options },
    component: () => import('./EditDiscount.vue')
  };

  try {
    await openModal(modalOptions);
  } catch (_) {
    return;
  }
}
