var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "product-card" },
    [
      _c("div", { staticClass: "product-card-inner" }, [
        _c("div", { staticClass: "product-title" }, [
          _c("div", {
            staticClass: "product-image",
            style: { backgroundImage: `url(${_vm.images[0]})` },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "product-name" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.left",
                  value: "View Product",
                  expression: "'View Product'",
                  modifiers: { left: true },
                },
              ],
              staticClass: "product-button view",
              on: { click: _vm.viewProduct },
            },
            [_c("Icon", { attrs: { icon: _vm.icons.faEye } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "product-stock",
            on: {
              click: function ($event) {
                _vm.showEditStockPopup = !_vm.showEditStockPopup
              },
            },
          },
          [
            _c("span", { staticClass: "stock-number" }, [
              _vm._v(_vm._s(_vm.stock)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "stock-label" }, [
              _vm._v("click to edit"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("transition", [
        _vm.showEditStockPopup
          ? _c("div", { staticClass: "edit-product-stock" }, [
              _c("div", { staticClass: "product-panel-pointer" }, [_c("span")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.stockAmount,
                    expression: "stockAmount",
                  },
                ],
                attrs: { type: "number", step: "1", min: "0" },
                domProps: { value: _vm.stockAmount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.stockAmount = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  on: { click: _vm.updateStock },
                },
                [_vm._v("Submit")]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }