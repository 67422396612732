var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "display-price", style: _vm.style },
    [
      _vm.isFree
        ? [_c("span", [_vm._v("Free")])]
        : [
            _c("span", [_vm._v(_vm._s(_vm.displayPrice.dollars))]),
            _vm._v(" "),
            _c("span", { staticClass: "amount-change" }, [
              _vm._v("." + _vm._s(_vm.displayPrice.change)),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }