var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.dismissed
    ? _c("div", { staticClass: "site-announcement" }, [
        _c(
          "div",
          { staticClass: "site-announcement-inner" },
          [
            _vm._v("\n    " + _vm._s(_vm.title) + "\n\n    "),
            _vm.details
              ? _c(
                  "b-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.showDetails()
                      },
                    },
                  },
                  [_vm._v(" Details ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-button",
              {
                on: {
                  click: function ($event) {
                    _vm.dismissed = true
                  },
                },
              },
              [_vm._v(" Dismiss ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }