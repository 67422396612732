import { server } from '@api/request';
import { dataUtils } from '@api/data-utils';
import * as models from '@models';
import { parse } from '@tools/safe-json-parse';
import { isArray, isString, isObject, isNull } from '@tools/type-guards';

type PaginatedOrdersResults = ZephyrWeb.Pagination.Results<models.Order>;

/**
 * {@link search Search orders} API request namespace.
 */
export namespace OrderSearch {
  /**
   * {@link search Search orders} API request options.
   */
  export interface Options {
    orderId?: string;
    email?: string;
    order?: string;
    hideFreeOrders?: boolean;
    onlyReferralOrders?: boolean;
    startDate?: string;
    endDate?: string;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link search Search orders} API request response.
   */
  export type Results = PaginatedOrdersResults;
}

/**
 * Search for order details by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns `OrderSearch.Results` data object.
 *
 * @see {@link ZephyrApi.Admin.Orders.listOrders `AdminSearchOrders`} API route in `zephyr-serverless`
 */
export async function search(options: OrderSearch.Options) {
  return await server.post('v2/admin/orders', options, processSearchResults);
}

/**
 * List all of the current user's orders
 *
 * @returns A list of orders.
 *
 * @see {@link ZephyrApi.Users.listOrders `GetMyOrders`} API route in `zephyr-serverless`
 */
export async function listMine() {
  return await server.list('v2/users/orders', processOrderData);
}

/**
 * {@link listByUser List orders by user} API request options.
 */
export interface ListByUserOptions {
  userId: models.User['id'];
}

/**
 * List all orders belonging to a specified user.
 *
 * @param options Request options bag.
 * @returns A list of scenes.
 *
 * @see {@link ZephyrApi.Admin.Orders.listOrders `AdminListUserOrders`} API route in `zephyr-serverless`
 */
export async function listByUser(options: ListByUserOptions) {
  return await server.list(
    `v2/admin/users/${options.userId}/orders`,
    processOrderData,
  );
}

/**
 * {@link listByOrganization List orders by organization} API request options.
 */
export interface ListByOrganizationOptions {
  organizationId: models.Organization['id'];
}

/**
 * List all orders belonging to a specified organization.
 *
 * @param options Request options bag.
 * @returns A list of orders.
 *
 * @see {@link ZephyrApi.Organizations.listOrders `ListOrganizationOrders`} API route in `zephyr-serverless`
 */
export async function listByOrganization(options: ListByOrganizationOptions) {
  return await server.list(
    `v2/organizations/${options.organizationId}/orders`,
    processOrderData,
  );
}

/**
 * {@link get Get order} API request options.
 */
export interface GetOptions {
  orderId: models.Order['id'];
  recaptcha?: string;
  admin?: boolean;
}

/**
 * Get a specified order.
 *
 * @param options Request options bag.
 * @returns The specified order.
 *
 * @see {@link ZephyrApi.Orders.getOrder `GetOrder`} API route in `zephyr-serverless`
 * @see {@link ZephyrApi.Admin.Orders.adminGetOrder `AdminGetOrder`} API route in `zephyr-serverless`
 */
export async function get(options: GetOptions) {
  const { orderId, admin, recaptcha } = options;

  if (admin) {
    return await server.get(`v2/admin/orders/${orderId}`, processOrderData);
  }

  return await server.post(
    `v2/orders/${orderId}`,
    { recaptcha },
    processOrderData,
  );
}

/**
 * {@link getPublic Get public order} API request options.
 */
export interface GetPublicOptions {
  orderId: string;
  email: string;
  reCaptcha: string;
}

/**
 * Get (public) order details.
 *
 * @param options Request options bag.
 * @returns The specified (public) order details.
 *
 * @see {@link ZephyrApi.Orders.getOrder `PublicGetOrderDetails`} API route in `zephyr-serverless`
 */
export async function getPublic(options: GetPublicOptions) {
  const payload = {
    email: options.email,
    reCaptcha: options.reCaptcha,
  };

  return await server.post(
    `v2/orders/${options.orderId}`,
    payload,
    processOrderData,
  );
}

/**
 * {@link assign Assign order} API request options.
 */
export interface AssignOrderOptions {
  orderId: models.Order['id'];
  userId: models.User['id'];
}

/**
 * Assign order to user
 */

/**
 * Assign order to user.
 *
 * @param options Request options bag.
 * @returns The assigned order.
 *
 * @see {@link ZephyrApi.Admin.Orders.assignOrder `AdminAssignOrder`} API route in `zephyr-serverless`
 */
export async function assign(options: AssignOrderOptions) {
  return await server.post(`v2/admin/orders/assign`, options, processOrderData);
}

//#region Helper Functions

type OldCartItem = Omit<
  models.Order.CartItem,
  'price' | 'customsPrice' | 'salePrice' | 'upSell' | 'downSell'
> & {
  price: string;
  customsPrice: string;
  salePrice: string;
  upSell: string;
  downSell: string;
};

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function sanitizeCartItem(data: unknown) {
  if (!isObject(data)) {
    throw new TypeError(
      '[createCartItem] value provided for CartItem data was not valid.',
    );
  }

  const item = data as models.Order.CartItem | OldCartItem;

  // ...
  const images = isArray(item.images)
    ? item.images
    : isString(item.images)
    ? parse(item.images) ?? []
    : [];

  // Parse quantity-based string values into number.

  const price = dataUtils.parseFloat(item.price, true);
  const customsPrice = dataUtils.parseFloat(item.customsPrice);
  const salePrice = dataUtils.parseFloat(item.salePrice);
  const upSell = dataUtils.parseFloat(item.upSell);
  const downSell = dataUtils.parseFloat(item.downSell);

  return {
    ...item,
    images,
    price,
    customsPrice,
    salePrice,
    upSell,
    downSell,
  } as models.Order.CartItem;
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processOrderData(data: unknown) {
  if (!isObject(data)) {
    throw new TypeError(
      '[createCartItem] value provided for CartItem data was not valid.',
    );
  }

  const order: models.Order = {
    id: dataUtils.assertIsString(data.id),
    createdAt: dataUtils.assertIsString(data.createdAt),
    updatedAt: dataUtils.assertIsString(data.updatedAt),
    transactionId: dataUtils.assertIsString(data.transactionId),
    email: dataUtils.assertIsString(data.email),
    cart: dataUtils.assertIsArray(data.cart).map(sanitizeCartItem),
    phone: dataUtils.assertIsString(data.phone, false),
    referralCode: dataUtils.assertIsString(data.referralCode, false),
    discountCode: dataUtils.assertIsString(data.discountCode, false),
    billingFirst: dataUtils.assertIsString(data.billingFirst, false),
    billingLast: dataUtils.assertIsString(data.billingLast, false),
    billingAddress1: dataUtils.assertIsString(data.billingAddress1, false),
    billingAddress2: dataUtils.assertIsString(data.billingAddress2, false),
    billingAddress3: dataUtils.assertIsString(data.billingAddress3, false),
    billingCity: dataUtils.assertIsString(data.billingCity, false),
    billingStateProvince: dataUtils.assertIsString(
      data.billingStateProvince,
      false,
    ),
    billingPostalCode: dataUtils.assertIsString(data.billingPostalCode, false),
    billingCountry: dataUtils.assertIsString(data.billingCountry, false),
    shippingFirst: dataUtils.assertIsString(data.shippingFirst, false),
    shippingLast: dataUtils.assertIsString(data.shippingLast, false),
    shippingAddress1: dataUtils.assertIsString(data.shippingAddress1, false),
    shippingAddress2: dataUtils.assertIsString(data.shippingAddress2, false),
    shippingAddress3: dataUtils.assertIsString(data.shippingAddress3, false),
    shippingCity: dataUtils.assertIsString(data.shippingCity, false),
    shippingStateProvince: dataUtils.assertIsString(
      data.shippingStateProvince,
      false,
    ),
    shippingPostalCode: dataUtils.assertIsString(
      data.shippingPostalCode,
      false,
    ),
    shippingCountry: dataUtils.assertIsString(data.shippingCountry, false),
    isShipping: dataUtils.assertIsBoolean(data.isShipping),
    expedited: dataUtils.assertIsBoolean(data.expedited),
    productTotal: dataUtils.assertIsNumber(data.productTotal),
    shippingTotal: dataUtils.assertIsNumber(data.shippingTotal),
    taxTotal: dataUtils.assertIsNumber(data.taxTotal),
    discountTotal: dataUtils.assertIsNumber(data.discountTotal),
    total: dataUtils.assertIsNumber(data.total),
    status: dataUtils.assertIsString(data.status),
    // TODO: Check with Eric about whether this should ever be undefined or not.
    user: data.user ?? { id: '', name: '' },
  };

  return order;
}

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidSearchResults(value: unknown): value is OrderSearch.Results {
  return (
    isObject(value) &&
    isArray(value['items']) &&
    (isObject(value['lastEvaluatedKey']) || isNull(value['lastEvaluatedKey']))
  );
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid search result data.');
  }

  return data;
}

//#endregion Helper Functions
