import { ref, computed, onMounted, defineComponent, type PropType } from 'vue';

import { Promotion as PromotionData } from '@interfaces';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    SectionPromotions: ComponentWithProps<Props>;
  }
}

export interface Props {
  promotions: PromotionData[];
}

export default defineComponent({
  name: 'SectionPromotions',
  props: {
    promotions: {
      type: Array as PropType<PromotionData[]>,
      required: true,
    },
  },
  setup(props) {
    const currentPromoIndex = ref(0);
    const intervalDuration = ref(15_000);

    const intervalId = ref<number | null>(null); // private

    const currentPromo = computed(() => {
      return props.promotions[currentPromoIndex.value] ?? null;
    });

    const animationDuration = computed(() => {
      return (intervalDuration.value * 0.001).toString() + 's';
    });

    const videoUrl = computed(() => {
      const videoId = props.promotions[currentPromoIndex.value]?.youtubeVideoId;
      return videoId && typeof videoId === 'string'
        ? `https://www.youtube.com/embed/${videoId}`
        : null;
    });

    onMounted(() => {
      // Previously from 'created' hook.
      startCycle();
    });

    const beforeDestroy = () => {
      stopCycle();
    };

    const setPromoId = (index: number) => {
      clearRotationInterval();

      currentPromoIndex.value = index;
    };

    const startCycle = () => {
      if (intervalId.value) return;

      intervalId.value = window.setInterval(
        promoRotationHandler.bind(this),
        intervalDuration.value,
      );
    };

    const stopCycle = () => {
      clearRotationInterval();
    };

    const promoRotationHandler = () => {
      currentPromoIndex.value =
        currentPromoIndex.value >= props.promotions.length - 1
          ? 0
          : currentPromoIndex.value + 1;
    };

    const clearRotationInterval = () => {
      if (!intervalId.value) return;

      clearInterval(intervalId.value);

      intervalId.value = null;
    };

    return {
      currentPromoIndex,
      intervalDuration,
      intervalId,
      currentPromo,
      animationDuration,
      videoUrl,
      beforeDestroy,
      setPromoId,
      startCycle,
      stopCycle,
      promoRotationHandler,
      clearRotationInterval,
    };
  },
});
