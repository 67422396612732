import { alert } from '@services/alert';
import { browser } from '@services/device-info';

declare module 'vue/types/vue' {
  interface Vue {
    $clipboard: ClipboardService;
  }
}

class ClipboardService {
  /**
   * Copy text into the user's clipboard.
   *
   * @param value The text to copy to the clipboard.
   * @param message Message to be displayed after text has been copied.
   */
  async copy(value: string, message: string | false = 'Copied to clipboard.') {
    if (browser !== 'safari') {
      if (!(await checkPermissions())) return;
    }

    try {
      await navigator.clipboard.writeText(value);
    } catch {
      return;
    }

    if (message === false) return;

    alert.success(message);
  }
}

async function checkPermissions() {
  const promise = navigator.permissions.query({
    name: 'clipboard-write' as PermissionName,
  });

  try {
    return (await promise).state === 'granted';
  } catch {
    return false;
  }
}

export const clipboard = new ClipboardService();

export default clipboard;
