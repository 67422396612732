
import { Vue, Component, Prop } from '@vue';

import { faEdit } from '@icons/duotone/faEdit';

import { Product } from '@models';

@Component
export default class CardProducts extends Vue {
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Array) readonly products!: Product[];

  readonly icons = { faEdit };
  readonly fields = [
    { key: 'name', label: 'Name' },
    { key: 'stock', label: 'Stock' },
  ];

  pageNumber = 1;

  /**
   * ...
   */
  async update(productId: Product['id']) {
    let error: unknown = null;

    try {
      await this.$modals.product.edit({ productId });
    } catch (err) {
      error = err;
    }

    if (error) {
      return this.$alert.error(error);
    }

    this.$alert.success('Updated product stock.');
  }
}
