import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Discount } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'discounts/findById': Discounts['findById'];
  }

  export interface CommitMap {
    'discounts/SET': Discounts['SET'];
    'discounts/ADD': Discounts['ADD'];
    'discounts/UPDATE': Discounts['UPDATE'];
    'discounts/DELETE': Discounts['DELETE'];
    'discounts/CLEAR': Discounts['CLEAR'];
  }

  export interface DispatchMap {
    'discounts/get': Discounts['get'];
    'discounts/list': Discounts['list'];
    'discounts/create': Discounts['create'];
    'discounts/update': Discounts['update'];
    'discounts/del': Discounts['del'];
  }
}

@Module({ namespaced: true })
export class Discounts
  extends VuexModule<Discounts.State, Root.State>
  implements Discounts.State
{
  items: Discount[] = [];

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  // region Mutations

  @Mutation
  SET(options: Discounts.SetMutationOptions) {
    this.items = options.data;
  }

  @Mutation
  ADD(options: Discounts.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  @Mutation
  UPDATE(options: Discounts.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.discountId });

    if (i === -1) {
      throw new Error(`Discunt with ID ${options.discountId} not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  @Mutation
  DELETE(options: Discounts.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.discountId });

    if (index === -1) {
      throw new Error(`Discount with ID ${options.discountId} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  @Mutation
  CLEAR() {
    this.items = [];
  }

  // endregion Mutations

  // region Actions

  @Action
  async get(options: Discounts.GetActionOptions) {
    const data = await api.discounts.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  @Action
  async list() {
    const data = await api.discounts.list();

    this.context.commit('SET', { data });

    return this.items;
  }

  @Action
  async create(options: Discounts.CreateActionOptions) {
    const data = await api.discounts.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  @Action
  async update(options: Discounts.UpdateActionOptions) {
    await api.discounts.update(options);

    return await this.context.dispatch('get', options);
  }

  @Action
  async del(options: Discounts.DeleteActionOptions) {
    await api.discounts.del(options);

    this.context.commit('DELETE', options);
  }

  // endregion Actions
}

export namespace Discounts {
  /**
   * ...
   */
  export interface State {
    items: Discount[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Discount[];
  }

  /** ... */
  export type AddMutationOptions = Discount;
  /** ... */
  export type UpdateMutationOptions = api.discounts.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.discounts.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.discounts.GetOptions;
  /** ... */
  export type CreateActionOptions = api.discounts.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.discounts.UpdateOptions;
  /** ... */
  export type DeleteActionOptions = api.discounts.DeleteOptions;
}

export default Discounts;
