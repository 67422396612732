var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: ["display-object", _vm.appearance] },
    _vm._l(_vm.properties, function (prop) {
      return _c("div", { key: prop.key, staticClass: "object-property" }, [
        _c(
          "span",
          {
            staticClass: "property-key",
            class: ["property-key", { expandable: prop.type === "object" }],
            on: {
              click: function ($event) {
                return _vm.onClick(prop)
              },
            },
          },
          [
            _c("span", { staticClass: "property-gutter" }, [
              prop.type === "object"
                ? _c(
                    "span",
                    { staticClass: "property-expander" },
                    [
                      _c("Icon", {
                        attrs: {
                          icon: prop.expanded
                            ? _vm.icons.faCaretDown
                            : _vm.icons.faCaretRight,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "property-label" }, [
              _vm._v(_vm._s(prop.label)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "mr-2" }, [_vm._v(":")]),
            _vm._v(" "),
            prop.type === "object" && !prop.expanded
              ? _c("span", [_vm._v("{ ... }")])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          { class: ["propert-value-container", `type-${prop.type}`] },
          [
            prop.blank
              ? _c("span", { staticClass: "propert-blank" }, [_vm._v("--")])
              : prop.type === "object"
              ? _c(
                  "span",
                  { staticClass: "w-100" },
                  [
                    _c(
                      "b-collapse",
                      {
                        model: {
                          value: prop.expanded,
                          callback: function ($$v) {
                            _vm.$set(prop, "expanded", $$v)
                          },
                          expression: "prop.expanded",
                        },
                      },
                      [
                        _c(
                          "DisplayObject",
                          _vm._b(
                            { attrs: { object: prop.value } },
                            "DisplayObject",
                            { appearance: _vm.appearance },
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  { staticClass: "propert-value" },
                  [
                    _vm.appearance === "json" && prop.type === "string"
                      ? [
                          _vm._v(
                            '\n          "' + _vm._s(prop.value) + '"\n        '
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n          " + _vm._s(prop.value) + "\n        "
                          ),
                        ],
                  ],
                  2
                ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }