import { Vue, Component, Watch } from '@vue';

declare module '.' {
  export type CardReviewerAudit = CardReviewerAuditComponent;
}

interface DataSet {
  data: number[];
  label?: string;
}

interface ChartData {
  datasets: DataSet[];
  labels: string[];
  series: string[];
}

@Component
export default class CardReviewerAuditComponent extends Vue {
  loading = false;
  reviewedData: number[] = [];
  labels: string[] = [];
  series: string[] = [];

  @Watch('certifications') onCertificationsChanged() {
    this.load();
  }

  get certifications() {
    // filter certifications that I've reviewed
    return this.$store.state.certifications.items.filter(
      (c) => c.reviewer?.id === this.$store.state.me.id,
    );
  }

  get certificationChartData() {
    return {
      datasets: [{ label: 'Reviewed Certifications', data: this.reviewedData }],
      labels: this.labels,
      series: this.series,
    } as ChartData;
  }

  private load() {
    this.reviewedData = [];
    this.labels = [];
    this.series = [];
    // create last 6 months of series
    const months = 6;
    const today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();

    for (let i = 0; i < months; i++) {
      this.labels.unshift(`${currentMonth + 1}/${currentYear}`);

      const relevantCerts = this.certifications.filter((c) => {
        if (!c.approvedAt && !c.deniedAt) return;

        const reviewedDate = c.approvedAt ? c.approvedAt : c.deniedAt;
        if (typeof reviewedDate !== 'string') return;

        const approvedDate = new Date(reviewedDate);
        return (
          approvedDate.getMonth() === currentMonth &&
          approvedDate.getFullYear() === currentYear
        );
      }).length;

      this.reviewedData.unshift(relevantCerts);

      if (currentMonth - 1 < 0) {
        currentMonth = 11;
        currentYear--;
      } else {
        currentMonth--;
      }
    }
  }
}
