import { Vue } from '@vue';
import { Getters } from '@vuex';
import { VuexModule, Mutation, Action } from '@vuex/decorators';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

import { delay } from '@services/delay';
import * as store from '@store';
import { ensureError } from '@tools/ensure-error';
import { isObject, isArray } from '@tools/type-guards';
import { clamp } from '@tools/math';
import { RoleId } from '@values/roles';

import { IconDefinition } from '@icons/core';

/**
 * Basic structure of a valid store module compatible for use with a table
 * panel.
 */
interface StoreModule<T = unknown> {
  items: T[];
}

export interface LoadPageOptions {
  clearPrevious?: boolean;
  filterTags?: string[];
  filterParams?: Record<string, unknown>;
}

/**
 * Table panel store abstract class.
 */
export abstract class TablePanel<T> extends VuexModule<
  store.Root.State,
  store.Root.State
> {
  //#region Immutable Properties

  readonly module: string = '';
  readonly loadAction: string = '';
  readonly loadPageAction: string = '';
  readonly pk: keyof T | null = null;
  readonly label: string = '';
  readonly columns: TablePanel.Column<T>[] = [];
  readonly operations?: TablePanel.Row.Operation<T>[] | null = null;
  readonly createAction: string | null = null;
  readonly deleteAction: string | null = null;
  readonly loadOnOpen: boolean = true;
  readonly provider: TablePanel.DataProvider<T> | null = null;

  //#endregion Immutable Properties

  //#region Abstract Immutable Properties

  abstract readonly enabled?: boolean;
  abstract readonly progressive?: boolean;

  //#endregion Abstract Immutable Properties

  //#region Public State Properties

  loading = false;
  tableOpen = false;
  tableQueryText = '';
  tableQueryParams: Record<string, unknown> | null = null;
  tablePageSize = 10;
  tablePageNumber = 1;
  tableSortType: string | null = null;
  tableSortReverse = false;
  // ...
  lastEvaluatedKey: unknown = null;
  allResultsLoaded = false;

  //#endregion Public State Properties

  private loadOptions: Record<string, unknown> = {};

  /**
   * Allows for external access to `progressive` property whether it's declared
   * as a regular property or a getter.
   */
  get isProgressive() {
    return !!this.progressive;
  }

  /**
   * All items used to populate table rows referenced directly from
   * {@link TablePanel.storeModule storeModule}.
   */
  get items() {
    return this.storeModule.items;
  }

  /** ... */
  get tableMenu() {
    return [] as TablePanel.MenuItem<T>[];
  }

  /**
   * Complete set of table rows, sorted and reversed if dictated by current
   * state.
   */
  get rows() {
    let rows = createTableRows(this.items, this.columns, this.pk);

    const sortType = this.tableSortType;

    if (sortType) {
      rows = sortBy(rows, ({ values }) => values?.[sortType]);
    }

    if (this.tableSortReverse) {
      rows = rows.reverse();
    }

    return rows;
  }

  /** ... */
  get rowCount() {
    return this.rows.length;
  }

  /** ... */
  get filteredRows() {
    let rows = this.rows;

    if (this.tableQueryText) {
      rows = rows.filter(({ values }) =>
        JSON.stringify(values).toLowerCase().includes(this.tableQueryText),
      );
    }

    return rows;
  }

  /** ... */
  get pages() {
    // Ensure there is always at least one page.
    const pageCount = Math.max(
      Math.ceil(this.filteredRows.length / this.tablePageSize),
      1,
    );

    const pages = [];

    for (let i = 0; i < pageCount; i++) {
      const start = i * this.tablePageSize;
      const end = start + this.tablePageSize;
      const page = this.filteredRows.slice(start, end);

      pages.push(page);
    }

    return pages;
  }

  /** ... */
  get pageCount() {
    return this.pages.length;
  }

  /** ... */
  get page() {
    // ...
    const pageIndex = clamp(this.tablePageNumber, 1, this.pageCount) - 1;

    const rows = this.pages[pageIndex];

    if (!rows) {
      /* eslint-disable-next-line no-console */
      console.warn(
        `[table-panel:page] could not retrieve table page at index "${pageIndex}"`,
      );

      return [];
    }

    const page: TablePanel.DisplayedRow<T>[] = [];

    for (let i = 0; i < this.tablePageSize; i++) {
      const row = rows[i];

      const id = row?.id ?? uniqueId();
      const item = row?.item ?? null;
      const values = row?.values ?? null;

      const displayedRow = {
        id,
        item,
        values,
        selected: false,
      } as TablePanel.DisplayedRow<T>;

      if (item && this.operations) {
        displayedRow.operations = createOperations(
          this.operations,
          item,
          this.activeUser,
        );
      }

      page.push(Vue.observable(displayedRow));
    }

    return page;
  }

  /** ... */
  get pageItemsCount() {
    return this.page.length || 0;
  }

  /** ... */
  get selectedRows() {
    return this.page.filter((row) => row.selected);
  }

  /** ... */
  protected get activeUser() {
    return this.context.rootState.me;
  }

  /**
   * The user's current active role.
   */
  protected get activeRole() {
    // return this.context.rootState.me.selectedRole!;

    const { selectedRole } = this.activeUser;

    if (!selectedRole) {
      throw new Error(
        "[table-panel] could not retrieve current user's active role.",
      );
    }

    return selectedRole;
  }

  /**
   * Utility for checking if the user's active role ID is among those provided.
   *
   * @returns Boolean representing whether or not one of the provided IDs
   * matched the user's active role ID.
   */
  protected get isActiveRole() {
    return (...ids: RoleId[]) => {
      return (this.context.rootGetters as Getters)['me/isCurrentRole'](...ids);
    };
  }

  /**
   * Store module associated with this table panel through the table panel's
   * assigned {@link TablePanel.module module} key.
   */
  protected get storeModule() {
    const value = (this.context.rootState as unknown as GenericObject)[
      this.module
    ];

    if (!isValidStoreModule(value)) {
      throw new Error(
        `[storeModule] could not find a valid store module under the module ${this.module}`,
      );
    }

    return value as StoreModule<T>;
  }

  //#region Mutations

  /**
   * ...
   *
   * @param options ...
   */
  @Mutation
  update(options: TablePanel.UpdateOptions) {
    for (const key in options) {
      if (key === 'loading') {
        this.loading = options.loading ?? this.loading;
      } else if (key === 'tableOpen') {
        this.tableOpen = options.tableOpen ?? this.tableOpen;
      } else if (key === 'tableQueryText') {
        this.tableQueryText = options.tableQueryText ?? this.tableQueryText;
      } else if (key === 'tableQueryParams') {
        this.tableQueryParams =
          options.tableQueryParams ?? this.tableQueryParams;
      } else if (key === 'tablePageSize') {
        this.tablePageSize = options.tablePageSize ?? this.tablePageSize;
      } else if (key === 'tablePageNumber') {
        this.tablePageNumber = options.tablePageNumber ?? this.tablePageNumber;
      } else if (key === 'tableSortType') {
        this.tableSortType = options.tableSortType ?? this.tableSortType;
      } else if (key === 'tableSortReverse') {
        this.tableSortReverse =
          options.tableSortReverse ?? this.tableSortReverse;
      } else if (key === 'lastEvaluatedKey') {
        this.lastEvaluatedKey =
          options.lastEvaluatedKey ?? this.lastEvaluatedKey;
      } else if (key === 'allResultsLoaded') {
        this.allResultsLoaded =
          options.allResultsLoaded ?? this.allResultsLoaded;
      } else {
        /* eslint-disable-next-line no-console */
        console.warn(
          `[table-panel:update] data of key "${key}" was not recognized and will be ignored.`,
        );
      }
    }
  }

  /**
   * ...
   *
   * @param queryText ...
   */
  @Mutation
  updateQuery(queryText: string) {
    this.tableQueryText = queryText;
    this.tablePageNumber = 1;
  }

  /**
   * ...
   *
   * @param value ...
   */
  @Mutation
  saveLoadOptions(value: Record<string, unknown>) {
    this.loadOptions = value;
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   *
   * @param options ...
   */
  @Action
  async loadRegular(options?: Record<string, unknown>) {
    // ...
    if (options) {
      this.context.commit('saveLoadOptions', options);
    } else {
      options = this.loadOptions ?? {};
    }

    this.context.commit('update', { loading: true });

    const res = await smoothLoad(
      this.context.dispatch(`${this.module}/${this.loadAction}`, options, {
        root: true,
      }),
    );

    if (res instanceof Error) {
      /* eslint-disable-next-line no-console */
      console.error(
        '[table-panel:load] failed to fetch table items due to an error: ' +
          res.message,
      );
    }

    this.context.commit('update', { loading: false });

    return this.items;
  }

  /**
   * ...
   *
   * @param options ...
   */
  @Action
  async loadProgressive(options?: LoadPageOptions) {
    // ...
    if (options) {
      this.context.commit('saveLoadOptions', options);
    } else {
      options = this.loadOptions ?? {};
    }

    const ctx: TablePanel.LoadPageOptions<T> = { filter: {} };

    if (this.tableQueryText) {
      ctx.filter.contains = this.tableQueryText;
    }

    if (this.tableQueryParams) {
      ctx.params = this.tableQueryParams;
    }

    if (options.filterTags?.length) {
      ctx.filter.tags = options.filterTags;
    }

    if (options.filterParams) {
      ctx.filter.params = options.filterParams;
    }

    if (options.clearPrevious) {
      ctx.clearPrevious = true;

      this.context.commit('update', {
        lastEvaluatedKey: null,
        allResultsLoaded: false,
      });
    }

    this.context.commit('update', { loading: true });

    const res = (await smoothLoad(
      this.context.dispatch(`${this.module}/${this.loadPageAction}`, ctx, {
        root: true,
      }),
    )) as TablePanel.DataProvider.Results<T> | Error;

    let lastEvaluatedKey: unknown = null;
    let allResultsLoaded = false;

    if (res instanceof Error) {
      /* eslint-disable-next-line no-console */
      console.error(
        '[table-panel:load] failed to fetch table items due to an error: ' +
          res.message,
      );
    } else {
      lastEvaluatedKey = res.lastEvaluatedKey;
      allResultsLoaded = !res.lastEvaluatedKey;
    }

    this.context.commit('update', {
      loading: false,
      lastEvaluatedKey,
      allResultsLoaded,
    });

    return this.items;
  }

  /**
   * ...
   *
   * @param options ...
   */
  @Action
  async load(options?: Record<string, unknown>) {
    let type: string;

    if (this.progressive) {
      type = 'loadProgressive';
      options = { ...(options ?? this.loadOptions ?? {}), clearPrevious: true };
    } else {
      type = 'loadRegular';
    }

    return await this.context.dispatch(type, options);
  }

  /**
   * ...
   */
  @Action
  async open(options?: Record<string, unknown>) {
    if (this.tableOpen) return;

    this.context.commit('update', { tableOpen: true });

    if (this.loadOnOpen) {
      await this.context.dispatch('load', options);
    }
  }

  /**
   * ...
   */
  @Action
  close() {
    if (!this.tableOpen) return;

    this.context.commit('update', { tableOpen: false });
  }

  //#endregion Actions
}

export namespace TablePanel {
  /** ... */
  export interface Props<T> {
    readonly module: keyof store.Root.Modules;
    readonly loadAction: string;
    readonly loadPageAction: string;
    readonly pk?: string;
    readonly label: string;
    readonly columns: Column<T>[];
    readonly operations?: Row.Operation<T>[] | null;
    readonly createAction?: string | null;
    readonly deleteAction?: string | null;
    // ...
    readonly filterTags?: FilterTag[];
    readonly filterParams?: FilterParam[];
    readonly infoMessage?: string | null;

    items: T[];
    loading: boolean;
    tableOpen: boolean;
    tableQueryText: string;
    tablePageSize: number;
    tablePageNumber: number;
    tableSortType: string | null;
    tableSortReverse: boolean;
    tableMenu?: MenuItem<T>[];
    enabled: boolean;
    progressive: boolean;
    // loadItems(options?: Record<string, unknown>): Promise<T[]>;
    onPageChanged?: OnPageChangedCallback<T>;
  }

  export interface FilterTag {
    key: string;
    label: string;
  }

  //#region Filter Param

  export namespace FilterParam {
    /**
     * Value types compatible with a filter param {@link Option}.
     */
    export type OptionValue = string | number | null;

    /**
     * {@link ZephyrWeb.OptionItem OptionItem} compatible with
     * {@link FilterParam}.
     */
    export type Option = ZephyrWeb.OptionItem<string | number | null>;

    /**
     * Function for generated a list of options for a {@link FilterParam}
     * config.
     */
    export type OptionsGenerator = () => Option[] | Promise<Option[]>;
  }

  /**
   * Configuration for a table filter parameter field
   */
  export interface FilterParam {
    /**
     * Represents the literal request parameter key a specified value should be
     * assigned under when included in a query request.
     */
    key: string;
    /**
     * Label that should be displayed for the rendered input field.
     */
    label: string;
    /**
     * If declared, the rendered input field should be treated as a select
     * input. If the value provided is an {@link FilterParam.OptionsGenerator
     * options generator function}, it should return a set of options (
     * optionally asynchronously), and be called and handled on initialization
     * of the table.
     *
     */
    options?: FilterParam.Option[] | FilterParam.OptionsGenerator;
  }

  //#endregion Filter Param

  /** ... */
  export interface Column<T> {
    key: string;
    label: string;
    type?: Column.Type;
    // value: string | ((row: T) => unknown);
    value: keyof T | ((row: T) => unknown);
    filterByFormatted?: boolean;
    sortByFormatted?: boolean;
    placeholder?: string | ((row: T) => unknown);
    component?:
      | keyof import('@vue/runtime-core').GlobalComponents
      | Vue.Component;
    hidden?: (user: store.Me.State) => boolean;
  }

  export namespace Column {
    /** ... */
    export type Type =
      | 'text'
      | 'number'
      | 'boolean'
      | 'date'
      | 'phone'
      | 'currency'
      | 'percent'
      | 'dateTime';
  }

  /**
   * Context object that is passed to a {@link ContextMenuItem}'s `click`
   * handler.
   */
  export interface MenuActionContext<T = unknown> {
    selection: T[];
  }

  export interface MenuItemButton<T = unknown> {
    key: string;
    label: string;
    icon?: IconDefinition;
    disabled?: boolean | ((context: MenuActionContext<T>) => boolean);
    click: (context: MenuActionContext<T>) => void | Promise<void>;
  }

  export interface MenuItemSeparator {
    type: 'separator';
  }

  /** ... */
  export type MenuItem<T = unknown> = MenuItemButton<T> | MenuItemSeparator;

  /** ... */
  export type OnPageChangedCallback<T> = (items: T[]) => void;

  /** ... */
  export interface Row<T> {
    id: string;
    // item: T;
    item: T | null;
    // values: Record<string, unknown>;
    values: Record<string, unknown> | null;
    selected: boolean;
  }

  export namespace Row {
    /** ... */
    export interface Operation<T> {
      label: string;
      icon: IconDefinition;
      variant?: 'danger' | 'success' | 'warning' | 'info';
      fn: (item: T, user: store.Me.State) => unknown;
      hidden?: (item: T, user: store.Me.State) => boolean;
    }
  }

  /** ... */
  export interface DisplayedRow<T> extends Row<T> {
    selected: boolean;
    operations?: DisplayedRow.Operation[];
  }

  export namespace DisplayedRow {
    /** ... */
    export interface Operation {
      label: string;
      icon: IconDefinition;
      fn: GenericFunction;
    }
  }

  /** ... */
  export interface UpdateOptions {
    // items?: T[];
    loading?: boolean;
    tableOpen?: boolean;
    tableQueryText?: string;
    tableQueryParams?: QueryParams;
    tablePageSize?: number;
    tablePageNumber?: number;
    tableSortType?: string;
    tableSortReverse?: boolean;
    lastEvaluatedKey?: unknown;
    allResultsLoaded?: boolean;
  }

  /** Special Custom filter functions */
  interface DropdownOption {
    value: string;
    text: string;
  }

  export interface SpecialFilter<T> {
    /** ... */
    type: SpecialFilter.Type;
    label: string;
    handler: (item: T | null, choice?: T | null) => unknown;
    options?: DropdownOption[];
    choice?: string | null;
  }

  export namespace SpecialFilter {
    /** date-range, text, and number not yet implemented */
    export type Type = 'toggle' | 'date-range' | 'dropdown' | 'text' | 'number';
  }

  export type FieldFilterConfig<T> = Partial<Record<keyof T, string>>;

  export interface FilterOptions<T> {
    equals?: FieldFilterConfig<T>;
    contains?: string | FieldFilterConfig<T>;
    tags?: string[];
    /**
     * ...
     */
    params?: Record<string, unknown>;
  }

  export namespace DataProvider {
    /** ... */
    export interface Context<T> {
      filter: FilterOptions<T>;
      params?: Record<string, unknown>;
    }

    /** ... */
    export interface Results<T> {
      items: T[];
      lastEvaluatedKey: unknown;
    }
  }

  /** ... */
  export type DataProvider<T> = (
    ctx: DataProvider.Context<T>,
  ) => Promise<DataProvider.Results<T>>;

  /**
   * ...
   */
  export interface LoadPageOptions<T> extends DataProvider.Context<T> {
    clearPrevious?: boolean;
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidStoreModule(value: unknown): value is StoreModule {
  return isObject(value) && isArray(value.items);
}

/**
 * ...
 *
 * @param fn ...
 * @returns ...
 */
function evokeSafely(fn: () => unknown) {
  let value: unknown;

  try {
    value = fn();
  } catch {
    // Ignore any errors.
  }

  return value;
}

/**
 * Attempt to access and return a (nested) value within a provided object by
 * following the provided "property chain" (i.e. `'some.nested.prop'`).
 *
 * @param obj Object containing the desired value.
 * @param chain `string` value representing the location of the value.
 * @example
 * ```ts
 * const obj = {
 *  foo: {
 *    bar: 'fizz-buzz'
 *  }
 *};
 *
 *  // Will log "fizz-buzz".
 *  console.log(evalPropertyChain(obj, 'foo.bar'));
 * ```
 */
function evalPropertyChain(obj: unknown, chain: string) {
  let value = obj;

  const props = chain.split('.');

  try {
    for (const prop of props) value = (value as GenericObject)[prop];
  } catch {
    value = null;
  }

  return value;
}

/**
 * ...
 *
 * @param promise ...
 * @returns ...
 */
async function smoothLoad(promise: Promise<unknown>) {
  const wrappedPromise = new Promise<unknown>((resolve) => {
    promise.then(resolve).catch((err) => resolve(ensureError(err)));
  });

  return (await Promise.all([wrappedPromise, delay(500)]))[0];
}

/**
 * ...
 *
 * @param ops ...
 * @param item ...
 * @param user ...
 * @returns ...
 */
function createOperations<T>(
  ops: TablePanel.Row.Operation<T>[],
  item: T,
  user: store.Me.State,
) {
  return ops
    .filter(({ hidden }) => (!hidden ? true : !hidden(item, user)))
    .map(({ fn, ...op }) => ({ ...op, fn: () => fn(item, user) }));
}

/**
 * ...
 *
 * @param items ...
 * @param columns ...
 * @param pk ...
 */
function createTableRows<T>(
  items: T[],
  columns: TablePanel.Column<T>[],
  pk?: Nullable<keyof T>,
) {
  return items.map((item, i) => {
    // ...
    const id = (pk ? item[pk] : i.toString()) as string;
    // ...
    const values: Record<string, unknown> = {};

    for (const { key, value } of columns) {
      let cell: unknown;

      if (typeof value === 'string') {
        cell = evalPropertyChain(item, value);
      } else if (typeof value === 'function') {
        cell = evokeSafely(() => value(item));
      }

      values[key] = cell;
    }

    return { id, item, values, selected: false } as TablePanel.Row<T>;
  });
}

//#endregion Helper Functions
