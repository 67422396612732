
import { Vue, Component, Prop } from '@vue';
import { State } from '@vuex/class';
import { BvTableField } from 'bootstrap-vue';
import { filter, find } from 'lodash';

import { faExclamationTriangle } from '@icons/regular/faExclamationTriangle';

import type { Course } from '@models';
import { dates } from '@utils/dates';
import { Users } from '@store';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellCourseName: ComponentWithProps<Props>;
  }
}

/**
 * `TableCellCourseName` component properties.
 */
export interface Props {
  /** ... */
  field: Field;
  /** ... */
  value: unknown;
  /** ... */
  item: Course;
}

/** ... */
type Field = BvTableField & { key: string };

@Component
export default class TableCellCourseName extends Vue {
  @Prop(Object) readonly field!: Field;
  @Prop() readonly value!: unknown;
  @Prop(Object) readonly item!: Course;

  readonly icons = { faExclamationTriangle };
  readonly tooltip =
    'Students within this course need license extensions. Click the Gear Icon > Send Extension Codes.';

  @State(({ users }) => users.items) readonly users!: Users['items'];

  /**
   * ...
   */
  get courseName() {
    return this.item.name;
  }

  /**
   * ...
   */
  get extensionsRequired() {
    // return !!this.item.extensionsRequired;
    //

    return this.users.some((user) => {
      const studentRole = find(user.roles, { id: 3 });

      if (!studentRole) return false;

      if (!dates.isBefore(dates.now, this.item.endDate)) return false;

      for (const license of user.licenses ?? []) {
        if (
          !license.expiration ||
          dates.isAfter(license.expiration, this.item.endDate)
        ) {
          continue;
        }

        if (!user.extensionCodes?.length) return true;

        // user has extension codes sent out already
        const codesUnused = filter(user.extensionCodes, { used: false });

        if (codesUnused.every(({ courseId }) => courseId !== this.item.id)) {
          return true;
        }
      }

      return false;
    });
  }
}
