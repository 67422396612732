
import { Vue, Component, Prop } from '@vue';

import { ContextMenuElement } from '@interfaces';

@Component
export default class ContextMenu extends Vue {
  @Prop(Array) readonly items!: ContextMenuElement[];

  private opened = false;

  created() {
    document.addEventListener('click', this.close);
  }

  destroyed() {
    document.removeEventListener('click', this.close);
  }

  public open() {
    this.opened = true;
  }

  public close() {
    this.opened = false;
  }

  public toggle() {
    this.opened = !this.opened;
  }

  private onSelected(fn: () => any) {
    this.close();

    setTimeout(fn, 1);

    // fn();
  }
}
