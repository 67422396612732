
import { Vue, Component, Prop } from '@vue';

import { ListItem } from './list-item';

@Component
export default class SelectionGroup<T> extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(Array) readonly items!: ListItem<T>[];
  @Prop(Function) readonly text!: (item: T) => string;
  @Prop(Function) readonly subtext!: (item: T) => string;

  get itemsLength() {
    return this.items?.length;
  }
}
