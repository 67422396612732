var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-dismiss-button" }, [
    _c(
      "button",
      { staticClass: "dismiss", on: { click: _setup.modal.dismiss } },
      [_c("Icon", { attrs: { icon: _setup.faXmark } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }