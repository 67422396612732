import { isObject } from './type-guards';

/**
 * ...
 *
 * @param text ...
 * @param fallbackValue ...
 * @return ...
 */
export function parse(text: string) {
  let value: unknown = null;

  try {
    value = JSON.parse(text);
  } catch (_) {
    value = {};
  }

  return isObject(value) ? value : {};
}
