var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-form",
    _vm._b(
      {
        staticClass: "form",
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.$emit("submit", _setup.formData)
          },
        },
      },
      "b-form",
      { name: _vm.name },
      false
    ),
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(_setup.FormFieldSelect, {
                attrs: {
                  label: "Country",
                  field: _setup.v.country,
                  options: _setup.countryOptions,
                  validate: _setup.fieldValidation,
                },
                scopedSlots: _vm._u([
                  {
                    key: "first",
                    fn: function () {
                      return [
                        _c(
                          "b-form-select-option",
                          { attrs: { value: null, disabled: "" } },
                          [_vm._v("\n            Select Country\n          ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _setup.administrativeArea
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _setup.administrativeAreaOptions
                    ? _c(_setup.FormFieldSelect, {
                        attrs: {
                          label: _setup.administrativeArea.label,
                          field: _setup.v.stateProvince,
                          options: _setup.administrativeAreaOptions,
                          validate: _setup.fieldValidation,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [
                                      _vm._v(
                                        "\n            Select " +
                                          _vm._s(
                                            _setup.administrativeArea.label
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3778017822
                        ),
                      })
                    : _c(_setup.FormFieldInput, {
                        attrs: {
                          label: _setup.administrativeArea.label,
                          field: _setup.v.stateProvince,
                          validate: _setup.fieldValidation,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _setup.fieldInfo?.postalCode
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(_setup.FormFieldInput, {
                    ref: "postalCode",
                    attrs: {
                      label: _setup.fieldInfo.postalCode.label,
                      field: _setup.v.postalCode,
                      validate: _setup.fieldValidation,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }