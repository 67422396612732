import { Vue, Component, Prop, Watch } from '@vue';
import { find } from 'lodash';

import { faArrowLeft } from '@icons/regular/faArrowLeft';
import { faArrowRight } from '@icons/regular/faArrowRight';

import SelectionGroup from './SelectionGroup.vue';

/**
 * ...
 */
export interface ListItem<T> {
  id: number;
  item: T;
}

/**
 * ...
 */
export interface SelectionPanelProps<T = unknown> {
  optionsLabel: string;
  selectionLabel: string;
  options: T[];
  selection: T[];
  text: (item: T) => string;
  subtext: (item: T) => string;
}

@Component({ components: { SelectionGroup } })
export class SelectionPanel<T> extends Vue {
  @Prop(String) readonly optionsLabel!: string;
  @Prop(String) readonly selectionLabel!: string;
  @Prop(Array) readonly options!: T[];
  @Prop(Array) readonly selection!: T[];
  @Prop(Function) readonly text!: (item: T) => string;
  @Prop(Function) readonly subtext!: (item: T) => string;

  readonly icons = { faArrowLeft, faArrowRight };

  items: ListItem<T>[] = [];
  selectionIds: number[] = [];

  @Watch('selectionIds')
  onSelectionChanged(ids: number[]) {
    const value: T[] = [];

    for (const id of ids) {
      const item = find(this.items, { id })?.item;

      if (!item) {
        throw new Error(
          '[SelectionPanel:onSelectionChanged] could not find an internally tacked item by its ID.',
        );
      }

      value.push(item);
    }

    this.$emit('selection-updated', value);
  }

  /** ... */
  get groups() {
    const left: ListItem<T>[] = [];
    const right: ListItem<T>[] = [];

    for (const item of this.items) {
      const group = this.selectionIds.includes(item.id) ? right : left;

      group.push(item);
    }

    return { left, right };
  }

  created() {
    this.generateLists();

    this.$watch(
      () => this.options,
      () => this.generateLists(),
    );
    this.$watch(
      () => this.selection,
      () => this.generateLists(),
    );
  }

  /**
   * ...
   *
   * @param id ...
   */
  add(id: number) {
    this.selectionIds = [...this.selectionIds, id];
  }

  /**
   * ...
   *
   * @param id ...
   */
  remove(id: number) {
    this.selectionIds = this.selectionIds.filter((_id) => _id !== id);
  }

  /**
   * ...
   */
  addAll() {
    this.selectionIds = this.items.map(({ id }) => id);
  }

  /**
   * ...
   */
  removeAll() {
    this.selectionIds = [];
  }

  /**
   * ...
   *
   * @return
   */
  private generateLists() {
    const items: ListItem<T>[] = [];
    const ids: number[] = [];

    let count = 0;

    for (const item of this.options) {
      items.push({ id: count++, item });
    }

    for (const item of this.selection) {
      const id = count++;

      items.push({ id, item });
      ids.push(id);
    }

    this.items = items;
    this.selectionIds = ids;
  }
}

export namespace SelectionPanel {
  /** ... */
  export type Props = SelectionPanelProps;
}

export default SelectionPanel;
