var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "order-card-item" },
    [
      _c(
        "b-row",
        { staticClass: "py-3" },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("div", {
              staticClass: "item-thumbnail",
              style: { backgroundImage: `url(${_setup.imageUrl})` },
            }),
          ]),
          _vm._v(" "),
          _c("b-col", [
            _c("div", { staticClass: "h6" }, [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("div", { staticClass: "item-detail" }, [
              _c("div", { staticClass: "detail-label" }, [_vm._v("Quantity")]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-spacer" }, [_c("Spacer")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "detail-value" }, [
                _vm._v(_vm._s(_vm.quantity)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-detail" }, [
              _c("div", { staticClass: "detail-label" }, [_vm._v("Price")]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-spacer" }, [_c("Spacer")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "detail-value" }, [
                _vm._v(_vm._s(_setup.displayPrice)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-detail" }, [
              _c("div", { staticClass: "detail-label" }, [_vm._v("Status")]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-spacer" }, [_c("Spacer")], 1),
              _vm._v(" "),
              _c("div", { staticClass: "detail-value" }, [
                _vm._v(_vm._s(_setup.displayStatus)),
              ]),
            ]),
            _vm._v(" "),
            _vm.shippingInfo
              ? _c("div", { staticClass: "item-detail" }, [
                  _c("div", { staticClass: "detail-label" }, [
                    _vm._v("Ship To"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail-spacer" },
                    [_c("Spacer")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail-value" },
                    [
                      _c("PrintAddress", {
                        staticClass: "order-shipping-address",
                        attrs: { address: _vm.shippingInfo },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }