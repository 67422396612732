import * as typeGaurds from '@tools/type-guards';

/** ... */
// type Nullish = null | undefined;

/** ... */
type AssetionType =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'null'
  | 'object'
  | 'function'
  | 'array';

// type ObjectBuilderDescriptor<T> = {
//   [P in keyof T]: AssetionType | { type: AssetionType, required?: boolean };
// }

type ObjectBuilderDescriptor<T> = Record<
  keyof T,
  AssetionType | { type: AssetionType; required?: boolean }
>;

/**
 * ...
 *
 * @param value ...
 * @param required ...
 * @return ...
 */
class DataUtilities {
  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  assertIsBoolean(value: unknown): boolean;
  assertIsBoolean(value: unknown, required: true): string;
  assertIsBoolean(value: unknown, required: false): string | null;
  assertIsBoolean(value: unknown, required = true) {
    return assertIsType(value, 'boolean', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  assertIsNumber(value: unknown): number;
  assertIsNumber(value: unknown, required: true): number;
  assertIsNumber(value: unknown, required: false): number | null;
  assertIsNumber(value: unknown, required = true) {
    return assertIsType(value, 'number', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  assertIsString(value: unknown): string;
  assertIsString(value: unknown, required: true): string;
  assertIsString(value: unknown, required: false): string | null;
  assertIsString(value: unknown, required = true) {
    return assertIsType(value, 'string', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  assertIsArray<T = unknown>(value: unknown): T[];
  assertIsArray<T = unknown>(value: unknown, required: true): T[];
  assertIsArray<T = unknown>(value: unknown, required: false): T[] | null;
  assertIsArray(value: unknown, required = true) {
    return assertIsType(value, 'array', !required);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  parseInt(value: unknown, required = false) {
    return parseNumber('int', value, required);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  parseFloat(value: unknown, required = false) {
    return parseNumber('float', value, required);
  }

  /**
   * ...
   *
   * @param const[key,value]inObject.entries(descriptor
   * @return
   */
  buildDataObject<T>(
    data: GenericObject,
    descriptor: ObjectBuilderDescriptor<T>,
  ) {
    const output: GenericObject = {};

    for (const key in descriptor) {
      const prop = key as keyof T;
      const options = descriptor[prop];

      let type: AssetionType;
      let required: boolean;

      if (typeGaurds.isString(options)) {
        type = options;
        required = true;
      } else {
        type = options.type;
        required = options.required ?? true;
      }

      output[key] = assertIsType(data[key], type, required);
    }

    return output as Partial<T>;
  }
}

// Record<keyof T, AssetionType | 'nullish' | (AssetionType | 'nullish')[]>;

/** ... */
export const dataUtils = new DataUtilities();

// region Helper Functions

/**
 * ...
 *
 * @param value ...
 * @param type ...
 * @return ...
 */
function assertIsType(
  value: unknown,
  type: AssetionType,
  allowNullish = false,
) {
  const tg =
    type === 'string'
      ? typeGaurds.isString
      : type === 'number'
      ? typeGaurds.isNumber
      : type === 'bigint'
      ? typeGaurds.isBigInt
      : type === 'boolean'
      ? typeGaurds.isBoolean
      : type === 'symbol'
      ? typeGaurds.isSymbol
      : type === 'undefined'
      ? typeGaurds.isUndefined
      : type === 'null'
      ? typeGaurds.isNull
      : type === 'object'
      ? typeGaurds.isObject
      : type === 'function'
      ? typeGaurds.isFunction
      : type === 'array'
      ? typeGaurds.isArray
      : null;

  if (!tg) {
    throw new Error('[assertType] the specified assertion type was not valid.');
  }

  if (tg(value) || (allowNullish && typeGaurds.isNullish(value))) {
    return value ?? null;
  }

  // ...
  const valueType =
    value === null
      ? 'null'
      : typeGaurds.isArray(value)
      ? 'array'
      : typeof value;

  throw new TypeError(
    `[DataUtilities:assertType] provided value expected to be "${type}", but was "${valueType}".`,
  );
}

/**
 * ...
 *
 * @param type ...
 * @param value ...
 * @param required ...
 * @return ...
 */
function parseNumber(type: 'int' | 'float', value: unknown, required: boolean) {
  // ...
  const parser = type === 'int' ? parseInt : parseFloat;

  if (typeGaurds.isNumber(value)) return value;

  if (typeGaurds.isString(value)) return parser(value);

  if (typeGaurds.isNullish(value)) {
    if (!required) return null;

    throw new TypeError(
      `[DataUtilities:parseNumber] provided value to be parsed as a number was "${typeof value}", but was marked as required.`,
    );
  }

  throw new TypeError(
    `[DataUtilities:parseNumber] provided value to be parsed as a number was not a valid type: was "${typeof value}", but must be either "number", "string", "null" or "undefined."`,
  );
}

// endregion Helper Functions
