var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "reports-table" }, [
    _c("div", { staticClass: "table-container" }, [
      _c("div", { staticClass: "table" }, [
        _c("div", { staticClass: "thead" }, [
          _c("div", { staticClass: "tr" }, [
            _c("div", { staticClass: "th" }, [_vm._v("No.")]),
            _vm._v(" "),
            _c("div", { staticClass: "th" }, [_vm._v("Date")]),
            _vm._v(" "),
            _c("div", { staticClass: "th" }, [_vm._v("Scene")]),
            _vm._v(" "),
            _c("div", { staticClass: "th" }, [_vm._v("Module")]),
            _vm._v(" "),
            _c("div", { staticClass: "th" }, [_vm._v("Score")]),
            _vm._v(" "),
            _c("div", { staticClass: "th options-column" }, [_vm._v("View")]),
            _vm._v(" "),
            _vm.isDroneLogbookLinked
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip",
                        value: "DroneLogbook",
                        expression: "'DroneLogbook'",
                      },
                    ],
                    staticClass: "th",
                  },
                  [_vm._v("\n            In DLB?\n          ")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tbody-container",
            style: { height: `${_vm.height}px` },
          },
          [
            _c(
              "div",
              { staticClass: "tbody" },
              _vm._l(_vm.items, function (report, i) {
                return _c("div", { staticClass: "tr" }, [
                  _c("div", { staticClass: "td" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          i +
                            1 +
                            (_vm.paginationInfo.currentPage - 1) *
                              _vm.itemsPerPage
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm._f("date")(
                            report.createdAt,
                            "MM/dd/yyyy hh:mm:ss a"
                          )
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td" }, [
                    _vm._v(_vm._s(report.sceneName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td" }, [
                    _vm._v(_vm._s(report.moduleName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(report.score) +
                        " / " +
                        _vm._s(report.possibleScore) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td options-column" }, [
                    report.loading
                      ? _c(
                          "span",
                          [_c("Icon", { attrs: { icon: _vm.icons.faSync } })],
                          1
                        )
                      : _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.viewReport(report)
                              },
                            },
                          },
                          [_c("Icon", { attrs: { icon: _vm.icons.faEye } })],
                          1
                        ),
                  ]),
                  _vm._v(" "),
                  _vm.isDroneLogbookLinked
                    ? _c("div", { staticClass: "td" }, [
                        _vm.me.id == report.userId && !report.dlbSyncable
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: "Cannot be Synced",
                                    expression: "'Cannot be Synced'",
                                  },
                                ],
                              },
                              [
                                _c("span", [_vm._v(" N/A")]),
                                _vm._v(" "),
                                _c("Icon", {
                                  attrs: { icon: _vm.icons.faInfoCircle },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.me.id == report.userId && report.dlbSyncable
                          ? _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(report.inDroneLogbook ? "Yes" : "No") +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.me.id != report.userId
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: "Can Only Sync Your Reports",
                                    expression: "'Can Only Sync Your Reports'",
                                  },
                                ],
                              },
                              [
                                _c("span", [_vm._v(" N/A")]),
                                _vm._v(" "),
                                _c("Icon", {
                                  attrs: { icon: _vm.icons.faInfoCircle },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "nav-container" }, [
      _vm.paginationInfo.pages.length
        ? _c(
            "ul",
            { staticClass: "pagination" },
            [
              _c(
                "li",
                { class: { disabled: _vm.paginationInfo.currentPage === 1 } },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setPages(1)
                        },
                      },
                    },
                    [_vm._v("First")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: { disabled: _vm.paginationInfo.currentPage === 1 } },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setPages(
                            _vm.paginationInfo.currentPage - 1
                          )
                        },
                      },
                    },
                    [_vm._v("Previous")]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.paginationInfo.pages, function (page) {
                return _c(
                  "li",
                  {
                    class: { active: _vm.paginationInfo.currentPage === page },
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.setPages(page)
                          },
                        },
                      },
                      [_vm._v(_vm._s(page))]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: {
                    disabled:
                      _vm.paginationInfo.currentPage ===
                      _vm.paginationInfo.totalPages,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setPages(
                            _vm.paginationInfo.currentPage + 1
                          )
                        },
                      },
                    },
                    [_vm._v("Next")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: {
                    disabled:
                      _vm.paginationInfo.currentPage ===
                      _vm.paginationInfo.totalPages,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.setPages(_vm.paginationInfo.totalPages)
                        },
                      },
                    },
                    [_vm._v("Last")]
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }