import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faArrowUp } from '@icons/solid/faArrowUp';
import { faArrowDown } from '@icons/solid/faArrowDown';

import { Product, Drone } from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';
import { store } from '@store';

const columns: TablePanel.Column<Product>[] = [
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  {
    key: 'id',
    label: 'ID',
    value: 'id',
  },
  {
    key: 'type',
    label: 'Type',
    value: ({ type }) => {
      if (type === 'SEAT') return 'Seat';
      if (type === 'LICENSE') return 'License';
      if (type === 'PHYSICAL') return 'Physical';
      if (type === 'CERTIFICATION') return 'Certification';
      if (type === 'BUNDLE') return 'Bundle';
      if (type === 'DLC') return 'DLC';

      return '--'; // Invalid type
    },
  },
  {
    key: 'price',
    label: 'Price',
    type: 'currency',
    value: 'price',
  },
  {
    key: 'salePrice',
    label: 'Sale Price',
    type: 'currency',
    value: 'salePrice',
  },
  {
    key: 'onSale',
    label: 'On Sale',
    type: 'boolean',
    value: 'onSale',
  },
  {
    key: 'active',
    label: 'Active',
    type: 'boolean',
    value: 'active',
  },
];

const operations: TablePanel.Row.Operation<Product>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id }) => {
      void modals.product.edit({ productId: id });
    },
  },
  {
    label: 'Set to Active',
    icon: faArrowUp,
    fn: async ({ id }) => {
      try {
        await store.dispatch('products/update', {
          productId: id,
          active: true,
        });
      } catch (err) {
        return alert.error(err);
      }

      alert.success('Product activated.');
    },
    hidden: ({ active }) => active,
  },
  {
    label: 'Set to Inactive',
    icon: faArrowDown,
    fn: async ({ id }) => {
      try {
        await store.dispatch('products/update', {
          productId: id,
          active: false,
        });
      } catch (err) {
        return alert.error(err);
      }

      alert.success('Product deactivated.');
    },
    hidden: ({ active }) => !active,
  },
];

const specialFilters = [
  {
    type: 'dropdown',
    label: 'Type',
    options: [
      { value: '--', text: '--' },
      { value: 'bundle', text: 'Bundle' },
      { value: 'license', text: 'License' },
      { value: 'certification', text: 'Certification' },
      { value: 'physical', text: 'Physical' },
      { value: 'seat', text: 'Seat' },
      { value: 'dlc', text: 'DLC' },
    ],
    handler: (item: Product, choice: string) => {
      return item?.type
        ? item.type.toLowerCase() === choice.toLowerCase()
        : null;
    },
  },
];

@Module({ namespaced: true })
export class ProductsTablePanel
  extends TablePanel<Product>
  implements TablePanel.Props<Product>
{
  filterTags?: TablePanel.FilterTag[];
  filterParams?: TablePanel.FilterParam[];
  infoMessage?: string | null;
  onPageChanged?: TablePanel.OnPageChangedCallback<Product>;
  readonly module = 'products';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'PRODUCTS';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createProduct';
  readonly deleteAction = 'deleteProduct';
  readonly specialFilters = specialFilters;

  get enabled() {
    return this.isActiveRole(9);
  }

  /** ... */
  get tableMenu() {
    const context = this.context;
    const roleId = context.rootState.me.selectedRole?.roleId;

    if (roleId !== 9) return [];

    const items: TablePanel.MenuItem[] = [];

    items.push({
      key: 'mass_add_drone',
      label: 'Add Drone to Product(s)',
      // disabled: this.processing,
      click: async (context) => {
        // this.processing = true;
        // these are the products they selected
        if (!context?.selection.length) {
          // this.processing = false;
          return alert.error('Must select at least one product.');
        }

        const products = context.selection.map((p) => p as Product);

        // select drone modal
        const drones = await store.dispatch('drones/list');

        const selectedDroneId = await modals.util.selectItemFromList({
          title: 'SELECT A DRONE',
          options: drones.map((d) => {
            return {
              value: d.id,
              label: d.name,
            };
          }),
        });

        if (!selectedDroneId || typeof selectedDroneId !== 'string') {
          // this.processing = false;
          return alert.error('Must select a drone to add.');
        }

        const droneName = drones.find((d) => d.id === selectedDroneId)?.name;
        // process each post product api call
        // check if drone is already on the list and skip
        for (const product of products) {
          alert.info(`Processing ${product.name}`);

          // product already has drone assigned
          if (product.drones?.find((d) => d.id === selectedDroneId)) {
            alert.warning(
              `${product.name} already has the ${droneName} assigned to it.`,
            );
            continue;
          }

          try {
            const droneIds = Array.isArray(product.drones)
              ? product.drones.map((d) => d.id)
              : [];

            droneIds.push(selectedDroneId);

            const options = {
              productId: product.id,
              drones: droneIds,
            };
            await store.dispatch('products/update', options);
            alert.success(`${droneName} added to ${product.name}`);
          } catch (err) {
            alert.error(err);
          }
        }

        // this.processing = false;
        alert.info('DONE PROCESSING');
      },
    });

    return items;
  }

  @Action
  async createProduct() {
    await modals.product.create();
  }

  @Action
  async deleteProduct({ items }: ProductsTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'products', items });
  }
}

export namespace ProductsTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: Product[];
  }
}
