var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.item
    ? _c("div", { staticClass: "tsi" }, [
        _c("div", { staticClass: "tsi-col tsi-image" }, [
          _c("img", { attrs: { src: _setup.item.images[0] || "" } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tsi-col tsi-info" }, [
          _c("div", { staticClass: "tsi-row tsi-title" }, [
            _c("h5", [_vm._v(_vm._s(_setup.item.name))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tsi-row grow" }, [
            _c("p", { staticClass: "tsi-label" }, [_vm._v("Quantity")]),
            _vm._v(" "),
            _c("span", { staticClass: "tsi-dotted-line" }),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  attrs: { disabled: _setup.item.quantity <= 1 },
                  on: { click: _setup.addOne },
                },
                [_vm._v("-")]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_setup.item.quantity))]),
              _vm._v(" "),
              _c(
                "button",
                {
                  attrs: {
                    disabled: _setup.item.quantity >= _setup.item.limit,
                  },
                  on: { click: _setup.removeOne },
                },
                [_vm._v("\n          +\n        ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tsi-row grow" }, [
            _c("p", { staticClass: "tsi-label" }, [_vm._v("Total")]),
            _vm._v(" "),
            _c("span", { staticClass: "tsi-dotted-line" }),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "tsi-total" },
              [
                _c("DisplayTextCurrency", {
                  attrs: { value: _setup.item.price * _setup.item.quantity },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _setup.item.discount
              ? _c("span", { staticClass: "item-discount" }, [
                  _c("h4", { staticClass: "success" }, [
                    _vm._v("\n          -\n          "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_setup.item.discount.percent,
                            expression: "!item.discount.percent",
                          },
                        ],
                      },
                      [_vm._v("$")]
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_setup.item.discount.amount) +
                        "\n          "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _setup.item.discount.percent,
                            expression: "item.discount.percent",
                          },
                        ],
                      },
                      [_vm._v("%")]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }