import * as models from '@models';
import { store, Users } from '@store';

import { openModal } from '../open-modal';
import { GetItemProps } from '../types';

/**
 * ...
 */
export interface InviteUserModalOptions {
  organizationName?: models.Organization['name'];
  targetOrganizationId?: models.Organization['id'];
  targetEmails?: string[]; // Allow to preload emails
}

/**
 * Open Invite User modal.
 *
 * @returns Modal promise.
 */
export async function invite(options?: InviteUserModalOptions) {
  await openModal.safe({
    title: `${
      options?.organizationName
        ? `Invite Users to ${options?.organizationName}`
        : 'INVITE USERS'
    }`,
    props: { ...options },
    component: () => import('./InviteUsers.vue'),
  });
}

/**
 * Alias of {@link invite}.
 *
 * @deprecated
 */
export const create = invite;

/**
 * ...
 */
export interface EditUserModalOptions {
  user: models.User;
}

/**
 * Open Edit User modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function edit(options: EditUserModalOptions) {
  const modalOptions = {
    title: 'EDIT USER',
    props: { ...options },
    component: () => import('./EditUser/index.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ViewUserDetailsModalOptions {
  user: models.User;
}

/**
 * Open View User Details modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function details(options: ViewUserDetailsModalOptions) {
  const modalOptions = {
    title: 'USER DETAILS',
    props: { ...options },
    size: 'lg',
    component: () => import('./UserDetails/index.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface InviteStudentModalOptions {
  targetCourseId?: models.Course['id'];
}

/**
 * Open Invite Student modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function inviteStudent(options?: InviteStudentModalOptions) {
  const modalOptions = {
    title: 'INVITE STUDENT(S)',
    props: { ...(options ?? {}) },
    component: () => import('./InviteStudent.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * Open Invite Indie Operator modal.
 *
 * @return Modal promise.
 */
export async function inviteIndieOperator() {
  const modalOptions = {
    title: 'INVITE INDEPENDENT OPERATOR',
    component: () => import('./InviteIndependentOperator.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface InviteResellerModalOptions {
  resellerId?: models.Reseller['id'];
}

/**
 * Open Invite Reseller modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function inviteReseller(options?: InviteResellerModalOptions) {
  const modalOptions = {
    title: 'INVITE RESELLER USER',
    props: { ...(options ?? {}) },
    component: () => import('./InviteReseller.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * Open Invite Reviewers modal.
 *
 * @return Modal promise.
 */
export async function inviteReviewers() {
  const modalOptions = {
    title: 'INVITE REVIEWERS',
    // props: { ...(options || {}) },
    component: () => import('./InviteReviewers.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * Open Invite LAS Admin modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function inviteLasAdmin() {
  const modalOptions = {
    title: 'INVITE LAS ADMIN(S)',
    component: () => import('./InviteLasAdmin.vue'),
  };

  await openModal.safe(modalOptions);

  // prevCtrl.allInvites();
}

/**
 * ...
 */
export interface LinkDroneLogbookModalOptions {
  organizationId: models.Organization['id'];
  oldOrganizationId: models.Organization['oldId'];
}

/**
 * Open Link Drone Logbook modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function linkDroneLogbook(options?: LinkDroneLogbookModalOptions) {
  const modalOptions = {
    title: 'LINK DRONE LOGBOOK ACCOUNT',
    size: 'lg',
    props: { ...(options ?? {}) },
    component: () => import('./LinkDroneLogbook.vue'),
  };

  let success = true;

  try {
    await openModal(modalOptions);
  } catch {
    success = false;
  }

  return { success };
}

/**
 * ...
 */
export interface LogoutModalOptions {
  message: string;
}

/**
 * Open Logout modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function logout(options: LogoutModalOptions) {
  const modalOptions = {
    title: 'LOGOUT',
    props: { ...options },
    component: () => import('./Logout.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export type RemoveStudentFromInstitutionModalOptions = Users.GetActionOptions;

/**
 * Open Remove Student from Institution modal.
 *
 * @deprecated in favor of remove user from institution
 * @param options Modal options.
 * @return Modal promise.
 */
export async function removeStudentFromInstitution(
  options: RemoveStudentFromInstitutionModalOptions,
) {
  const user = await store.dispatch('users/get', options);

  const modalOptions = {
    title: 'REMOVE STUDENT FROM INSTITUTION',
    component: () => import('./RemoveStudentFromInstitution.vue'),
    props: { user },
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export type RemoveInstructorFromInstitutionModalOptions =
  Users.GetActionOptions;

/**
 * Open Remove Instructor from Institution modal.
 *
 * @deprecated in favor of remove user from institution
 * @param options Modal options.
 * @return Modal promise.
 */
export async function removeInstructorFromInstitution(
  options: RemoveInstructorFromInstitutionModalOptions,
) {
  // ...
  const user = await store.dispatch('users/get', options);

  const modalOptions = {
    title: 'REMOVE INSTRUCTOR FROM INSTITUTION',
    component: () => import('./RemoveInstructorFromInstitution.vue'),
    props: { user },
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface AddCourseModalOptions {
  user: models.User;
  targetOrganizationName: models.Organization['name'];
  targetOrganizationId: models.Organization['id'];
}

/**
 * Open Add Course modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */

export async function addCourse(options: AddCourseModalOptions) {
  const modalOptions = {
    title: `Adding Course to ${options.user.email}`,
    component: () => import('./AddCourse.vue'),
    props: { ...options },
  };
  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface RemoveUserFromInstitutionModalOptions {
  user: models.User;
}

/**
 * Open Remove User from Institution modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */

export async function removeUserFromInstitution(
  options: RemoveUserFromInstitutionModalOptions,
) {
  interface RemoveUserModalResponse {
    userRemoved: boolean;
  }

  let userRemovedResponse: RemoveUserModalResponse | null = null;

  try {
    userRemovedResponse = (await openModal({
      title: 'REMOVE USER FROM INSTITUTION',
      component: () => import('./RemoveUserFromInstitution.vue'),
      props: { user: options.user },
    })) as RemoveUserModalResponse;
  } catch {
    //empty catch
  }

  return { userRemoved: userRemovedResponse?.userRemoved };
}

/**
 * ...
 */
export interface DeleteUserModalOptions {
  user: models.User;
}

/**
 * Open Delete User View modal.
 *
 * * @param options Modal options.
 * @return Modal promise.
 */
export async function deleteUser(options: DeleteUserModalOptions) {
  // ...

  interface UserDeletedModalResponse {
    userDeleted: boolean;
  }

  let userDeletedResponse: UserDeletedModalResponse | null = null;

  try {
    userDeletedResponse = (await openModal({
      title: 'DELETE USER',
      component: () => import('./DeleteUser.vue'),
      props: { user: options.user },
    })) as UserDeletedModalResponse;
  } catch {
    //empty catch
  }
  return { userDeleted: userDeletedResponse?.userDeleted };
}

export interface ChangePasswordModalOptions {
  userId: string;
}

/**
 * Open Change Password View modal.
 *
 * * @param options Modal options.
 * @return Modal promise.
 */
export async function changePassword(options: ChangePasswordModalOptions) {
  await openModal.safe({
    title: 'CHANGE PASSWORD',
    component: () => import('./ChangePassword.vue'),
    props: {
      userId: options.userId,
    },
  });
}

/**
 * Open Reseller View modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function resellerViewUser(userId: number) {
  const gip: GetItemProps = {
    storeName: 'users',
    key: 'user',
    id: userId,
    getter: (id: number) => store.dispatch('users/get', id),
  };

  const modalOptions = {
    title: 'USER DETAILS',
    component: () => import('./ResellerViewUser.vue'),
    getItemProps: [gip],
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface CoursesModalOptions {
  userId: models.User['id'];
}

/**
 * Open User Courses modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function courses(options: CoursesModalOptions) {
  const modalOptions = {
    title: 'USER COURSES',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserCourses.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface OrdersModalOptions {
  userId: models.User['id'];
}

/**
 * Open User Orders modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function orders(options: OrdersModalOptions) {
  const modalOptions = {
    title: 'USER ORDERS',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserOrders.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface ReportsModalOptions {
  user: models.User;
}

/**
 * Open User Reports modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function reports(options: ReportsModalOptions) {
  const modalOptions = {
    title: 'USER REPORTS',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserReports.vue'),
  };

  await openModal.safe(modalOptions);
}

/** ... */
export interface RolesModalOptions {
  userId: models.User['id'];
}

/**
 * Open User Roles modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function roles(options: RolesModalOptions) {
  const modalOptions = {
    title: 'USER ROLES',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserRoles.vue'),
  };

  await openModal.safe(modalOptions);
}

/**
 * ...
 */
export interface AddLicenseModalOptions {
  user: models.User;
  licenses: models.License[];
}

/**
 * Open Add User License modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function addLicense(options: AddLicenseModalOptions) {
  const modalOptions = {
    title: 'ADD USER LICENSE',
    size: 'lg',
    props: { ...options },
    component: () => import('./AddLicense.vue'),
  };

  let output = null;

  try {
    output = await openModal(modalOptions);
  } catch {
    return;
  }

  return output;
}

/**
 * ...
 */
export interface AddRoleModalOptions {
  user: models.User;
}

/**
 * Open Add Role Modal.
 */
export async function addRole(options: AddRoleModalOptions) {
  const modalOptions = {
    title: 'ADD USER ROLE',
    props: { ...options },
    component: () => import('./AddRole.vue'),
  };

  let output: models.User.Role | undefined;

  try {
    output = await openModal<models.User.Role>(modalOptions);
  } catch {
    return null;
  }

  return output ?? null;
}

/**
 * ...
 */
export interface UpdateRoleModalOptions {
  user: models.User;
  roleId: models.Role['id'];
}

/**
 * Open Add Role Modal.
 */
export async function updateRole(options: UpdateRoleModalOptions) {
  const props = {
    user: options.user,
    initialRoleId: options.roleId,
  };

  const modalOptions = {
    title: 'UPDATE USER ROLE',
    props,
    component: () => import('./UpdateRole.vue'),
  };

  let output: models.User.Role | undefined;

  try {
    output = await openModal<models.User.Role>(modalOptions);
  } catch {
    return null;
  }

  return output ?? null;
}
