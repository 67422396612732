
import { Vue, Component, Prop } from '@vue';
// import RadialProgressBar from 'vue-radial-progress';
import RadialProgressBar from '@components/RadialProgressBar';

import { faInfoCircle } from '@icons/duotone/faInfoCircle';

@Component({ components: { RadialProgressBar } })
export default class CardScore extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly subtitle!: string | null;
  @Prop(Number) readonly percent!: number | number;
  @Prop(String) readonly rating!: string;
  @Prop(String) readonly info!: string;

  readonly totalSteps = 100;
  readonly icons = { faInfoCircle };

  get cardProps() {
    const props: GenericObject = {
      title: this.label,
      info: this.info,
    };

    if (this.subtitle) {
      props.subtitle = this.subtitle;
    }

    return props;
  }

  get percentDisplay() {
    return this.percent ?? 0;
  }

  get completedSteps() {
    return this.percent ?? 0;
  }

  get ratingDisplay() {
    return this.rating ? this.rating : 'N/A';
  }

  get ratingClass() {
    return this.rating ? this.rating : 'score-average';
  }

  viewInfo() {
    const options = {
      title: this.label.toUpperCase() + ' STATISTICS',
      text: this.info,
    };

    this.$modals.util.generalInfo(options);
  }
}
