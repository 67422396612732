import { Vue, Component, Prop, Watch, State } from '@vue';
import { cloneDeep, range } from 'lodash';

import { Report } from '@models';
import { Me } from '@store';

import { faEye } from '@icons/solid/faEye';
import { faSync } from '@icons/solid/faSync';
import { faInfoCircle } from '@icons/solid/faInfoCircle';

interface PaginationInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

function paginate(totalItems: number, currentPage: number, pageSize: number) {
  // default to first page
  currentPage = currentPage || 1;

  // default page size is 10
  pageSize = pageSize || 10;

  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  let startPage: number;
  let endPage: number;

  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= 6) {
    startPage = 1;
    endPage = 10;
  } else if (currentPage + 4 >= totalPages) {
    startPage = totalPages - 9;
    endPage = totalPages;
  } else {
    startPage = currentPage - 5;
    endPage = currentPage + 4;
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to repeat in the pager control
  const pages = range(startPage, endPage + 1);

  // return object with all pager properties required by the view

  const info: PaginationInfo = {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };

  return info;
}

@Component
export default class ReportsTable extends Vue {
  @Prop(Array) readonly reports!: Report[];
  @Prop(Number) readonly itemsPerPage!: number;
  @Prop([String, Number]) readonly height!: string | number;

  readonly icons = { faEye, faSync, faInfoCircle };

  paginationInfo: PaginationInfo | null = null;
  items: Report[] = [];

  @State me!: Me.State;

  get isDroneLogbookLinked() {
    return this.me.isDroneLogbookLinked;
  }

  @Watch('reports')
  onReportsChanged() {
    // self.reports = newValue;

    this.setPages(1);
  }

  created() {
    this.setPages(1);
  }

  viewReport(report: Report) {
    this.$modals.report.viewReport({ report });
  }

  setPages(page: number) {
    if (!this.reports.length) {
      return;
    }

    const reports = cloneDeep(this.reports);

    page = page || this.paginationInfo.currentPage;

    this.paginationInfo = paginate(reports.length, page, this.itemsPerPage);

    this.items = reports.slice(
      this.paginationInfo.startIndex,
      this.paginationInfo.endIndex + 1,
    );
  }
}
